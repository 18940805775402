var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { roleLabel } from "commonLocale/typeLabels";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Menu from "components/molecules/Menu";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import { FALLBACK_STRING, coreMentorAndAboveRoles } from "utils/constants";
import EditUserRoleModal from "./EditMentorRoleModal";
import UserModal from "./UserModal";
var UserTable = function (_a) {
    var users = _a.users, loading = _a.loading, hasMore = _a.hasMore, onScroll = _a.onScroll;
    var modal = useModal();
    var roleSet = useAuth().roleSet;
    var COLUMNS = useMemo(function () { return [
        {
            Header: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.mentorName.label" }, void 0),
            accessor: "id",
            Cell: function (cellInfo) { return (_jsx("span", __assign({ onClick: function () { return modal.show(_jsx(UserModal, { user: cellInfo.row.original }, void 0)); }, className: "text-primary font-bold cursor-pointer" }, { children: "".concat(cellInfo.row.original.lastName, " ").concat(cellInfo.row.original.firstName) }), void 0)); },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.role.label" }, void 0),
            accessor: "roles",
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx(FormattedMessage, { id: roleLabel[value] || FALLBACK_STRING }, void 0));
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.branchName.label" }, void 0),
            accessor: "mentor.branch.name",
            Cell: function (_a) {
                var value = _a.value;
                return value || FALLBACK_STRING;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.graduationYear.label" }, void 0),
            accessor: "lastGraduationYear",
            Cell: function (_a) {
                var value = _a.value;
                return value || FALLBACK_STRING;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.highestEdu.label" }, void 0),
            accessor: "mentor.educations[0].schoolDepartment.school.name",
            Cell: function (_a) {
                var value = _a.value;
                return value || FALLBACK_STRING;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.departmentName.label" }, void 0),
            accessor: "mentor.educations[0].schoolDepartment.name",
            Cell: function (_a) {
                var value = _a.value;
                return value || FALLBACK_STRING;
            },
        },
        {
            Header: false,
            id: "edit",
            Cell: function (cellInfo) {
                return cellInfo.row.original.mentor &&
                    coreMentorAndAboveRoles.some(function (role) { return roleSet.has(role); }) ? (_jsx(Menu, { options: [
                        {
                            id: "edit",
                            label: _jsx(FormattedMessage, { id: "admin.usersPage.table.column.edit.role.label" }, void 0),
                            onClick: function () {
                                return modal.show(_jsx(EditUserRoleModal, { user: cellInfo.row.original }, void 0));
                            },
                        },
                    ] }, void 0)) : null;
            },
        },
    ]; }, []);
    return users && (users === null || users === void 0 ? void 0 : users.length) === 0 && !loading ? (_jsx(NoResult, {}, void 0)) : (_jsx(Table, { data: users, columns: COLUMNS, hasMore: hasMore, onScroll: onScroll, loading: loading }, void 0));
};
export default UserTable;
