var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Icon } from "components/atoms";
import Menu from "components/molecules/Menu";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { CompanyRecommendationStatus, InterviewStatus, } from "generated/graphql";
import { FALLBACK_STRING } from "utils/constants";
import { evaluationMap } from "utils/mappers";
var EntorTable = function (_a) {
    var entors = _a.entors, loading = _a.loading, hasMore = _a.hasMore, onScroll = _a.onScroll, onAssignMyself = _a.onAssignMyself, onUnassignUser = _a.onUnassignUser;
    var COLUMNS = useMemo(function () { return [
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.name.label" }, void 0),
            accessor: "id",
            Cell: function (cellInfo) { return (_jsx(Link, __assign({ to: String(cellInfo.row.original.id), className: "text-primary font-bold cursor-pointer" }, { children: "".concat(cellInfo.row.original.user.lastName, " ").concat(cellInfo.row.original.user.firstName) }), void 0)); },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.assignedMentorName.label" }, void 0),
            accessor: "assignedUser",
            Cell: function (cellInfo) {
                return cellInfo.row.original.assignedUser
                    ? "".concat(cellInfo.row.original.assignedUser.lastName, " ").concat(cellInfo.row.original.assignedUser.firstName)
                    : FALLBACK_STRING;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.highestEdu.label" }, void 0),
            accessor: "user.lastEducation.schoolDepartment",
            Cell: function (cellInfo) {
                var _a;
                return (_a = (cellInfo.row.original.user.lastEducation && (_jsxs(_Fragment, { children: [_jsx("p", { children: "".concat(cellInfo.row.original.user.lastEducation.schoolDepartment.name) }, void 0), _jsx("p", __assign({ className: "text-black50" }, { children: "".concat(cellInfo.row.original.user.lastEducation.schoolDepartment.school.name) }), void 0)] }, void 0)))) !== null && _a !== void 0 ? _a : undefined;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.graduationYear.label" }, void 0),
            accessor: "user.lastGraduationYear",
            Cell: function (_a) {
                var value = _a.value;
                return value || FALLBACK_STRING;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.generalEvaluation.label" }, void 0),
            accessor: "evaluation.excellence",
            Cell: function (cellInfo) {
                var _a, _b, _c;
                return (_c = evaluationMap((_b = (_a = cellInfo.row.original.evaluation) === null || _a === void 0 ? void 0 : _a.excellence) !== null && _b !== void 0 ? _b : undefined)) !== null && _c !== void 0 ? _c : FALLBACK_STRING;
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.interviewStatus.label" }, void 0),
            accessor: "interviewStatus",
            Cell: function (cellInfo) {
                return cellInfo.row.original.interviewStatus &&
                    cellInfo.row.original.interviewStatus === InterviewStatus.Done ? (_jsx(Icon, { name: "CheckCircle", className: "text-success text-size-h5" }, void 0)) : (FALLBACK_STRING);
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.companyRecommendation.label" }, void 0),
            accessor: "companyRecommendationStatus",
            Cell: function (cellInfo) {
                return cellInfo.row.original.companyRecommendationStatus &&
                    cellInfo.row.original.companyRecommendationStatus === CompanyRecommendationStatus.Done ? (_jsx(Icon, { name: "CheckCircle", className: "text-success text-size-h5" }, void 0)) : (FALLBACK_STRING);
            },
        },
        {
            accessor: "assign-user",
            Cell: function (cellInfo) { return (_jsx(Menu, { options: [
                    {
                        id: "assign-myself",
                        label: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.assignMyself.label" }, void 0),
                        onClick: function () {
                            onAssignMyself(cellInfo.row.original);
                        },
                        disabled: !!cellInfo.row.original.assignedUser,
                    },
                    {
                        id: "unassign-myself",
                        label: _jsx(FormattedMessage, { id: "admin.entorsPage.table.column.unassignUser.label" }, void 0),
                        onClick: function () {
                            onUnassignUser(cellInfo.row.original);
                        },
                        disabled: !cellInfo.row.original.assignedUser,
                    },
                ] }, void 0)); },
        },
    ]; }, []);
    return entors && (entors === null || entors === void 0 ? void 0 : entors.length) === 0 && !loading ? (_jsx(NoResult, {}, void 0)) : (_jsx(Table, { data: entors, columns: COLUMNS, hasMore: hasMore, onScroll: onScroll, loading: loading }, void 0));
};
export default EntorTable;
