var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
var MultiTab = function (_a) {
    var tabs = _a.tabs, isSticky = _a.isSticky, onTabClick = _a.onTabClick, topZIndex = _a.topZIndex, selectedTabIndex = _a.selectedTabIndex, overflowX = _a.overflowX;
    return (_jsx("div", __assign({ className: "whitespace-nowrap" }, { children: _jsx("div", __assign({ className: classNames("flex h-full", {
                "sticky top-0": isSticky,
                "z-40": topZIndex,
                "overflow-auto": overflowX,
            }) }, { children: tabs.map(function (tab) { return (_jsxs("div", __assign({ onClick: function () { return onTabClick(tab.index); }, className: classNames("flex-1 flex justify-center items-center shadow-sm border-b-2 px-4 py-2 text-size-small bg-black10 h-full font-regular", tab.index === selectedTabIndex ? "border-primary font-bold" : "border-transparent", tab.disabled
                    ? "cursor-not-allowed text-black10 bg-black10"
                    : "cursor-pointer text-black90 bg-table-grey", tab.hasErrors && "text-error") }, { children: [tab.label, tab.hasErrors && " ⚠️"] }), tab.label + "".concat(tab.index))); }) }), void 0) }), void 0));
};
export default MultiTab;
