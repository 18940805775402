var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import emptyImage from "assets/images/empty.png";
import { Button } from "components/atoms";
import { useAuth } from "contexts/AuthContext";
import { QuestionType, RoleName } from "generated/graphql";
import QuestionForm from "./QuestionForm";
export var DEFAULT_QUESTION_TYPE = QuestionType.PullDown;
// TODO: Rename to Questions
var PreEventInformation = function () {
    var roleSet = useAuth().roleSet;
    var control = useFormContext().control;
    var _a = useFieldArray({
        control: control,
        name: "questions",
    }), fields = _a.fields, append = _a.append, remove = _a.remove, swap = _a.swap;
    var handleNewQuestion = function () {
        append({
            title: "",
            required: true,
            questionType: DEFAULT_QUESTION_TYPE,
            label: "",
            wordCount: undefined,
            options: [],
        });
    };
    var handleDeleteQuestion = function (questionIndex) {
        remove(questionIndex);
    };
    var handleSwapQuestion = function (direction, questionIndex) {
        if (direction === "up" && questionIndex > 0) {
            swap(questionIndex, questionIndex - 1);
        }
        else if (direction === "down" && questionIndex < fields.length - 1) {
            swap(questionIndex, questionIndex + 1);
        }
    };
    return fields.length > 0 ? (_jsxs("div", { children: [fields.map(function (field, index) { return (_jsx("div", { children: _jsx(QuestionForm, { questionIndex: index, onDeleteQuestion: function () { return handleDeleteQuestion(index); }, onSwapQuestion: handleSwapQuestion }, void 0) }, field.id)); }), _jsx("div", __assign({ className: "pt-4 pb-8 flex flex-col items-center w-full px-14" }, { children: _jsx(Button, __assign({ onClick: handleNewQuestion }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.button.label" }, void 0) }), void 0) }), void 0)] }, void 0)) : (_jsxs("div", __assign({ className: "bg-white pb-8 flex flex-col gap-6 items-center w-full" }, { children: [_jsx("img", { className: "w-2/5", src: emptyImage }, void 0), _jsxs("div", __assign({ className: "mb-2 text-black50 font-bold" }, { children: [_jsx("h6", { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.label1" }, void 0) }, void 0), _jsx("h6", { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.label2" }, void 0) }, void 0), _jsx("h6", { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.label3" }, void 0) }, void 0)] }), void 0), _jsxs("div", __assign({ className: "text-black50 text-size-small font-regular -mt-3" }, { children: [_jsx("p", { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.example.label1" }, void 0) }, void 0), _jsx("p", { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.example.label2" }, void 0) }, void 0)] }), void 0), _jsx(Button, __assign({ onClick: handleNewQuestion, disabled: roleSet.has(RoleName.Mentor) }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.additionalInfo.new.button.label" }, void 0) }), void 0)] }), void 0));
};
export default PreEventInformation;
