var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
import { useRef } from "react";
import { Transition } from "react-transition-group";
import onOutsideClick from "utils/helpers/onOutsideClick";
var ModalContainer = function (_a) {
    var children = _a.children, open = _a.open, closeModal = _a.closeModal, onClose = _a.onClose, _b = _a.disableOutsideClick, disableOutsideClick = _b === void 0 ? false : _b;
    var nodeRef = useRef(null);
    var wrapperRef = useRef(null);
    onOutsideClick(wrapperRef, closeModal);
    return (_jsx(Transition, __assign({ nodeRef: nodeRef, in: open, timeout: { exit: 300 }, mountOnEnter: true, unmountOnExit: true, onExited: onClose }, { children: function (state) { return (_jsx("div", __assign({ ref: nodeRef, className: classnames("absolute w-full h-full flex justify-center items-center bg-black10 transition-opacity duration-300 z-50", state === "entered" ? "opacity-100" : "opacity-0") }, { children: _jsx("div", __assign({ ref: disableOutsideClick ? null : wrapperRef, className: classnames("min-w-half max-w-[75%] transform transition-all duration-300 flex justify-center", state === "entered" ? "opacity-100" : "opacity-0 translate-y-8") }, { children: children }), void 0) }), void 0)); } }), void 0));
};
export default ModalContainer;
