var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { SegmentQuestionType } from "generated/graphql";
import { evaluationMap, roleNameToJPMap } from "utils/mappers";
var EvaluationSidebar = function (_a) {
    var accuracy = _a.accuracy, excellence = _a.excellence, segments = _a.segments, onSelect = _a.onSelect, selected = _a.selected, segmentAnswers = _a.segmentAnswers;
    var intl = useIntl();
    return (_jsxs("div", __assign({ className: "flex flex-col gap-3 w-1/4" }, { children: [_jsxs("div", __assign({ className: "flex flex-col border-b border-black10" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("span", __assign({ className: "flex flex-col justify-center text-black50 text-small" }, { children: intl.formatMessage({
                                    id: "admin.entorPage.evaluationTab.accuracy.label",
                                }) }), void 0), _jsx("h4", __assign({ className: "text-black50" }, { children: roleNameToJPMap(accuracy) ||
                                    intl.formatMessage({ id: "admin.entorPage.evaluationTab.notYetEvaluated.label" }) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("span", __assign({ className: "flex flex-col justify-center text-black50 text-small" }, { children: intl.formatMessage({
                                    id: "admin.entorPage.evaluationTab.excellenceEvaluation.label",
                                }) }), void 0), _jsx("h4", __assign({ className: "text-black50" }, { children: evaluationMap(excellence) ||
                                    intl.formatMessage({ id: "admin.entorPage.evaluationTab.notYetEvaluated.label" }) }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex flex-col gap-3" }, { children: segments &&
                    segments.map(function (_a, idx) {
                        var questions = _a.questions, title = _a.title;
                        return (_jsxs("div", __assign({ onClick: function () { return onSelect(idx); }, className: classNames("flex flex-row flex-wrap justify-between px-4 py-2 border rounded border-black10 text-size-small cursor-pointer font-bold ", selected === idx ? "text-white bg-black10" : "text-primary bg-white") }, { children: [_jsx("span", { children: title }, void 0), questions.filter(function (question) { return question.segmentQuestionType === SegmentQuestionType.Select; }).length > 0 && (_jsx("div", __assign({ className: "h-4 rounded-full bg-black10 text-black50 px-3 text-size-caption justify-center items-center font-bold" }, { children: "".concat(segmentAnswers.filter(function (segmentAnswer) {
                                        questions.map(function (q) { return q.id; }).includes(segmentAnswer.question.id);
                                    }).length, "/").concat(questions.filter(function (question) { return question.segmentQuestionType === SegmentQuestionType.Select; }).length) }), void 0))] }), idx + title));
                    }) }), void 0)] }), void 0));
};
export default EvaluationSidebar;
