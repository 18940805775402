var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import CompanyRecommendationSelect from "components/molecules/FormSelects/SingleSelect/CompanyRecommendationSelect";
import { EvaluationSelect } from "components/molecules/FormSelects/SingleSelect/EvaluationSelect";
import InterviewStatusSelect from "components/molecules/FormSelects/SingleSelect/InterviewStatus";
import TargetYearSelect from "components/molecules/FormSelects/SingleSelect/TargetYearSelect";
import FilterHeader from "components/templates/FilterHeader";
import NotFoundView from "components/templates/NotFoundView";
import { useAlert } from "contexts/AlertContext";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import { RoleName, useGetEntorsCsvLazyQuery, useListEntorsQuery, } from "generated/graphql";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
import AssignMyselfModal from "./AssignMyselfModal";
import EntorTable from "./EntorTable";
import UnassignUserModal from "./UnassignUserModal";
var PAGE_SIZE = 30;
var inputs = [
    {
        name: "id",
        label: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.filter.id.label" }, void 0),
    },
    {
        name: "name",
        label: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.filter.name.label" }, void 0),
    },
    {
        name: "assignedUserName",
        label: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.filter.assignedMentorName.label" }, void 0),
    },
    {
        name: "schoolName",
        label: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.filter.highestEdu.label" }, void 0),
    },
    {
        name: "departmentName",
        label: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.filter.departmentName.label" }, void 0),
    },
    {
        name: "graduationYear",
        Component: TargetYearSelect,
        singleSelect: true,
    },
    {
        name: "evaluation",
        Component: EvaluationSelect,
        singleSelect: true,
    },
    {
        name: "interviewStatus",
        Component: InterviewStatusSelect,
        singleSelect: true,
    },
    {
        name: "companyRecommendationStatus",
        Component: CompanyRecommendationSelect,
        singleSelect: true,
    },
];
var getFilters = function (searchParams) { return ({
    name: searchParams.get("name") || undefined,
    id: Number(searchParams.get("id")) || undefined,
    assignedUserName: searchParams.get("assignedUserName") || undefined,
    schoolName: searchParams.get("schoolName") || undefined,
    departmentName: searchParams.get("departmentName") || undefined,
    graduationYear: Number(searchParams.get("graduationYear")) || undefined,
    evaluation: searchParams.get("evaluation") || undefined,
    interviewStatus: searchParams.get("interviewStatus") || undefined,
    companyRecommendationStatus: searchParams.get("companyRecommendationStatus") || undefined,
}); };
var EntorList = function () {
    var _a, _b;
    var roleSet = useAuth().roleSet;
    var modal = useModal();
    var alert = useAlert();
    var intl = useIntl();
    var _c = __read(useSearchParams(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var initialFilters = useMemo(function () { return getFilters(searchParams); }, []);
    var _d = useListEntorsQuery({
        variables: {
            filter: __assign({}, initialFilters),
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true, // this will trigger a loading state on refetch
    }), data = _d.data, error = _d.error, refetch = _d.refetch, loading = _d.loading, fetchMore = _d.fetchMore;
    // TODOs
    // create a page that lists all download file links so reduce backend requests
    // Allow downloads with filters on (i.g. download entors that graduate in 2025 only)
    var _e = __read(useGetEntorsCsvLazyQuery({
        fetchPolicy: "cache-first",
        onCompleted: function (data) {
            var _a;
            if ((_a = data.getEntorsCsv) === null || _a === void 0 ? void 0 : _a.url) {
                alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
                window.open(data.getEntorsCsv.url);
            }
        },
    }), 2), getEntorsCSVLazy = _e[0], entorCSVDownloadLoading = _e[1].loading;
    var entors = (_a = data === null || data === void 0 ? void 0 : data.entors) === null || _a === void 0 ? void 0 : _a.edges.node;
    var pageInfo = (_b = data === null || data === void 0 ? void 0 : data.entors) === null || _b === void 0 ? void 0 : _b.pageInfo;
    var handleFilter = function (filters) {
        setSearchParams(filters);
        refetch({
            filter: __assign({ after: undefined, name: undefined, id: undefined, assignedUserName: undefined, graduationYear: undefined, offset: undefined, schoolName: undefined, departmentName: undefined, evaluation: undefined, interviewStatus: undefined, companyRecommendationStatus: undefined }, filters),
        });
    };
    var handleAssignMyself = function (entor) {
        modal.show(_jsx(AssignMyselfModal, { entor: entor }, void 0));
    };
    var handleUnassignUser = function (entor) {
        modal.show(_jsx(UnassignUserModal, { entor: entor }, void 0));
    };
    var handleScroll = function () {
        if (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) {
            fetchMore({
                variables: {
                    filter: __assign(__assign({}, getFilters(searchParams)), { first: PAGE_SIZE, after: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor }),
                },
            });
        }
    };
    return error ? (_jsx(NotFoundView, {}, void 0)) : (_jsxs("div", __assign({ className: "w-full overflow-y-scroll flex flex-col mt-4" }, { children: [_jsx(FilterHeader, { title: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.title" }, void 0), initialFilters: initialFilters, inputs: inputs, onFilter: handleFilter, actionBtn: roleSet.has(RoleName.Admin) ||
                    roleSet.has(RoleName.HeadQuarter) ||
                    roleSet.has(RoleName.BranchManager)
                    ? {
                        label: _jsx(FormattedMessage, { id: "admin.entorsPage.tableHeader.actionBtn.label" }, void 0),
                        onClick: function () {
                            getEntorsCSVLazy({});
                        },
                    }
                    : undefined, actionLoading: entorCSVDownloadLoading }, void 0), _jsx(EntorTable, { onAssignMyself: handleAssignMyself, onUnassignUser: handleUnassignUser, entors: entors || [], loading: loading, hasMore: !!(pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage), onScroll: handleScroll }, void 0)] }), void 0));
};
var CheckForFlags = function () {
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: true }, { children: _jsx(EntorList, {}, void 0) }), void 0));
};
export default CheckForFlags;
