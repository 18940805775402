var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
var currentYear = new Date().getFullYear();
var YEAR_OPTIONS = __spreadArray([], __read(Array(6)
    .fill(0)
    .map(function (_, index) {
    var year = currentYear - 1 + index;
    return {
        id: String(year),
        label: String(year),
        value: String(year),
    };
})), false);
var TargetYearSelect = function (props) { return (_jsx(SingleSelect, __assign({}, props, { options: YEAR_OPTIONS, name: "target-year", label: _jsx(FormattedMessage, { id: "common.form.field.singleSelect.targetYear.label" }, void 0) }), void 0)); };
export default TargetYearSelect;
