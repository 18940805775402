var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
var alertContext = createContext({
    onErrorAlert: function (msg) { },
    onSuccessAlert: function (msg) { },
    onLoadingAlert: function (msg) { },
});
var ProvideAlerts = function (_a) {
    var children = _a.children;
    var handleShowErrorAlert = function (errorMsg) {
        toast(errorMsg, { className: "!bg-error !text-white" });
    };
    var handleShowSuccessAlert = function (errorMsg) {
        toast(errorMsg, { className: "!bg-success !text-white" });
    };
    var handleShowLoadingAlert = function (loadingMsg) {
        toast(loadingMsg, { className: "!bg-primary10 !text-black" });
    };
    return (_jsxs(alertContext.Provider, __assign({ value: {
            onErrorAlert: handleShowErrorAlert,
            onSuccessAlert: handleShowSuccessAlert,
            onLoadingAlert: handleShowLoadingAlert,
        } }, { children: [_jsx(Toaster, { position: "top-right" }, void 0), children] }), void 0));
};
export var useAlert = function () { return useContext(alertContext); };
export default ProvideAlerts;
