var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { Icon, Label } from "components/atoms";
var RadioSelect = function (_a) {
    var label = _a.label, description = _a.description, onSelect = _a.onSelect, options = _a.options, isRequired = _a.isRequired, selectedValue = _a.selectedValue, error = _a.error, className = _a.className, isDisabled = _a.isDisabled;
    var intl = useIntl();
    var errorMsg = typeof error === "object" && (error === null || error === void 0 ? void 0 : error.locale_id)
        ? intl.formatMessage({ id: error === null || error === void 0 ? void 0 : error.locale_id }, __assign({}, error === null || error === void 0 ? void 0 : error.locale_value))
        : error;
    return (_jsxs("fieldset", __assign({ className: classNames("flex items-center h-9 relative", className) }, { children: [_jsxs("div", __assign({ className: "flex flex-row flex-wrap gap-3 py-3" }, { children: [label && (_jsx(Label, __assign({ withColon: true, isRequired: isRequired, className: "text-size-small mr-4" }, { children: label }), void 0)), options.map(function (option) { return (_jsxs("label", __assign({ onClick: function () { return onSelect(option.value); }, className: classNames("flex items-center text-size-smallr", {
                            "cursor-pointer": !isDisabled,
                            "cursor-not-allowed text-black25": isDisabled,
                        }) }, { children: [_jsx(Icon, { name: option.value === selectedValue ? "RadioCircleMarked" : "RadioCircle", className: classNames("flex text-size-h5", { "text-primary": !isDisabled }) }, void 0), _jsx("span", __assign({ className: "font-bold" }, { children: option.label }), void 0)] }), option.id)); })] }), void 0), ((errorMsg && typeof errorMsg === "string") || description) && (_jsx("p", __assign({ className: classNames("absolute top-[38px] h-3 text-caption leading-caption", {
                    "text-error": errorMsg,
                }) }, { children: errorMsg || description }), void 0))] }), void 0));
};
export default RadioSelect;
