var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Icon, Input } from "components/atoms";
export default function MultiEntry(_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, arrayName = _a.arrayName, segmentIdx = _a.segmentIdx, questionIdx = _a.questionIdx;
    var intl = useIntl();
    var _c = useFormContext(), register = _c.register, errors = _c.formState.errors;
    var _d = useFieldArray({
        name: arrayName,
    }), fields = _d.fields, append = _d.append, remove = _d.remove;
    return (_jsxs("div", __assign({ className: "w-4/5" }, { children: [fields.map(function (field, idx) {
                var _a, _b, _c, _d, _e, _f, _g;
                return (_jsxs("div", __assign({ className: "flex flex-row items-end" }, { children: [_jsx(Input, __assign({ disabled: disabled, label: "".concat(intl.formatMessage({
                                id: "admin.entorPage.evaluationTab.internshipCompanyName.label",
                            })).concat(idx + 1), className: "w-3/5 text-black50 text-size-caption" }, register("".concat(arrayName, ".").concat(idx, ".companyName")), { error: (_g = (_f = (_e = (_d = (_c = (_b = (_a = errors === null || errors === void 0 ? void 0 : errors.segments) === null || _a === void 0 ? void 0 : _a[segmentIdx]) === null || _b === void 0 ? void 0 : _b.questions) === null || _c === void 0 ? void 0 : _c[questionIdx]) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e[idx]) === null || _f === void 0 ? void 0 : _f.companyName) === null || _g === void 0 ? void 0 : _g.message }), void 0), !disabled && (_jsx(Icon, { name: "XCircle", className: "mb-2 ml-2 text-size-h5 text-black50 cursor-pointer", onClick: function () {
                                remove(idx);
                            } }, void 0))] }), field.id));
            }), !disabled && (_jsxs("div", __assign({ className: "mt-2 text-primary font-bold flex flex-row items-center cursor-pointer", onClick: function () {
                    append({
                        companyName: "",
                    });
                } }, { children: [_jsx(Icon, { name: "PlusCircle", className: "mr-1 text-size-body items-center" }, void 0), _jsx("span", __assign({ className: "text-size-small" }, { children: intl.formatMessage({ id: "admin.entorPage.evaluationTab.addCompanyName.label" }) }), void 0)] }), void 0))] }), void 0));
}
