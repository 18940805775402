var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { usePrefecturesQuery } from "generated/graphql";
var formatForSelect = function (prefecture) { return ({
    label: prefecture.name,
    id: String(prefecture.id),
    value: prefecture.id,
}); };
var PrefectureSelect = function (props) {
    var data = usePrefecturesQuery().data;
    var options = useMemo(function () {
        var prefOnline = data === null || data === void 0 ? void 0 : data.prefectures.find(function (pref) { return pref.id === 49; });
        var prefs = data === null || data === void 0 ? void 0 : data.prefectures.filter(function (pref) { return pref.id !== 49; });
        prefs === null || prefs === void 0 ? void 0 : prefs.unshift(prefOnline);
        return prefs === null || prefs === void 0 ? void 0 : prefs.map(formatForSelect);
    }, [data]);
    return options ? (_jsx(SingleSelect, __assign({}, props, { options: options, name: "prefecture-select", label: _jsx(FormattedMessage, { id: "common.form.field.singleSelect.prefecture.label" }, void 0), isClearable: false }), void 0)) : null;
};
export default PrefectureSelect;
