var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import { Icon } from "components/atoms";
import { MiniSpinner } from "components/atoms/Spinner";
var DownloadLink = function (props) {
    return (_jsxs("div", __assign({ className: "relative flex items-center" }, { children: [props.isLoading && _jsx(MiniSpinner, {}, void 0), _jsxs("a", __assign({ onClick: props.onDownload, className: classnames("flex flex-row space-between cursor-pointer text-primary items-center font-bold", { invisible: props.isLoading }) }, { children: [_jsx(Icon, { name: "Download" }, void 0), _jsx("span", { children: props.children }, void 0)] }), void 0)] }), void 0));
};
export default DownloadLink;
