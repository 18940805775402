var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { DoorsOpeningTiming } from "generated/graphql";
var DoorsOpeningSelect = function (props) {
    var intl = useIntl();
    var DOORS_OPENING_OPTIONS = [
        {
            id: "10",
            label: intl.formatMessage({
                id: "common.eventPage.editForm.scheduledEvent.date.doorsOpenOption.10",
            }),
            value: DoorsOpeningTiming.Before_10Minutes,
        },
        {
            id: "15",
            label: intl.formatMessage({
                id: "common.eventPage.editForm.scheduledEvent.date.doorsOpenOption.15",
            }),
            value: DoorsOpeningTiming.Before_15Minutes,
        },
        {
            id: "30",
            label: intl.formatMessage({
                id: "common.eventPage.editForm.scheduledEvent.date.doorsOpenOption.30",
            }),
            value: DoorsOpeningTiming.Before_30Minutes,
        },
        {
            id: "45",
            label: intl.formatMessage({
                id: "common.eventPage.editForm.scheduledEvent.date.doorsOpenOption.45",
            }),
            value: DoorsOpeningTiming.Before_45Minutes,
        },
        {
            id: "60",
            label: intl.formatMessage({
                id: "common.eventPage.editForm.scheduledEvent.date.doorsOpenOption.60",
            }),
            value: DoorsOpeningTiming.Before_60Minutes,
        },
    ];
    return (_jsx(SingleSelect, __assign({}, props, { options: DOORS_OPENING_OPTIONS, name: "doors-opening-select", label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.scheduledEvent.date.doorsOpen.label" }, void 0), isClearable: false }), void 0));
};
export default DoorsOpeningSelect;
