var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SchoolMultiSelect from "components/molecules/FormSelects/MultiSelect/SchoolMultiSelect";
import FilterHeader from "components/templates/FilterHeader";
import NotFoundView from "components/templates/NotFoundView";
import { useModal } from "contexts/ModalContext";
import { ListBranchesDocument, useListBranchesQuery } from "generated/graphql";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
import BranchModal from "./BranchModal";
import BranchesTable from "./BranchesTable";
var inputs = [
    {
        name: "name",
        label: _jsx(FormattedMessage, { id: "admin.branchesPage.tableHeader.filter.branchName.label" }, void 0),
    },
    {
        name: "schoolIDs",
        width: "w-2/5",
        Component: SchoolMultiSelect,
        multiSelect: true,
    },
];
var BranchList = function () {
    var modal = useModal();
    var _a = __read(useSearchParams(), 2), searchParams = _a[0], setSearchParams = _a[1];
    var location = useLocation();
    var navigate = useNavigate();
    var withModalOpen = location.pathname.split("/")[2] === "new"; // open modal if path is for new branch
    var initialFilters = useMemo(function () { return ({
        name: searchParams.get("name") || undefined,
        schoolIDs: searchParams.getAll("schoolIDs").map(Number),
    }); }, []);
    var _b = useListBranchesQuery({
        variables: {
            filter: initialFilters,
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true, // this will trigger a loading state on refetch
    }), data = _b.data, error = _b.error, refetch = _b.refetch, client = _b.client;
    useEffect(function () {
        if (withModalOpen) {
            modal.show(_jsx(BranchModal, { onCreated: refetch }, void 0), { onClose: function () { return navigate("/branches"); } });
        }
    }, [withModalOpen]);
    var handleFilter = function (filters) {
        setSearchParams(filters);
        refetch({
            filter: {
                name: filters.name,
                schoolIDs: filters.schoolIDs,
            },
        });
    };
    var handleBranchUpdate = function (id) {
        var branches = (client.cache.readQuery({
            query: ListBranchesDocument,
            variables: {
                filter: {
                    name: searchParams.get("name") || undefined,
                    schoolIDs: searchParams.getAll("schoolIDs").map(Number),
                },
            },
        }) || { branches: [] }).branches;
        var branch = branches.find(function (branch) { return branch.id === id; });
        modal.show(_jsx(BranchModal, { onCreated: refetch, branch: branch }, void 0));
    };
    return error ? (_jsx(NotFoundView, {}, void 0)) : (_jsxs("div", __assign({ className: "w-full overflow-y-scroll flex flex-col mt-4" }, { children: [_jsx(FilterHeader, { title: _jsx(FormattedMessage, { id: "admin.branchesPage.tableHeader.title" }, void 0), initialFilters: initialFilters, inputs: inputs, onFilter: handleFilter, actionBtn: {
                    label: _jsx(FormattedMessage, { id: "common.form.branch.create.title" }, void 0),
                    onClick: function () { return modal.show(_jsx(BranchModal, { onCreated: refetch }, void 0)); },
                } }, void 0), _jsx(BranchesTable, { branches: (data === null || data === void 0 ? void 0 : data.branches) || [], onEditBranch: handleBranchUpdate }, void 0)] }), void 0));
};
var CheckForFlags = function () {
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: true }, { children: _jsx(BranchList, {}, void 0) }), void 0));
};
export default CheckForFlags;
