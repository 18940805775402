var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHover } from "usehooks-ts";
import { useAlert } from "contexts/AlertContext";
import { useUploadImageMutation } from "generated/graphql";
import { Icon, Spinner } from ".";
var ImageUploader = function (_a) {
    var initialUrl = _a.initialUrl, _b = _a.withIcon, withIcon = _b === void 0 ? false : _b, onChange = _a.onChange, label = _a.label, sublabel = _a.sublabel, className = _a.className, isRequired = _a.isRequired, error = _a.error;
    var alert = useAlert();
    var intl = useIntl();
    var _c = __read(useState(initialUrl), 2), url = _c[0], setUrl = _c[1];
    var hoverRef = useRef(null);
    var isHover = useHover(hoverRef);
    var inputUpload = useRef(null);
    var _d = __read(useUploadImageMutation({
        onCompleted: function (data) {
            setUrl(data.createImageFromBase64.imageUrl);
            onChange(data.createImageFromBase64.imageUrl);
            if (data.createImageFromBase64.imageUrl) {
                alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.ImgUpload" }));
            }
            else {
                alert.onErrorAlert(intl.formatMessage({ id: "common.api.fail.ImgUpload" }));
            }
        },
        onError: function () {
            alert.onErrorAlert(intl.formatMessage({ id: "common.api.fail.ImgUpload" }));
        },
    }), 2), uploadImageMutation = _d[0], loading = _d[1].loading;
    var handleFileChange = function (e) {
        var _a;
        e.preventDefault();
        var uploadedFile = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        var reader = new FileReader();
        if (uploadedFile) {
            reader.readAsDataURL(uploadedFile);
            reader.onload = function () {
                uploadImageMutation({
                    variables: {
                        base64: reader.result,
                    },
                });
            };
        }
    };
    useEffect(function () {
        if (initialUrl !== url) {
            setUrl(initialUrl);
        }
    }, [initialUrl]);
    return (_jsx("div", __assign({ className: classNames(className || "w-100", { "border border-error": error }) }, { children: _jsx("div", __assign({ className: "overflow-hidden relative ".concat(withIcon ? " pt-50" : "pt-100") }, { children: _jsx("div", __assign({ className: "absolute top-0 left-0 w-100 h-100" }, { children: _jsx("div", __assign({ className: "h-100 flex justify-center items-center" }, { children: _jsxs("div", __assign({ className: classNames("flex flex-col items-center justify-center w-100 h-100 bg-table-grey shadow-a relative"), ref: hoverRef }, { children: [_jsx("input", { type: "file", accept: "image", className: "hidden", onChange: handleFileChange, ref: inputUpload }, void 0), _jsxs("div", __assign({ className: "w-full h-full absolute" }, { children: [(isHover || url === "") && !loading && (_jsxs("div", __assign({ className: classNames("hover:cursor-pointer hover:bg-primary10 flex flex-col items-center justify-center w-full h-full absolute z-30", { "bg-primary55": url.length > 0 }, { "bg-transparent": url.length === 0 }), ref: hoverRef, onClick: function () {
                                            var _a;
                                            (_a = inputUpload.current) === null || _a === void 0 ? void 0 : _a.click();
                                        } }, { children: [withIcon && (_jsx(Icon, { name: "Image", className: classNames("text-size-h1 mb-2", { "text-white": url.length > 0 }, { "text-black90": url.length === 0 }) }, void 0)), _jsxs("div", __assign({ className: classNames("mb-2 text-size-small font-bold", { "text-white": url.length > 0 }, { "text-black90": url.length === 0 }) }, { children: [label, isRequired && _jsx("span", __assign({ className: "text-error text-size-caption" }, { children: "*" }), void 0)] }), void 0), sublabel && (_jsx("div", __assign({ className: classNames("flex flex-col items-center text-size-caption", { "text-white": url.length > 0 }, { "text-black90": url.length === 0 }) }, { children: sublabel }), void 0))] }), void 0)), url.length > 0 && !loading && (_jsx("img", { src: url, className: "w-full h-full object-cover" }, void 0)), loading && _jsx(Spinner, {}, void 0)] }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0));
};
export default ImageUploader;
