export default {
    mixed: {
        default: function () { return ({ locale_id: "common.yup.mixed.default" }); },
        required: function () { return ({ locale_id: "common.yup.mixed.required" }); },
        oneOf: function (_a) {
            var values = _a.values;
            return ({
                locale_id: "common.yup.mixed.oneOf",
                locale_value: { values: values },
            });
        },
        notOneOf: function (_a) {
            var values = _a.values;
            return ({
                locale_id: "common.yup.mixed.notOneOf",
                locale_value: { values: values },
            });
        },
        defined: function () { return ({ locale_id: "common.yup.mixed.defined" }); },
    },
    string: {
        length: function (_a) {
            var length = _a.length;
            return ({
                locale_id: "common.yup.string.length",
                locale_value: { length: length },
            });
        },
        min: function (_a) {
            var min = _a.min;
            return ({
                locale_id: "common.yup.string.min",
                locale_value: { min: min },
            });
        },
        max: function (_a) {
            var max = _a.max;
            return ({
                locale_id: "common.yup.string.max",
                locale_value: { max: max },
            });
        },
        email: function () { return ({ locale_id: "common.yup.string.email" }); },
        url: function () { return ({ locale_id: "common.yup.string.url" }); },
        uuid: function () { return ({ locale_id: "common.yup.string.uuid" }); },
        trim: function () { return ({ locale_id: "common.yup.string.trim" }); },
        lowercase: function () { return ({ locale_id: "common.yup.string.lowercase" }); },
        uppercase: function () { return ({ locale_id: "common.yup.string.uppercase" }); },
    },
    number: {
        min: function (_a) {
            var min = _a.min;
            return ({
                locale_id: "common.yup.number.min",
                locale_value: { min: min },
            });
        },
        max: function (_a) {
            var max = _a.max;
            return ({
                locale_id: "common.yup.number.max",
                locale_value: { max: max },
            });
        },
        lessThan: function (_a) {
            var less = _a.less;
            return ({
                locale_id: "common.yup.number.lessThan",
                locale_value: { less: less },
            });
        },
        moreThan: function (_a) {
            var more = _a.more;
            return ({
                locale_id: "common.yup.number.moreThan",
                locale_value: { more: more },
            });
        },
        positive: function () { return ({ locale_id: "common.yup.number.positive" }); },
        negative: function () { return ({ locale_id: "common.yup.number.negative" }); },
        integer: function () { return ({ locale_id: "common.yup.number.integer" }); },
    },
    date: {
        min: function (_a) {
            var min = _a.min;
            return ({
                locale_id: "common.yup.date.min",
                locale_value: { min: min },
            });
        },
        max: function (_a) {
            var max = _a.max;
            return ({
                locale_id: "common.yup.date.max",
                locale_value: { max: max },
            });
        },
    },
    object: {
        noUnknown: function () { return ({ locale_id: "common.yup.object.noUnknown" }); },
    },
    array: {
        min: function (_a) {
            var min = _a.min;
            return ({
                locale_id: "common.yup.array.min",
                locale_value: { min: min },
            });
        },
        max: function (_a) {
            var max = _a.max;
            return ({
                locale_id: "common.yup.array.max",
                locale_value: { max: max },
            });
        },
    },
};
