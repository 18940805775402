var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { ClientStatus } from "generated/graphql";
var clientStatusLabel = (_a = {},
    _a[ClientStatus.Hidden] = "common.status.hidden",
    _a[ClientStatus.NoIndex] = "common.status.noIndex",
    _a[ClientStatus.Visible] = "common.status.visible",
    _a);
var COLUMNS = [
    {
        Header: _jsx(FormattedMessage, { id: "admin.clientsPage.table.column.clientName.label" }, void 0),
        accessor: "company",
        Cell: function (cellInfo) { return (_jsx(Link, __assign({ to: String(cellInfo.row.original.id), className: "text-primary font-bold cursor-pointer" }, { children: cellInfo.row.original.company.name }), void 0)); },
        width: 75,
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.clientsPage.table.column.status.label" }, void 0),
        accessor: "status_typed",
        Cell: function (cellInfo) { return (_jsx(FormattedMessage, { id: clientStatusLabel[cellInfo.value] || "common.status.hidden" }, void 0)); },
    },
];
var ClientsTable = function (_a) {
    var clients = _a.clients, loading = _a.loading, hasMore = _a.hasMore, onScroll = _a.onScroll;
    return clients && (clients === null || clients === void 0 ? void 0 : clients.length) === 0 && !loading ? (_jsx(NoResult, {}, void 0)) : (_jsx(Table, { data: clients, columns: COLUMNS, hasMore: hasMore, onScroll: onScroll, loading: loading }, void 0));
};
export default ClientsTable;
