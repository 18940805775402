var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as yup from "yup";
export var HEADER_SIZE = 5;
var convertToEmbedYoutubeLink = function (url) {
    // Possible youtube urls
    // https://www.youtube.com/watch?v=CFJ9v5xvKsQ
    // https://youtu.be/etgMbLL6Gq8
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    var youtubeId = match && match[7].length == 11 ? match[7] : undefined;
    return youtubeId
        ? "https://www.youtube.com/embed/".concat(youtubeId)
        : url.replace("watch?v=", "embed/");
};
export var queryToEditorBlocks = function (contents) {
    return {
        blocks: contents.reduce(function (acc, content) {
            var _a;
            if (content.contentInstance.__typename === "ContentQuotation") {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    {
                        type: "quote",
                        data: {
                            text: content.contentInstance.text,
                            caption: content.contentInstance.source,
                        },
                    },
                ], false);
            }
            if (content.contentInstance.__typename === "ContentSpeaker") {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    {
                        type: "speaker",
                        data: {
                            file: {
                                url: (_a = content.contentInstance.speakerImageUrl) !== null && _a !== void 0 ? _a : undefined,
                            },
                            name: content.contentInstance.name,
                            content: content.contentInstance.profile,
                            company: content.contentInstance.companyName,
                        },
                    },
                ], false);
            }
            if (content.contentInstance.__typename === "ContentHeadline") {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    {
                        type: "header",
                        data: {
                            text: content.contentInstance.text,
                            level: HEADER_SIZE,
                        },
                    },
                ], false);
            }
            if (content.contentInstance.__typename === "ContentText") {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    {
                        type: "paragraph",
                        data: {
                            text: content.contentInstance.body,
                        },
                    },
                ], false);
            }
            if (content.contentInstance.__typename === "ContentImage") {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    {
                        type: "image",
                        data: {
                            file: {
                                url: content.contentInstance.imageUrl,
                            },
                            caption: content.contentInstance.caption || "",
                        },
                    },
                ], false);
            }
            if (content.contentInstance.__typename === "ContentVideo") {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    {
                        type: "embed",
                        data: {
                            source: content.contentInstance.url,
                            embed: convertToEmbedYoutubeLink(content.contentInstance.url),
                            service: "youtube",
                        },
                    },
                ], false);
            }
            return acc;
        }, []),
    };
};
export var editorBlocksToMutation = function (values) {
    if (values === undefined || values.blocks === undefined)
        return [];
    return values.blocks.reduce(function (acc, block) {
        if (block.type === "quote") {
            return __spreadArray(__spreadArray([], __read(acc), false), [
                {
                    quotation: {
                        quotation: {
                            text: block.data.text,
                            source: block.data.caption,
                        },
                    },
                },
            ], false);
        }
        if (block.type === "speaker") {
            return __spreadArray(__spreadArray([], __read(acc), false), [
                {
                    speaker: {
                        speaker: {
                            name: block.data.name,
                            profile: block.data.content,
                            companyName: block.data.company,
                            imageUrl: block.data.file.url,
                        },
                    },
                },
            ], false);
        }
        if (block.type === "header") {
            return __spreadArray(__spreadArray([], __read(acc), false), [
                { headline: { headline: { text: block.data.text.replaceAll("&nbsp;", " ").trim() } } },
            ], false);
        }
        if (block.type === "paragraph") {
            return __spreadArray(__spreadArray([], __read(acc), false), [{ text: { text: { body: block.data.text } } }], false);
        }
        if (block.type === "image") {
            return __spreadArray(__spreadArray([], __read(acc), false), [
                { image: { image: { caption: block.data.caption, imageUrl: block.data.file.url } } },
            ], false);
        }
        if (block.type === "embed") {
            return __spreadArray(__spreadArray([], __read(acc), false), [{ video: { video: { url: block.data.source } } }], false);
        }
        return acc;
    }, []);
};
export var editorSchema = yup.object({
    blocks: yup.array().of(yup.object({
        type: yup.string(),
        data: yup.mixed().when("type", function (type) {
            if (type === "quote") {
                return yup.object({ text: yup.string(), author: yup.string() });
            }
            if (type === "paragraph") {
                return yup.object({ text: yup.string() });
            }
            if (type === "header") {
                return yup.object({ text: yup.string(), level: yup.number() });
            }
            if (type === "image") {
                return yup.object({ caption: yup.string(), file: yup.object({ url: yup.string() }) });
            }
            if (type === "embed") {
                return yup.object({ source: yup.string() });
            }
            if (type === "speaker") {
                return yup.object({
                    file: yup.object({
                        url: yup.string(),
                    }),
                    name: yup.string(),
                    content: yup.string(),
                    companyName: yup.string(),
                });
            }
            return yup.object({});
        }),
    })),
});
