var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBoundary, init as Sentryinit } from "@sentry/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/plugin/utc";
import React from "react";
import NotFoundView from "components/templates/NotFoundView";
import "./commonIndex.css";
dayjs.extend(utc);
// Logging
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "sandbox") {
    try {
        Sentryinit({
            // TODO: add sentry url for sandbox and production
            // dsn: process.env.SENTRY_URL,
            environment: process.env.NODE_ENV,
            sampleRate: 1,
            tracesSampleRate: 0, // no trace sampling
        });
    }
    catch (err) {
        // console.error(err);
    }
}
var CommonIndex = function (_a) {
    var children = _a.children;
    return (_jsx(React.StrictMode, { children: _jsx(ErrorBoundary, __assign({ fallback: _jsx(NotFoundView, {}, void 0) }, { children: children }), void 0) }, void 0));
};
export default CommonIndex;
