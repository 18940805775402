var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { useAuth } from "contexts/AuthContext";
import { ApplicationStatus } from "generated/graphql";
import { pickRole } from "_AdminApp/routes/redirect";
var columns = [
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.table.column.header.name.label" }, void 0),
        accessor: "name",
        Cell: function (cellInfo) {
            var roleSet = useAuth().roleSet;
            var role = pickRole(roleSet);
            return (_jsx("a", __assign({ className: "text-primary font-bold", href: "".concat(process.env.BAKLAVA_URL, "/").concat(role, "/users/").concat(cellInfo.row.original.entorID) }, { children: "".concat(cellInfo.row.original.lastName, " ").concat(cellInfo.row.original.firstName) }), void 0));
        },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.table.column.header.faculty.label" }, void 0),
        accessor: "faculty",
        Cell: function (cellInfo) { return (_jsxs(_Fragment, { children: [_jsx("div", { children: cellInfo.row.original.school }, void 0), _jsx("div", __assign({ className: "text-black50" }, { children: cellInfo.row.original.schoolDepartment }), void 0)] }, void 0)); },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.table.column.header.contact.label" }, void 0),
        accessor: "contact",
        Cell: function (cellInfo) { return (_jsxs(_Fragment, { children: [_jsx("div", { children: cellInfo.row.original.phoneNumber }, void 0), _jsx("div", __assign({ className: "text-black50" }, { children: cellInfo.row.original.email }), void 0)] }, void 0)); },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.table.column.header.status.label" }, void 0),
        accessor: "status",
        Cell: function (cellInfo) { return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: cellInfo.row.original.applicationStatus === ApplicationStatus.Applied
                    ? "text-success"
                    : "text-error" }, { children: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.table.status.cell.".concat(cellInfo.row.original.applicationStatus, ".label") }, void 0) }), void 0) }, void 0)); },
    },
];
var EntorsTable = function (_a) {
    var entors = _a.entors, loading = _a.loading, hasMore = _a.hasMore, onScroll = _a.onScroll;
    return entors && (entors === null || entors === void 0 ? void 0 : entors.length) === 0 && !loading ? (_jsx(NoResult, {}, void 0)) : (_jsx(Table, { columns: columns, data: entors, hasMore: hasMore, onScroll: onScroll, loading: loading }, void 0));
};
export default EntorsTable;
