var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, NavButton } from "components/atoms";
var BigLink = function (_a) {
    var to = _a.to, text = _a.text, iconName = _a.iconName;
    return (_jsxs(NavButton, __assign({ to: to, className: "mt-3 px-3 flex items-center border-transparent border-l-2", matchedClass: "border-primary" }, { children: [_jsx(Icon, { name: iconName, className: "flex pl-1 text-size-h4" }, void 0), _jsx("span", __assign({ className: "pl-2 text-size-small font-bold" }, { children: text }), void 0)] }), void 0));
};
export default BigLink;
