var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { useAuth } from "contexts/AuthContext";
import { RoleName } from "generated/graphql";
var RoleSelectForInvite = function (props) {
    var _a = useAuth(), highestRole = _a.highestRole, roleSet = _a.roleSet;
    var intl = useIntl();
    // ordered by access level (desc.)
    var ORDERED_ROLE_OPTIONS = [
        {
            id: "admin",
            label: intl.formatMessage({ id: "common.role.admin" }),
            value: RoleName.Admin,
        },
        {
            id: "head_quarter",
            label: intl.formatMessage({ id: "common.role.head_quarter" }),
            value: RoleName.HeadQuarter,
        },
        {
            id: "branch_manager",
            label: intl.formatMessage({ id: "common.role.branch_manager" }),
            value: RoleName.BranchManager,
        },
        {
            id: "core_mentor",
            label: intl.formatMessage({ id: "common.role.core_mentor" }),
            value: RoleName.CoreMentor,
        },
        {
            id: "mentor",
            label: intl.formatMessage({ id: "common.role.mentor" }),
            value: RoleName.Mentor,
        },
    ];
    var roleOptions = useMemo(function () {
        // Users can only invite those below them in terms of roles
        // e.g. Branch Managers can invite core mentors and mentors only
        if (!roleSet.has(RoleName.Admin)) {
            var idx = ORDERED_ROLE_OPTIONS.map(function (option) { return option.value; }).indexOf(highestRole || RoleName.Mentor);
            return ORDERED_ROLE_OPTIONS.slice(idx + 1);
        }
        else {
            return ORDERED_ROLE_OPTIONS;
        }
    }, [roleSet]);
    return (_jsx(SingleSelect, __assign({}, props, { options: roleOptions, name: "role", label: intl.formatMessage({ id: "common.form.field.singleSelect.role.label" }) }), void 0));
};
export default RoleSelectForInvite;
