var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Button } from "components/atoms";
var PublishBar = function (_a) {
    var publishStatuses = _a.publishStatuses, disabled = _a.disabled, children = _a.children;
    var register = useFormContext().register;
    return (_jsxs("div", __assign({ className: "flex bg-black10 w-full px-6 py-2 justify-between items-center md:flex-wrap" }, { children: [publishStatuses.length ? (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx("span", __assign({ className: "text-size-small mr-3" }, { children: _jsx(FormattedMessage, { id: "common.publishBar.title" }, void 0) }), void 0), _jsx("div", __assign({ className: "flex" }, { children: publishStatuses.map(function (option) { return (_jsxs("label", __assign({ className: "mr-3 font-bold text-black90" }, { children: [_jsx("input", __assign({}, register("status"), { className: "mr-1", type: "radio", value: option.value }), void 0), option.label] }), option.value)); }) }), void 0), children] }), void 0)) : (_jsx("div", {}, void 0)), _jsx(Button, __assign({ type: "submit", disabled: disabled }, { children: _jsx(FormattedMessage, { id: "common.button.publish.label" }, void 0) }), void 0)] }), void 0));
};
export default PublishBar;
