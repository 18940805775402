var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm from "components/templates/forms/LoginForm";
import { useAuth } from "contexts/AuthContext";
import { useLoginAdminMutation } from "generated/graphql";
var LoginPage = function () {
    var _a, _b;
    var _c = useAuth(), onAuth = _c.onAuth, clearAuth = _c.clearAuth;
    var navigate = useNavigate();
    var location = useLocation();
    var from = ((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname) || "/";
    var _d = __read(useLoginAdminMutation({
        onCompleted: function () {
            onAuth(function () { return navigate(from !== "/login" ? from : "/", { replace: true }); });
        },
        onError: function () {
            clearAuth();
        },
    }), 2), loginMutation = _d[0], loading = _d[1].loading;
    var handleSubmit = function (_a) {
        var email = _a.email, password = _a.password;
        loginMutation({ variables: { email: email, password: password } });
    };
    return (_jsx(LoginForm, { onSubmit: handleSubmit, onForgotPw: function () { return navigate("reset-password"); }, isLoading: loading }, void 0));
};
export default LoginPage;
