var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "components/atoms";
import { useAuth } from "contexts/AuthContext";
import { RoleName, useFeatureFlagsQuery } from "generated/graphql";
var baklavaUrl = process.env.BAKLAVA_URL || "http://localhost:4005";
var poblanoUrl = process.env.SSO_URL || "http://localhost:4100";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var Redirect = function (_a) {
    var children = _a.children;
    var _b = useFeatureFlagsQuery(), data = _b.data, loading = _b.loading;
    var pathname = useLocation().pathname;
    var query = useParams();
    var roleSet = useAuth().roleSet;
    var role = pickRole(roleSet);
    useEffect(function () {
        if (loading)
            return;
        if (data) {
            var rule = redirectionRules({ flags: data, pathname: pathname, query: query, role: role });
            if (rule.redirects)
                window.location.replace(rule.url);
        }
    }, [data, loading]);
    return children;
};
export var redirectionRules = function (_a) {
    var _b;
    var pathname = _a.pathname, query = _a.query, role = _a.role, flags = _a.flags;
    var _c = query || {}, queryId = _c.id, dateId = _c.dateId;
    if (!role)
        return { redirects: false, url: baklavaUrl };
    switch (pathname) {
        case "/login":
            return { redirects: false, url: "".concat(poblanoUrl) };
        case "/login/reset-password":
            return { redirects: true, url: "".concat(poblanoUrl, "/recovery") };
        case "/my-page":
            return { redirects: true, url: "".concat(baklavaUrl, "/").concat(role, "/my-profile"), target: "_blank" };
        case "/entors":
            return { redirects: true, url: "".concat(baklavaUrl, "/").concat(role, "/entors") };
        case "/entors/".concat(queryId):
            return { redirects: true, url: "".concat(baklavaUrl, "/").concat(role, "/users/").concat(queryId) };
        case "users":
        case "/users":
            if (role == RoleName.Admin)
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/users") };
            if (role == RoleName.Client)
                return { redirects: true, url: "".concat(baklavaUrl, "/client/users") };
            return { redirects: true, url: "".concat(baklavaUrl, "/mentor/mentors") };
        case "/users/new":
            if (role == RoleName.Admin)
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/user-invitations/new") };
            if (role == RoleName.Client)
                return { redirects: true, url: "".concat(poblanoUrl, "/users/new"), target: "_blank" };
            return { redirects: true, url: "".concat(baklavaUrl, "/mentor/mentors/invite") };
        case "/companies":
            return { redirects: true, url: "".concat(baklavaUrl, "/admin/organizations") };
        case "/companies/".concat(queryId):
            return { redirects: true, url: "".concat(baklavaUrl, "/admin/organizations/").concat(queryId) };
        case "/companies/new":
            return { redirects: true, url: "".concat(poblanoUrl, "/organizations/new"), target: "_blank" };
        case "branches":
        case "/branches":
            return { redirects: true, url: "".concat(baklavaUrl, "/admin/branches") };
        case "branches/new":
        case "/branches/new":
            return { redirects: true, url: "".concat(baklavaUrl, "/admin/branches/new") };
        case "/entors/questions":
            return { redirects: true, url: "".concat(baklavaUrl, "/").concat(role, "/interviews/surveys") };
    }
    if ((_b = flags === null || flags === void 0 ? void 0 : flags.baklavaFeatureFlags) === null || _b === void 0 ? void 0 : _b.baklavaEventManagement) {
        switch (pathname) {
            case "/events":
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/events") };
            case "/events/".concat(queryId):
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/events/").concat(queryId) };
            case "/events/new":
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/events/new") };
            case "/events/".concat(queryId, "/entors"):
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/events/").concat(queryId, "/attendees") };
            case "/events/".concat(queryId, "/dates"):
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/events/").concat(queryId, "/instances") };
            case "/events/".concat(queryId, "/dates/").concat(dateId, "/entors"):
                return { redirects: true, url: "".concat(baklavaUrl, "/admin/events/").concat(queryId, "/instances") };
        }
    }
    return {};
};
var divStyle = {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
};
export var RedirectLink = function () {
    var pathname = useLocation().pathname;
    var query = useParams();
    var roleSet = useAuth().roleSet;
    var role = pickRole(roleSet);
    var url = redirectionRules({ pathname: pathname, query: query, role: role }).url;
    var isCreating = url === null || url === void 0 ? void 0 : url.endsWith("/new");
    return (_jsx("div", __assign({ className: "flex justify-center", style: divStyle }, { children: _jsx("a", __assign({ className: "p-10", target: "_blank", href: url, rel: "noreferrer" }, { children: _jsx(Button, __assign({ type: "button" }, { children: isCreating ? (_jsx(FormattedMessage, { id: "admin.clientPage.basicInfo.button.create.label" }, void 0)) : (_jsx(FormattedMessage, { id: "admin.clientPage.basicInfo.button.edit.label" }, void 0)) }), void 0) }), void 0) }), void 0));
};
export function pickRole(roleSet) {
    if (roleSet.has(RoleName.Admin))
        return RoleName.Admin;
    if (roleSet.has(RoleName.Client))
        return RoleName.Client;
    if (roleSet.has(RoleName.Mentor))
        return RoleName.Mentor;
    if (roleSet.has(RoleName.BranchManager))
        return RoleName.Mentor;
    if (roleSet.has(RoleName.HeadQuarter))
        return RoleName.Mentor;
    if (roleSet.has(RoleName.CoreMentor))
        return RoleName.Mentor;
    return undefined;
}
export var ReplaceComponentWithRedirect = function (_a) {
    var children = _a.children, replace = _a.replace;
    if (replace)
        return _jsx(RedirectLink, {}, void 0);
    return _jsx(_Fragment, { children: children }, void 0);
};
export default Redirect;
