var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import useDebouncedCallback from "hooks/useDebounce";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon, Label } from "components/atoms";
import { arrayAreEqual } from "utils/helpers/arrayHelpers";
import onOutsideClick from "utils/helpers/onOutsideClick";
var MAX_OPTIONS_DISPLAYED = 50;
var BaseSelect = function (_a) {
    var selectedOptions = _a.selectedOptions, _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.description, description = _c === void 0 ? " " : _c, name = _a.name, _d = _a.isClearable, isClearable = _d === void 0 ? false : _d, _e = _a.isDisabled, isDisabled = _e === void 0 ? false : _e, label = _a.label, error = _a.error, isRequired = _a.isRequired, isSearchable = _a.isSearchable, fetchOptions = _a.fetchOptions, onSelect = _a.onSelect, defaultOption = _a.defaultOption, children = _a.children, className = _a.className;
    var _f = __read(useState(false), 2), showDropdown = _f[0], setShowDropdown = _f[1];
    var _g = __read(useState(""), 2), searchInput = _g[0], setSearchInput = _g[1];
    var _h = __read(useState(false), 2), searchLoading = _h[0], setSearchLoading = _h[1];
    var _j = __read(useState(options || []), 2), filteredOptions = _j[0], setFilteredOptions = _j[1];
    useEffect(function () {
        if (fetchOptions) {
            fetchOptions("").then(function (newOptions) {
                setSearchLoading(false);
                setFilteredOptions(newOptions);
            });
        }
    }, []);
    useEffect(function () {
        if (options.length) {
            setFilteredOptions(options);
        }
    }, [options]);
    var intl = useIntl();
    var errorMsg = typeof error === "object" && (error === null || error === void 0 ? void 0 : error.locale_id)
        ? intl.formatMessage({ id: error === null || error === void 0 ? void 0 : error.locale_id }, __assign({}, error === null || error === void 0 ? void 0 : error.locale_value))
        : error;
    var wrapperRef = useRef(null);
    onOutsideClick(wrapperRef, function () { return setShowDropdown(false); });
    var handleSelect = function (selectedOption) {
        if (selectedOption) {
            onSelect(selectedOption);
        }
        else {
            onSelect(defaultOption);
        }
        setShowDropdown(false);
    };
    var debouncedSearch = useDebouncedCallback(function (searchString) {
        if (fetchOptions) {
            setSearchLoading(true);
            fetchOptions(searchString).then(function (newOptions) {
                setSearchLoading(false);
                setFilteredOptions(newOptions);
            });
        }
        else {
            // Default search is done by label
            setFilteredOptions(searchString
                ? options.filter(function (option) { return option.label.toLowerCase().includes(searchString); })
                : options);
        }
    }, 500);
    var handleSearch = function (e) {
        e.preventDefault();
        setSearchInput(e.target.value);
        debouncedSearch(e.target.value);
    };
    var handleOpenDropdown = function () {
        if (!isDisabled) {
            setShowDropdown(true);
            if (searchable) {
                setSearchInput("");
            }
        }
    };
    var handleClear = function () {
        if (!isDisabled) {
            onSelect(defaultOption);
        }
    };
    var hasSelected = selectedOptions.length > 0;
    var searchable = fetchOptions || isSearchable || options.length > MAX_OPTIONS_DISPLAYED;
    var canClear = isClearable && hasSelected;
    var selectedOptionIds = useMemo(function () { return selectedOptions.map(function (option) { return option.id; }); }, [selectedOptions]);
    return (_jsx("div", __assign({ ref: wrapperRef, className: classNames("w-full flex flex-col justify-center min-h-9", className) }, { children: _jsxs("div", __assign({ className: "relative min-h-5" }, { children: [_jsxs("div", __assign({ className: classNames("relative flex justify-between border-b", {
                        "!border-error": error,
                        "border-primary": showDropdown,
                        "border-black25 text-black25 cursor-not-allowed": isDisabled,
                        "cursor-pointer": !isDisabled,
                        "cursor-not-allowed": isDisabled,
                    }) }, { children: [_jsx("div", __assign({ className: "flex content-center flex-grow min-h-5", onClick: handleOpenDropdown }, { children: _jsx("div", __assign({ className: "flex flex-wrap w-full items-center overflow-auto max-h-80" }, { children: children }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex flex-row" }, { children: [canClear && (_jsx(Icon, { name: "X", className: "flex my-1 max-h-5 text-size-h5 float-right text-gray-300 z-1", onClick: handleClear }, void 0)), _jsx(Icon, { name: showDropdown ? "ChevronUp" : "ChevronDown", className: "flex text-size-h4 float-right", onClick: handleOpenDropdown }, void 0)] }), void 0), ((errorMsg && typeof errorMsg === "string") || description) && (_jsx("p", __assign({ className: classNames("absolute -bottom-3 h-3 text-caption leading-caption", {
                                "text-error": errorMsg,
                            }) }, { children: errorMsg || description }), void 0))] }), void 0), showDropdown && (_jsxs("div", __assign({ className: "absolute z-10 bg-white shadow-lg rounded-md border border-black10 my-1 w-full" }, { children: [searchable && (_jsx("div", __assign({ className: "p-2" }, { children: _jsx("input", { autoFocus: true, onChange: handleSearch, id: name, className: "w-full border-2 border-primary rounded p-1 self-center h-5 text-black90 text-small outline-none", value: searchInput }, void 0) }), "input")), !filteredOptions.length || searchLoading ? (_jsx("div", __assign({ className: "p-2 text-center text-gray-300" }, { children: searchLoading ? (_jsx(FormattedMessage, { id: "common.form.field.search.loading" }, void 0)) : (_jsx(FormattedMessage, { id: "common.form.field.search.notFound" }, void 0)) }), void 0)) : (_jsx("ul", __assign({ className: "max-h-64 overflow-auto border-t border-black10" }, { children: filteredOptions.slice(0, MAX_OPTIONS_DISPLAYED).map(function (option) { return (_jsx("li", __assign({ className: classNames("cursor-pointer p-2 hover:bg-primary10", {
                                    "bg-primary10": selectedOptionIds.includes(option.id),
                                }), onClick: function () { return handleSelect(option); } }, { children: option.label }), option.id)); }) }), void 0))] }), void 0)), _jsx(Label, __assign({ htmlFor: name, className: classNames("py-1 transform transition-all absolute top-0 left-0 items-center text-small", {
                        "text-caption h-1/2 -translate-y-full py-0 cursor-text": hasSelected,
                        "cursor-pointer": !showDropdown && !isDisabled,
                        "cursor-not-allowed text-black25": isDisabled,
                    }), isRequired: isRequired, onClick: handleOpenDropdown }, { children: label }), void 0)] }), void 0) }), void 0));
};
export default memo(BaseSelect, function (prevProps, nextProps) {
    return arrayAreEqual(prevProps.selectedOptions, nextProps.selectedOptions) &&
        arrayAreEqual(prevProps.options, nextProps.options) &&
        prevProps.error === nextProps.error &&
        prevProps.isRequired === nextProps.isRequired &&
        prevProps.isDisabled === nextProps.isDisabled;
});
