var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { Spinner } from "components/atoms";
import NotFoundView from "components/templates/NotFoundView";
import { useAuth } from "contexts/AuthContext";
import { RoleName } from "generated/graphql";
import { coreMentorAndAboveRoles } from "utils/constants";
import Branches from "_AdminApp/pages/private/Branches";
import { ClientList, ClientView } from "_AdminApp/pages/private/Clients";
import { EntorList, EntorQuestions, EntorView } from "_AdminApp/pages/private/Entors";
import { EventDateAttendeesView, EventDateView, EventEntorsView, EventView, EventsList, } from "_AdminApp/pages/private/Events";
import Privacy from "_AdminApp/pages/private/Privacy";
import Profile from "_AdminApp/pages/private/Profile";
import Sync from "_AdminApp/pages/private/Sync";
import Terms from "_AdminApp/pages/private/Terms";
import Users from "_AdminApp/pages/private/Users";
import ForgotPassword from "_AdminApp/pages/public/ForgotPassword";
import Login from "_AdminApp/pages/public/Login";
import RegisterUser from "_AdminApp/pages/public/RegisterUser";
import ResetPassword from "_AdminApp/pages/public/ResetPassword";
import PrivateLayout from "../layouts/Private";
import PublicLayout from "../layouts/Public";
import Redirect from "./redirect";
export var publicRoutePath = {
    login: "/login",
    account: "/account",
};
export var routeRoles = {
    newEvent: coreMentorAndAboveRoles,
    newUser: coreMentorAndAboveRoles,
    entorQuestions: [RoleName.Admin],
    clientManagement: [RoleName.Admin],
    branchManagement: [RoleName.Admin],
};
var privateRoutes = [
    { index: true, element: _jsx(Navigate, { to: "/events" }, void 0) },
    {
        path: "events",
        element: (_jsx(Redirect, { children: _jsx(Outlet, {}, void 0) }, void 0)),
        children: [
            { index: true, element: _jsx(EventsList, {}, void 0) },
            { path: "/events/new", element: _jsx(EventView, {}, void 0), permittedRoles: routeRoles.newEvent },
            { path: "/events/:id", element: _jsx(EventView, {}, void 0) },
            { path: "/events/:id/entors", element: _jsx(EventEntorsView, {}, void 0) },
            { path: "/events/:id/dates", element: _jsx(EventDateView, {}, void 0) },
            { path: "/events/:id/dates/:dateId/entors", element: _jsx(EventDateAttendeesView, {}, void 0) },
        ],
    },
    {
        path: "entors",
        element: (_jsx(Redirect, { children: _jsx(Outlet, {}, void 0) }, void 0)),
        children: [
            { index: true, element: _jsx(EntorList, {}, void 0) },
            { path: "/entors/:id", element: _jsx(EntorView, {}, void 0) },
            {
                path: "/entors/questions",
                element: _jsx(EntorQuestions, {}, void 0),
                permittedRoles: routeRoles.entorQuestions,
            },
        ],
    },
    {
        path: "users",
        element: (_jsx(Redirect, { children: _jsx(Outlet, {}, void 0) }, void 0)),
        children: [
            { index: true, element: _jsx(Users, {}, void 0) },
            { path: "/users/new", element: _jsx(Users, {}, void 0), permittedRoles: routeRoles.newUser },
        ],
    },
    {
        path: "companies",
        element: (_jsx(Redirect, { children: _jsx(Outlet, {}, void 0) }, void 0)),
        permittedRoles: routeRoles.clientManagement,
        children: [
            { index: true, element: _jsx(ClientList, {}, void 0) },
            { path: "/companies/:id", element: _jsx(ClientView, {}, void 0) },
            { path: "/companies/new", element: _jsx(ClientView, {}, void 0) },
            { path: "/companies/sync-legacy-companies", element: _jsx(Sync, {}, void 0) },
        ],
    },
    {
        path: "branches",
        element: (_jsx(Redirect, { children: _jsx(Outlet, {}, void 0) }, void 0)),
        permittedRoles: routeRoles.branchManagement,
        children: [
            { index: true, element: _jsx(Branches, {}, void 0) },
            { path: "/branches/new", element: _jsx(Branches, {}, void 0) },
        ],
    },
    {
        path: "my-page",
        element: (_jsx(Redirect, { children: _jsx(Profile, {}, void 0) }, void 0)),
    },
    { path: "terms", element: _jsx(Terms, {}, void 0) },
    { path: "privacy", element: _jsx(Privacy, {}, void 0) },
];
// convert private route to regular route objects
var convertPrivateToRoutes = function (privateRoutes, roleSet) {
    return privateRoutes.map(function (pr) {
        var _a;
        // only render the element and children if the roles permit it
        var isPermitted = !pr.permittedRoles || ((_a = pr.permittedRoles) === null || _a === void 0 ? void 0 : _a.some(function (role) { return roleSet.has(role); }));
        return {
            caseSensitive: pr.caseSensitive,
            index: pr.index,
            path: pr.path,
            children: pr.children && isPermitted && convertPrivateToRoutes(pr.children, roleSet),
            element: isPermitted ? pr.element : _jsx(NotFoundView, { unauthorized: true }, void 0),
        };
    });
};
var Routes = function () {
    var _a = useAuth(), isAuthenticated = _a.isAuthenticated, roleSet = _a.roleSet, isAuthenticating = _a.isAuthenticating;
    var routes = useMemo(function () { return [
        {
            path: "/",
            element: isAuthenticated ? (_jsx(PrivateLayout, { roleSet: roleSet }, void 0)) : isAuthenticating ? (_jsx(Spinner, {}, void 0)) : (_jsx(Navigate, { to: "/login" }, void 0)),
            children: __spreadArray(__spreadArray([], __read(convertPrivateToRoutes(privateRoutes, roleSet)), false), [
                { path: "*", element: _jsx(NotFoundView, {}, void 0) },
            ], false),
        },
        // Public
        {
            path: publicRoutePath.login,
            element: _jsx(PublicLayout, {}, void 0),
            children: [
                { index: true, element: _jsx(Login, {}, void 0) },
                {
                    path: "reset-password",
                    element: (_jsx(Redirect, { children: _jsx(ForgotPassword, {}, void 0) }, void 0)),
                },
            ],
        },
        {
            path: publicRoutePath.account,
            element: (_jsx(Redirect, { children: _jsx(PublicLayout, {}, void 0) }, void 0)),
            children: [
                { path: "password/reset", element: _jsx(ResetPassword, {}, void 0) },
                { path: "new", element: _jsx(RegisterUser, {}, void 0) },
            ],
        },
        { path: "/*", element: _jsx(NotFoundView, { withGoToLogin: true }, void 0) },
    ]; }, [isAuthenticated, roleSet]);
    var routeElemtent = useRoutes(routes);
    return routeElemtent;
};
export default Routes;
