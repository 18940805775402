import { ClientStatus, Evaluation, RoleName } from "generated/graphql";
var evaluationMap = function (evaluation) {
    switch (evaluation) {
        case Evaluation.GePlus:
            return "GE+";
        case Evaluation.Ge:
            return "GE";
        case Evaluation.GeMinus:
            return "GE-";
        case Evaluation.NePlus:
            return "NE+";
        case Evaluation.Ne:
            return "NE";
        case Evaluation.NeMinus:
            return "NE-";
        case Evaluation.PePlus:
            return "PE+";
        case Evaluation.Pe:
            return "PE";
        case Evaluation.PeMinus:
            return "PE-";
    }
};
var clientStatusMap = function (status) {
    switch (status) {
        case ClientStatus.Hidden:
            return ClientStatus.Hidden;
        case ClientStatus.Visible:
            return ClientStatus.Visible;
        case ClientStatus.NoIndex:
            return ClientStatus.NoIndex;
    }
    return ClientStatus.Visible;
};
// Currently used to map only admin/hq/bm/cm/m
var roleNameToJPMap = function (roleName) {
    switch (roleName) {
        case RoleName.Admin:
            return "アドミン";
        case RoleName.HeadQuarter:
            return "本部メンター";
        case RoleName.BranchManager:
            return "ブランチマネージャー";
        case RoleName.CoreMentor:
            return "コアメンター";
        case RoleName.Mentor:
            return "メンター";
    }
    return undefined;
};
export { evaluationMap, clientStatusMap, roleNameToJPMap };
