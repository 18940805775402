var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "components/atoms";
var WarningMessageModal = function (_a) {
    var onCancel = _a.onCancel, onOk = _a.onOk;
    return (_jsx(Modal, { children: _jsxs("div", __assign({ className: "relative py-4 px-6" }, { children: [_jsx("h5", __assign({ className: "font-bold mb-1" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.warningModal.mainWarningMessage" }, void 0) }), void 0), _jsx("small", __assign({ className: "text-size-small text-black50" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.warningModal.detailsWarningMessage" }, void 0) }), void 0), _jsxs("div", __assign({ className: "w-full items-center justify-end flex mt-5 gap-3" }, { children: [_jsx(Button, __assign({ onClick: onCancel, inverted: true }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.warningModal.cancelButtonLabel" }, void 0) }), void 0), _jsx(Button, __assign({ onClick: onOk, className: "bg-error" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.warningModal.confirmButtonLabel" }, void 0) }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default WarningMessageModal;
