var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Button, Spinner, TextArea } from "components/atoms";
import { useAlert } from "contexts/AlertContext";
import { useUpdateEntorCompanyRecommendationMutation, useUpdateEntorInterviewAnswersMutation, } from "generated/graphql";
var answerSchema = yup.object({
    id: yup.number().required(),
    text: yup.string(),
    interviewQuestionId: yup.number(),
});
var formSchema = yup.object({
    companyRecommendation: yup.string().max(1000),
    answers: yup.array().of(answerSchema),
});
export var Interview = function (_a) {
    var _b;
    var canEdit = _a.canEdit, interviewAnswers = _a.interviewAnswers, interviewCategories = _a.interviewCategories, companyRecommendation = _a.companyRecommendation, entorId = _a.entorId;
    var intl = useIntl();
    var alert = useAlert();
    var _c = __read(useState(null), 2), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var allQuestions = interviewCategories === null || interviewCategories === void 0 ? void 0 : interviewCategories.flatMap(function (category) {
        return category.subCategories.flatMap(function (sub) { return sub.questions; });
    });
    var initialValues = {
        companyRecommendation: (companyRecommendation === null || companyRecommendation === void 0 ? void 0 : companyRecommendation.text) || undefined,
        answers: allQuestions.map(function (q) {
            var _a;
            return {
                id: q.id,
                text: ((_a = interviewAnswers === null || interviewAnswers === void 0 ? void 0 : interviewAnswers.find(function (a) { return a.interviewQuestionId === q.id; })) === null || _a === void 0 ? void 0 : _a.text) || undefined,
                interviewQuestionId: q.id,
            };
        }) || [],
    };
    var _d = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(formSchema),
    }), register = _d.register, reset = _d.reset, getValues = _d.getValues, submit = _d.handleSubmit, _e = _d.formState, isDirty = _e.isDirty, isSubmitting = _e.isSubmitting, isSubmitted = _e.isSubmitted, dirtyFields = _e.dirtyFields, errors = _e.errors;
    var answers = getValues("answers") || [];
    var canSubmit = (Object.keys(errors).length === 0 && !isSubmitting && isDirty) || (isSubmitted && isDirty);
    var _f = __read(useUpdateEntorInterviewAnswersMutation({
        onCompleted: function () {
            alert.onSuccessAlert(intl.formatMessage({
                id: "admin.entorPage.interviewTab.interviewAnswers.udpateCompleted",
            }));
        },
    }), 2), updateEntorInterviewAnswersMutation = _f[0], interivewAnswersUpdateLoading = _f[1].loading;
    var _g = __read(useUpdateEntorCompanyRecommendationMutation({
        onCompleted: function () {
            alert.onSuccessAlert(intl.formatMessage({
                id: "admin.entorPage.interviewTab.companyRecommendation.udpateCompleted",
            }));
        },
    }), 2), updateEntorCompanyRecommendation = _g[0], companyRecommendationUpdateLoading = _g[1].loading;
    var handleSubmit = submit(function (data) {
        var _a;
        if (dirtyFields.companyRecommendation &&
            data.companyRecommendation &&
            data.companyRecommendation.length > 0) {
            updateEntorCompanyRecommendation({
                variables: {
                    entorId: entorId,
                    input: {
                        text: data.companyRecommendation,
                    },
                },
            });
        }
        if (dirtyFields.answers && dirtyFields.answers.length > 0) {
            var validInterviewAnswersInput = ((_a = data.answers) === null || _a === void 0 ? void 0 : _a.filter(function (answer) { return answer.text; }).map(function (a) { return ({ questionId: a.interviewQuestionId, text: a.text }); })) || [];
            if (validInterviewAnswersInput.length > 0) {
                updateEntorInterviewAnswersMutation({
                    variables: {
                        entorId: entorId,
                        interviewAnswers: validInterviewAnswersInput,
                    },
                });
            }
        }
        reset(__assign({}, data));
    });
    if (interivewAnswersUpdateLoading || companyRecommendationUpdateLoading) {
        return _jsx(Spinner, {}, void 0);
    }
    return (_jsxs("form", __assign({ noValidate: true, className: "h-full flex flex-col flex-1", onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: "bg-white p-4 flex flex-1" }, { children: [_jsxs("div", __assign({ className: "w-1/4" }, { children: [_jsx("div", __assign({ className: classNames("text-center p-2 border rounded text-size-small mb-3 cursor-pointer font-bold border-black10", { "text-white bg-black10": selectedCategory === null }, { "text-primary bg-white": selectedCategory !== null }), onClick: function () {
                                    setSelectedCategory(null);
                                } }, { children: intl.formatMessage({
                                    id: "admin.entorPage.interviewTab.companyRecommendation.label",
                                }) }), void 0), interviewCategories.map(function (interviewCategory) { return (_jsx("div", __assign({ className: classNames("text-center p-2 mb-3 border rounded border-black10 text-size-small cursor-pointer font-bold", {
                                    "text-white bg-black10": selectedCategory && selectedCategory.id === interviewCategory.id,
                                }, {
                                    "text-primary bg-white": (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id) !== interviewCategory.id,
                                }), onClick: function () {
                                    setSelectedCategory(interviewCategory);
                                } }, { children: interviewCategory.title }), interviewCategory.id)); })] }), void 0), _jsx("div", __assign({ className: "flex-1 px-5" }, { children: selectedCategory === null ? (_jsx(TextArea, __assign({ disabled: !canEdit, rows: 10, label: "" }, register("companyRecommendation"), { description: intl.formatMessage({
                                id: "admin.entorPage.interviewTab.companyRecommendation.description",
                            }), error: (_b = errors.companyRecommendation) === null || _b === void 0 ? void 0 : _b.message }), void 0)) : (_jsx("div", { children: selectedCategory.subCategories.map(function (subCategory) { return (_jsxs("div", { children: [_jsx("h5", __assign({ className: "font-bold mb-3" }, { children: subCategory.title }), void 0), subCategory.questions.map(function (question) {
                                        var _a, _b, _c;
                                        var idx = answers.map(function (a) { return a.interviewQuestionId; }).indexOf(question.id);
                                        return (_jsx("div", { children: _jsx(TextArea, __assign({ disabled: !canEdit }, register("answers.".concat(idx, ".text")), { className: "m-4", label: question.title, rows: 3, error: (_c = (_b = (_a = errors === null || errors === void 0 ? void 0 : errors.answers) === null || _a === void 0 ? void 0 : _a[idx]) === null || _b === void 0 ? void 0 : _b.text) === null || _c === void 0 ? void 0 : _c.message }), void 0) }, question.id));
                                    })] }, subCategory.id)); }) }, void 0)) }), void 0)] }), void 0), canEdit && (_jsxs("div", __assign({ className: "w-full bg-black10 z-10 flex items-center justify-center py-2 mt-auto gap-4" }, { children: [_jsx(Button, __assign({ type: "button", onClick: function () { return reset(); }, disabled: Object.keys(errors).length > 0 }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.cancelButton.label" }, void 0) }), void 0), _jsx(Button, __assign({ type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.saveButton.label" }, void 0) }), void 0)] }), void 0))] }), void 0));
};
