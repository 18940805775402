var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import React from "react";
import { useIntl } from "react-intl";
import Label from "./Label";
var Input = React.forwardRef(function (_a, refs) {
    var label = _a.label, error = _a.error, isRequired = _a.isRequired, autocomplete = _a.autocomplete, disabled = _a.disabled, name = _a.name, description = _a.description, className = _a.className, readOnly = _a.readOnly, rest = __rest(_a, ["label", "error", "isRequired", "autocomplete", "disabled", "name", "description", "className", "readOnly"]);
    var intl = useIntl();
    var errorMsg = typeof error === "object" && (error === null || error === void 0 ? void 0 : error.locale_id)
        ? intl.formatMessage({ id: error === null || error === void 0 ? void 0 : error.locale_id }, __assign({}, error === null || error === void 0 ? void 0 : error.locale_value))
        : error;
    return (_jsx("div", __assign({ className: classNames(className, "w-full flex flex-col justify-center h-9") }, { children: _jsxs("div", __assign({ className: "relative group h-5" }, { children: [_jsx("input", __assign({}, rest, { name: name, ref: refs, id: name, readOnly: readOnly, disabled: disabled, 
                    /*
                      This makes peer-valid selector on the label trigger any time
                      there's a value in the input. Forcing required will not interfere
                      with our usage since input validation is done with a schema
                      (yup) and not natively on the input.
                    */
                    required: true, className: classNames("py-1 bg-transparent h-5 absolute border-b text-black90 w-full text-small peer outline-none focus:border-primary", { "!border-error": error }, { "text-black25 cursor-not-allowed": disabled }, { "cursor-not-allowed focus:border-black": readOnly }), autoComplete: autocomplete }), void 0), label && (_jsx(Label, __assign({ htmlFor: name, className: classNames("py-1", "transform", "transition-all", "absolute", "top-0", "left-0", "items-center", "text-small", "group-focus-within:text-caption", "peer-valid:text-caption", "group-focus-within:h-1/2", "peer-valid:h-1/2", "group-focus-within:-translate-y-full", "peer-valid:-translate-y-full", "group-focus-within:py-0", "peer-valid:py-0", "cursor-text", { "h-1/2 text-caption py-0 -translate-y-full": readOnly }, { "text-black25 cursor-not-allowed": disabled }), isRequired: !!isRequired }, { children: label }), void 0)), ((errorMsg && typeof errorMsg === "string") || description) && (_jsx("p", __assign({ className: classNames("relative top-5 h-3 text-caption leading-caption", {
                        "text-error": errorMsg,
                    }) }, { children: errorMsg || description }), void 0))] }), void 0) }), void 0));
});
Input.displayName = "Input";
export default Input;
