var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import Select from "components/molecules/Select";
var SingleSelect = function (_a) {
    var selectedValue = _a.selectedValue, onSelect = _a.onSelect, options = _a.options, description = _a.description, defaultValue = _a.defaultValue, _b = _a.isClearable, isClearable = _b === void 0 ? true : _b, rest = __rest(_a, ["selectedValue", "onSelect", "options", "description", "defaultValue", "isClearable"]);
    var selectedOpt = useMemo(function () { return options === null || options === void 0 ? void 0 : options.find(function (option) { return option.value === selectedValue; }); }, [selectedValue]);
    var handleSelect = function (newValue) {
        if (newValue) {
            onSelect(newValue.value);
        }
        else {
            onSelect(defaultValue);
        }
    };
    return (_jsx(Select, __assign({}, rest, { options: options, selectedOption: selectedOpt, onSelect: handleSelect, isClearable: isClearable }), void 0));
};
export default SingleSelect;
