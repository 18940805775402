var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Modal, Spinner } from "components/atoms";
import NotFoundView from "components/templates/NotFoundView";
import BasicInfoForm from "components/templates/forms/BasicUserInfoForm";
import ChangePWForm from "components/templates/forms/PWForm/ChangePWForm";
import { useAlert } from "contexts/AlertContext";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import { CurrentAdminDocument, CurrentMentorDocument, RoleName, useChangePasswordMutation, useCurrentMentorQuery, useUpdateMentorMutation, } from "generated/graphql";
import { useCurrentAdminQuery, useUpdateAdminMutation } from "generated/graphql";
import { DATE_FORMAT_YYYYMMDD } from "utils/constants";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
var Profile = function () {
    var alert = useAlert();
    var intl = useIntl();
    var modal = useModal();
    var client = useApolloClient();
    var _a = useAuth(), roleSet = _a.roleSet, userEmail = _a.userEmail;
    var _b = useCurrentAdminQuery({
        skip: !roleSet.has(RoleName.Admin),
    }), dataAdmin = _b.data, admingLoading = _b.loading, adminError = _b.error;
    var _c = useCurrentMentorQuery({
        skip: roleSet.has(RoleName.Admin),
    }), dataMentor = _c.data, mentorLoading = _c.loading, mentorError = _c.error;
    var initialValues = dataAdmin
        ? {
            firstName: dataAdmin.currentAdmin.firstName,
            lastName: dataAdmin.currentAdmin.lastName,
            firstNameKana: dataAdmin.currentAdmin.firstNameKana,
            lastNameKana: dataAdmin.currentAdmin.lastNameKana,
            phoneNumber: dataAdmin.currentAdmin.phoneNumber,
            gender: dataAdmin.currentAdmin.gender,
            email: dataAdmin.currentAdmin.email || undefined,
        }
        : dataMentor
            ? {
                firstName: dataMentor.currentMentor.firstName,
                lastName: dataMentor.currentMentor.lastName,
                firstNameKana: dataMentor.currentMentor.firstNameKana,
                lastNameKana: dataMentor.currentMentor.lastNameKana,
                phoneNumber: dataMentor.currentMentor.phoneNumber,
                gender: dataMentor.currentMentor.gender || undefined,
                email: dataMentor.currentMentor.email,
                lineId: dataMentor.currentMentor.lineId || undefined,
                birthday: dataMentor.currentMentor.birthday &&
                    dayjs(dataMentor.currentMentor.birthday).format(DATE_FORMAT_YYYYMMDD),
                highSchoolName: dataMentor.currentMentor.highSchoolName || undefined,
                educations: dataMentor.currentMentor.educations || undefined,
            }
            : undefined;
    var _d = __read(useUpdateAdminMutation({
        onCompleted: function (newData) {
            var oldData = client.cache.readQuery({
                query: CurrentAdminDocument,
            });
            client.cache.writeQuery({
                query: CurrentAdminDocument,
                data: {
                    currentAdmin: __assign({ email: oldData.currentAdmin.email ? oldData.currentAdmin.email : userEmail }, newData.updateCurrentAdmin),
                },
            });
            alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
        },
    }), 1), updateAdminMutation = _d[0];
    var _e = __read(useUpdateMentorMutation({
        onCompleted: function (newData) {
            var oldData = client.cache.readQuery({
                query: CurrentMentorDocument,
            });
            client.cache.writeQuery({
                query: CurrentMentorDocument,
                data: {
                    currentMentor: __assign(__assign({ userId: oldData.currentMentor.userId, email: oldData.currentMentor.email ? oldData.currentMentor.email : userEmail }, newData.updateCurrentMentor), { educations: oldData.currentMentor.educations }),
                },
            });
            alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
        },
    }), 1), updateMentorMutation = _e[0];
    var _f = __read(useChangePasswordMutation({
        onCompleted: function () {
            alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
            modal.close();
        },
    }), 1), changePasswordMutation = _f[0];
    var handleSubmit = function (data) {
        if (roleSet.has(RoleName.Admin)) {
            updateAdminMutation({ variables: data });
        }
        else {
            updateMentorMutation({
                variables: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    firstNameKana: data.firstNameKana,
                    lastNameKana: data.lastNameKana,
                    gender: data.gender,
                    phoneNumber: data.phoneNumber,
                    highSchoolName: data.highSchoolName,
                    birthday: dayjs.utc(data.birthday).format(),
                    lineId: data.lineId,
                },
            });
        }
    };
    var handleChangePW = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, changePasswordMutation({
                            variables: { oldPassword: data.currentPassword, newPassword: data.newPassword },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/, { isValid: true }];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, { isValid: false }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleChangePWClick = function () {
        modal.show(_jsx(Modal, __assign({ size: "md" }, { children: _jsx(ChangePWForm, { onSubmit: handleChangePW }, void 0) }), void 0));
    };
    if (mentorError || adminError) {
        return _jsx(NotFoundView, {}, void 0);
    }
    if (admingLoading || mentorLoading) {
        return _jsx(Spinner, {}, void 0);
    }
    return (_jsxs("div", __assign({ className: "w-full h-auto mt-4 flex flex-col" }, { children: [_jsx("h4", __assign({ className: "px-6 py-4 bg-gradient" }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.header.label" }, void 0) }), void 0), _jsx("div", __assign({ className: "bg-white flex0 h-full relative overflow-auto" }, { children: _jsx(BasicInfoForm, { isAdmin: roleSet.has(RoleName.Admin), onSubmit: handleSubmit, onChangePWClick: handleChangePWClick, isLoading: false, initialValues: initialValues }, void 0) }), void 0)] }), void 0));
};
var CheckForFlags = function () {
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: true }, { children: _jsx(Profile, {}, void 0) }), void 0));
};
export default CheckForFlags;
