var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useDebouncedCallback from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Input } from "components/atoms";
import Header from "components/molecules/Header";
var FilterHeader = function (_a) {
    var inputs = _a.inputs, initialFilters = _a.initialFilters, onFilter = _a.onFilter, _b = _a.disabled, disabled = _b === void 0 ? false : _b, rest = __rest(_a, ["inputs", "initialFilters", "onFilter", "disabled"]);
    var inputNames = inputs.map(function (input) { return input.name; });
    var _c = __read(useState(initialFilters), 2), filters = _c[0], setFilters = _c[1];
    useEffect(function () {
        setFilters(initialFilters);
    }, [initialFilters]);
    var debouncedOnFilter = useDebouncedCallback(function (newFilters) { return onFilter(newFilters); }, 500);
    var handleFilter = function (value, inputName, isDebounced) {
        setFilters(function (prevFilter) {
            var _a;
            var newFilters = __assign(__assign({}, prevFilter), (_a = {}, _a[inputName] = value, _a));
            // Clear all empty filters
            for (var key in newFilters) {
                if (!newFilters[key] ||
                    (newFilters[key] instanceof Array &&
                        newFilters[key].length < 1)) {
                    delete newFilters[key];
                }
            }
            if (isDebounced) {
                debouncedOnFilter(newFilters);
            }
            else {
                onFilter(newFilters);
            }
            return newFilters;
        });
    };
    var handleClearSearch = function () {
        setFilters({});
        onFilter({});
    };
    return (_jsx(Header, __assign({}, rest, { children: _jsxs("div", __assign({ className: "w-full flex flex-wrap items-center" }, { children: [inputs.map(function (input) { return (_jsx("div", __assign({ className: "".concat(input.width || "w-input", " p-2") }, { children: input.Component ? (input.singleSelect ? (_jsx(input.Component, { selectedValue: filters[input.name], onSelect: function (selected) { return handleFilter(selected, input.name); }, defaultValue: input.defaultValue, isDisabled: disabled }, input.name)) : (_jsx(input.Component, { selectedValues: filters[input.name] || [], onSelect: function (selected) {
                            return handleFilter(selected, input.name);
                        }, isDisabled: disabled }, input.name))) : (_jsx(Input, { name: input.name, label: input.label, type: "text", value: filters[input.name] || "", onChange: function (e) { return handleFilter(e.currentTarget.value, input.name, true); }, disabled: disabled }, input.name)) }), input.name)); }), _jsx(Button, __assign({ type: "button", onClick: handleClearSearch, inverted: true }, { children: _jsx(FormattedMessage, { id: "common.button.clear.label" }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default FilterHeader;
