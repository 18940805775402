var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import noResultImg from "assets/images/noResults.png";
var NoResult = function () { return (_jsx("div", __assign({ className: "h-full w-full flex flex-col justify-center items-center overflow-y-scroll bg-white" }, { children: _jsx("div", __assign({ className: "flex flex-col items-center w-single-asset" }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center justify-center text-center" }, { children: [_jsx("div", __assign({ className: "mb-4 items-center justify-center flex" }, { children: _jsx("img", { className: "w-3/5", src: noResultImg, alt: "noResults" }, void 0) }), void 0), _jsx("div", __assign({ className: "mb-2 text-black50 font-bold text-h6" }, { children: _jsx(FormattedMessage, { id: "common.component.noResult.title" }, void 0) }), void 0), _jsx("div", __assign({ className: "text-black50 text-small font-regular" }, { children: _jsx(FormattedMessage, { id: "common.component.noResult.subtitle" }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0)); };
export default NoResult;
