var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { DATE_FORMAT_YYYYMMDDHM } from "utils/constants";
export var InterviewHistory = function (_a) {
    var interviewRecords = _a.interviewRecords;
    var intl = useIntl();
    var records = __spreadArray([], __read((interviewRecords || [])), false);
    records.sort(function (a, b) {
        return dayjs(a.editTime).isBefore(dayjs(b.editTime), "seconds") ? 1 : -1;
    });
    return (_jsx("div", __assign({ className: "w-full h-full flex flex-col justify-between flex-1" }, { children: _jsx("div", __assign({ className: "bg-white" }, { children: _jsxs("table", __assign({ className: "h-full w-full bg-white" }, { children: [_jsx("thead", __assign({ className: "w-full h-5 bg-background-grey text-size-caption text-left" }, { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "font-regular p-1 pl-4 text-black90" }, { children: intl.formatMessage({
                                        id: "admin.entorPage.historyTab.companyRecommendation.editTime",
                                    }) }), void 0), _jsx("th", __assign({ className: "font-regular p-1 pl-4 text-black90" }, { children: intl.formatMessage({
                                        id: "admin.entorPage.historyTab.companyRecommendation.editorName",
                                    }) }), void 0)] }, void 0) }), void 0), _jsx("tbody", { children: records.map(function (record) { return (_jsxs("tr", __assign({ className: "bg-white border-b border-black10 text-black90" }, { children: [_jsx("td", __assign({ className: "px-4 py-2 text-size-caption text-left" }, { children: _jsx("div", { children: dayjs(record.editTime).format(DATE_FORMAT_YYYYMMDDHM) }, void 0) }), void 0), _jsx("td", __assign({ className: "px-4 py-2 text-size-caption text-left" }, { children: _jsx("div", { children: record.editUserName }, void 0) }), void 0)] }), "".concat(record.id + record.editTime))); }) }, void 0)] }), void 0) }), void 0) }), void 0));
};
