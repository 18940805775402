var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { useInterviewQuestionsQuery } from "generated/graphql";
var QuestionCategorySelect = function (props) {
    var data = useInterviewQuestionsQuery().data;
    var options = data === null || data === void 0 ? void 0 : data.interviewCategories.map(function (category) {
        var _a;
        return ({
            label: (_a = category.title) !== null && _a !== void 0 ? _a : "",
            value: category.id,
            id: category.id,
        });
    });
    return (_jsx(SingleSelect, __assign({}, props, { name: "questioCategory", label: _jsx(FormattedMessage, { id: "common.form.field.singleSelect.questionCategory.label" }, void 0), options: options }), void 0));
};
export default QuestionCategorySelect;
