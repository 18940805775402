var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { Button, Input, Spinner } from "components/atoms";
import { useModal } from "contexts/ModalContext";
import { pwSchema } from "utils/commonSchemas";
var schema = yup.object({
    currentPassword: yup.string().required(),
    newPassword: pwSchema.required(),
    confirmNewPassword: pwSchema.required().oneOf([yup.ref("newPassword")], function () { return ({
        locale_id: "common.input.password.error.matchingPassword",
    }); }),
});
var ChangePasswordForm = function (_a) {
    var _b, _c, _d;
    var onSubmit = _a.onSubmit;
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var modal = useModal();
    var _f = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },
        resolver: yupResolver(schema),
    }), reset = _f.reset, register = _f.register, submit = _f.handleSubmit, setError = _f.setError, _g = _f.formState, errors = _g.errors, isSubmitting = _g.isSubmitting, isSubmitted = _g.isSubmitted, dirtyFields = _g.dirtyFields;
    var handleSubmit = function (formData) {
        setIsLoading(true);
        onSubmit(formData).then(function (_a) {
            var isValid = _a.isValid;
            if (isValid) {
                setIsLoading(false);
                reset(__assign({}, formData));
            }
            else {
                setIsLoading(false);
                reset(__assign({}, formData), { keepDirty: false });
                setError("currentPassword", { message: " " });
                setError("newPassword", { message: " " });
                setError("confirmNewPassword", { message: " " });
            }
        });
    };
    var canSubmit = !isSubmitting &&
        ((dirtyFields.currentPassword && dirtyFields.newPassword && dirtyFields.confirmNewPassword) || // if all fields are dirty
            (isSubmitted &&
                (dirtyFields.currentPassword ||
                    dirtyFields.newPassword ||
                    dirtyFields.confirmNewPassword))); // if submitted and now a field is changed
    return (_jsxs("div", __assign({ className: "p-5 relative" }, { children: [isLoading && _jsx(Spinner, {}, void 0), _jsx("h5", __assign({ className: "font-bold" }, { children: _jsx(FormattedMessage, { id: "common.form.changePW.title" }, void 0) }), void 0), _jsxs("form", __assign({ onSubmit: submit(handleSubmit), noValidate: true }, { children: [_jsx(Input, __assign({}, register("currentPassword"), { isRequired: true, type: "password", autocomplete: "current-password", label: _jsx(FormattedMessage, { id: "common.form.changePW.field.currentPassword.label" }, void 0), error: (_b = errors.currentPassword) === null || _b === void 0 ? void 0 : _b.message }), void 0), _jsx(Input, __assign({}, register("newPassword"), { isRequired: true, type: "password", autocomplete: "new-password", label: _jsx(FormattedMessage, { id: "common.form.changePW.field.newPassword.label" }, void 0), error: (_c = errors.newPassword) === null || _c === void 0 ? void 0 : _c.message, description: _jsx(FormattedMessage, { id: "common.form.field.password.requirements" }, void 0) }), void 0), _jsx(Input, __assign({}, register("confirmNewPassword"), { isRequired: true, type: "password", autocomplete: "new-password", label: _jsx(FormattedMessage, { id: "common.form.changePW.field.confirmNewPassword.label" }, void 0), error: (_d = errors.confirmNewPassword) === null || _d === void 0 ? void 0 : _d.message }), void 0), _jsxs("div", __assign({ className: "flex justify-end gap-4" }, { children: [_jsx(Button, __assign({ onClick: function (e) {
                                    e.preventDefault();
                                    modal.close();
                                } }, { children: _jsx(FormattedMessage, { id: "common.button.cancel.label" }, void 0) }), void 0), _jsx(Button, __assign({ type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.button.save.label" }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default ChangePasswordForm;
