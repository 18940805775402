var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Input, TextArea } from "components/atoms";
import QuestionTypeSelect from "components/molecules/FormSelects/SingleSelect/QuestionTypeSelect";
import { QuestionType } from "generated/graphql";
var QuestionForm = function (_a) {
    var _b, _c, _d;
    var questionIndex = _a.questionIndex, handleDeleteQuestion = _a.onDeleteQuestion, handleSwapQuestion = _a.onSwapQuestion;
    var intl = useIntl();
    var base = "questions.".concat(questionIndex);
    var _e = useFormContext(), register = _e.register, getValues = _e.getValues, watch = _e.watch, setValue = _e.setValue, control = _e.control, errors = _e.formState.errors;
    var _f = useFieldArray({
        control: control,
        name: "questions.".concat(questionIndex, ".options"),
    }), fields = _f.fields, append = _f.append, remove = _f.remove, swap = _f.swap;
    var questionType = watch(base + ".questionType");
    var isRequired = watch(base + ".required");
    var questions = getValues("questions");
    var toggleRequired = function () {
        setValue(base + ".required", !getValues(base + ".required"), { shouldDirty: true });
    };
    useEffect(function () {
        if (fields.length === 0)
            append({ label: "" });
    }, [fields]);
    var handleNewOption = function () {
        append({ label: "" });
    };
    var handleDeleteOption = function (optionIndex) {
        if (fields.length > 1)
            remove(optionIndex);
    };
    var handleSwapOption = function (direction, optionIndex) {
        if (direction === "up" && optionIndex > 0) {
            swap(optionIndex, optionIndex - 1);
        }
        else if (direction === "down" && optionIndex < fields.length - 1) {
            swap(optionIndex, optionIndex + 1);
        }
    };
    var handleSetQuestionType = function (newValue) {
        if (newValue) {
            setValue(base + ".options", []);
            setValue(base + ".questionType", newValue, { shouldDirty: true, shouldValidate: true });
        }
    };
    return (_jsxs("div", __assign({ className: "pt-4 pb-8 flex flex-col items-center w-full px-14 gap-3" }, { children: [_jsx("div", __assign({ className: "w-full" }, { children: _jsx("div", __assign({ className: "flex flex-col mb-2" }, { children: _jsxs("div", __assign({ className: "text-size-body text-black font-bold flex flex-row justify-between mb-2" }, { children: [_jsx("h5", { children: intl.formatMessage({ id: "common.eventPage.editForm.questions.item.label" }) +
                                    " ".concat(questionIndex + 1) }, void 0), _jsxs("div", __assign({ className: "flex flex-row text-size-h5" }, { children: [_jsxs("div", __assign({ className: "flex flex-row mr-4" }, { children: [_jsx("div", __assign({ className: classNames("mr-2", questionIndex === 0 && "cursor-not-allowed text-black25", questions.length > 1
                                                    ? "cursor-pointer text-black90"
                                                    : "cursor-not-allowed text-black25"), onClick: function () { return handleSwapQuestion("up", questionIndex); } }, { children: _jsx("i", { className: "icon-chevron-up flex" }, void 0) }), void 0), _jsx("div", __assign({ className: classNames("mr-2", questionIndex === questions.length - 1 && "cursor-not-allowed text-black25", questions.length > 1
                                                    ? "cursor-pointer text-black90"
                                                    : "cursor-not-allowed text-black25"), onClick: function () { return handleSwapQuestion("down", questionIndex); } }, { children: _jsx("i", { className: "icon-chevron-down flex" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ onClick: handleDeleteQuestion }, { children: _jsx("i", { className: "icon-x-circle flex cursor-pointer text-black50" }, void 0) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0), _jsx("div", { className: "border-b border-black10 mb-2 w-full" }, void 0), _jsxs("div", __assign({ className: "flex flex-row w-full gap-3 mb-3" }, { children: [_jsx(Input, __assign({}, register("questions.".concat(questionIndex, ".title")), { label: intl.formatMessage({ id: "common.eventPage.editForm.questions.title.label" }), description: intl.formatMessage({
                            id: "common.eventPage.editForm.questions.title.description",
                        }), isRequired: true, error: (_d = (_c = (_b = errors.questions) === null || _b === void 0 ? void 0 : _b[questionIndex]) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.message }), void 0), _jsx("fieldset", __assign({ className: "flex items-center grow" }, { children: _jsx("div", __assign({ className: "outline-none flex w-full whitespace-nowrap" }, { children: _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: isRequired, onChange: toggleRequired }, void 0), _jsx("span", __assign({ className: "ml-1" }, { children: intl.formatMessage({ id: "common.eventPage.editForm.questions.required.label" }) }), void 0)] }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "min-w-[30%]" }, { children: _jsx(QuestionTypeSelect, { selectedValue: questionType, onSelect: handleSetQuestionType, isRequired: true }, void 0) }), void 0)] }), void 0), questionType === QuestionType.Text ? (_jsx("div", __assign({ className: "flex flex-row w-full" }, { children: _jsx(Input, __assign({}, register(base + ".wordCount"), { label: intl.formatMessage({
                        id: "common.eventPage.editForm.questions.wordCount.label",
                    }), isRequired: true }), void 0) }), void 0)) : (_jsxs("fieldset", __assign({ className: "flex flex-col w-full" }, { children: [fields.map(function (field, index) {
                        var _a, _b, _c, _d, _e;
                        return (_jsxs("div", __assign({ className: "flex flex-row w-full" }, { children: [_jsx(Input, __assign({}, register("".concat(base, ".options.").concat(index, ".label")), { label: intl.formatMessage({
                                        id: "common.eventPage.editForm.questions.options.label",
                                    }) +
                                        " ".concat(index + 1, " ") +
                                        intl.formatMessage({
                                            id: "common.eventPage.editForm.questions.options.label.extraInfo",
                                        }), isRequired: true, error: (_e = (_d = (_c = (_b = (_a = errors.questions) === null || _a === void 0 ? void 0 : _a[questionIndex]) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.label) === null || _e === void 0 ? void 0 : _e.message }), void 0), _jsxs("div", __assign({ className: "flex flex-row text-size-h5" }, { children: [_jsxs("div", __assign({ className: "flex flex-row mr-4" }, { children: [_jsx("div", __assign({ className: classNames("mr-2", index === 0 && "cursor-not-allowed text-black25", fields.length > 1
                                                        ? "cursor-pointer text-black90"
                                                        : "cursor-not-allowed text-black25"), onClick: function () { return handleSwapOption("up", index); } }, { children: _jsx("i", { className: "icon-chevron-up flex" }, void 0) }), void 0), _jsx("div", __assign({ className: classNames("mr-2", index === fields.length - 1 && "cursor-not-allowed text-black25", fields.length > 1
                                                        ? "cursor-pointer text-black90"
                                                        : "cursor-not-allowed text-black25"), onClick: function () { return handleSwapOption("down", index); } }, { children: _jsx("i", { className: "icon-chevron-down flex" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ onClick: function () { return handleDeleteOption(index); } }, { children: _jsx("i", { className: "icon-x-circle flex cursor-pointer text-black50" }, void 0) }), void 0)] }), void 0)] }), field.id));
                    }), _jsxs("div", __assign({ className: "w-full mb-2 flex flex-row text-primary text-size-small font-bold items-center cursor-pointer", onClick: handleNewOption }, { children: [_jsx("i", { className: "icon-plus-circle flex" }, void 0), _jsx("span", { children: intl.formatMessage({ id: "common.eventPage.editForm.questions.options.add.label" }) }, void 0)] }), void 0)] }), void 0)), _jsx("div", __assign({ className: "w-full" }, { children: _jsx(TextArea, __assign({}, register(base + ".label"), { rows: 3, label: intl.formatMessage({
                        id: "common.eventPage.editForm.questions.supplementInfo.label",
                    }), description: intl.formatMessage({
                        id: "common.eventPage.editForm.questions.supplementInfo.description",
                    }) }), void 0) }), void 0)] }), void 0));
};
export default QuestionForm;
