var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Button } from "components/atoms";
import { useAlert } from "contexts/AlertContext";
import { Evaluation, SegmentQuestionType, useUpdateEntorSegementAnswersMutation, } from "generated/graphql";
import EvaluationSidebar from "./EvaluationSidebar";
import Questions from "./Questions";
var questionSchema = yup.object({
    id: yup.number().required(),
    label: yup.string().required(),
    subLabel: yup.string().nullable(),
    segmentQuestionType: yup.mixed().oneOf(Object.values(SegmentQuestionType)).required(),
    value: yup.lazy(function (val) {
        return Array.isArray(val)
            ? yup.array().of(yup
                .object({
                companyName: yup.string().trim(),
            })
                .required())
            : typeof val === "string"
                ? yup.mixed().oneOf(Object.values(Evaluation)).defined().required()
                : typeof val === "boolean"
                    ? yup.boolean()
                    : yup.number();
    }),
});
var evaluationPageSchema = yup
    .object({
    segments: yup.array().of(yup.object({
        id: yup.number().required(),
        title: yup.string().required(),
        questions: yup.array().of(questionSchema),
    })),
})
    .required();
var EvaluationPage = function (_a) {
    var canEdit = _a.canEdit, segments = _a.segments, evaluation = _a.evaluation, segmentAnswers = _a.segmentAnswers, entorId = _a.entorId;
    var alert = useAlert();
    var intl = useIntl();
    var _b = __read(useState(0), 2), selectedSegmentIdx = _b[0], setSelectedSegmentIdx = _b[1];
    var formMethods = useForm({
        defaultValues: {
            segments: segments.map(function (segment) { return ({
                id: segment.id,
                title: segment.title,
                questions: segment.questions.map(function (q) {
                    var _a;
                    return ({
                        id: q.id,
                        label: q.label,
                        subLabel: q.subLabel,
                        segmentQuestionType: q.segmentQuestionType,
                        value: (_a = segmentAnswers.find(function (a) { return q.id === a.question.id; })) === null || _a === void 0 ? void 0 : _a.value,
                    });
                }),
            }); }),
            evaluation: evaluation,
        },
        resolver: yupResolver(evaluationPageSchema),
    });
    var _c = __read(useUpdateEntorSegementAnswersMutation({
        onCompleted: function () {
            alert.onSuccessAlert(intl.formatMessage({
                id: "admin.entorPage.evaluationTab.segmentAnswers.udpateCompleted",
            }));
        },
    }), 1), updateEntorSegementAnswers = _c[0];
    var handleSubmit = formMethods.handleSubmit, reset = formMethods.reset, _d = formMethods.formState, isDirty = _d.isDirty, isSubmitting = _d.isSubmitting, isSubmitted = _d.isSubmitted, errors = _d.errors;
    var onSubmit = function (data) {
        var _a;
        var answers = ((_a = data.segments) === null || _a === void 0 ? void 0 : _a.flatMap(function (s) { return s.questions; }).filter(function (q) { return q && (q === null || q === void 0 ? void 0 : q.value) !== undefined; }).map(function (q) {
            return {
                questionId: q === null || q === void 0 ? void 0 : q.id,
                // Sending evaluation as `\"${q?.value}\"` to adjust to backend typing
                value: Object.values(Evaluation).includes(q === null || q === void 0 ? void 0 : q.value)
                    ? "\"".concat(q === null || q === void 0 ? void 0 : q.value, "\"")
                    : Array.isArray(q === null || q === void 0 ? void 0 : q.value)
                        ? q === null || q === void 0 ? void 0 : q.value.filter(function (v) { return v.companyName.length > 0; })
                        : q === null || q === void 0 ? void 0 : q.value,
            };
        })) || [];
        if (answers.length > 0) {
            updateEntorSegementAnswers({
                variables: {
                    entorId: entorId,
                    answers: answers,
                },
            });
        }
    };
    var canSubmit = Object.keys(errors).length === 0 && (!isSubmitting || isSubmitted);
    return (_jsx(FormProvider, __assign({}, formMethods, { children: _jsxs("form", __assign({ className: "flex flex-col h-full overflow-auto", onSubmit: handleSubmit(onSubmit), noValidate: true }, { children: [_jsxs("div", __assign({ className: "flex gap-8 bg-white p-4 overflow-y" }, { children: [_jsx(EvaluationSidebar, { segmentAnswers: segmentAnswers, segments: segments, selected: selectedSegmentIdx, onSelect: function (idx) { return setSelectedSegmentIdx(idx); }, accuracy: evaluation === null || evaluation === void 0 ? void 0 : evaluation.accuracy, excellence: (evaluation === null || evaluation === void 0 ? void 0 : evaluation.excellence) || undefined }, void 0), _jsx(Questions, { disabled: !canEdit, segmentIdx: selectedSegmentIdx, segmentCategories: segments }, void 0)] }), void 0), canEdit && (_jsxs("div", __assign({ className: "w-full bg-black10 z-10 flex items-center justify-center py-2 mt-auto gap-4" }, { children: [_jsx(Button, __assign({ type: "button", onClick: function () {
                                reset();
                            }, disabled: !isDirty }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.cancelButton.label" }, void 0) }), void 0), _jsx(Button, __assign({ type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.saveButton.label" }, void 0) }), void 0)] }), void 0))] }), void 0) }), void 0));
};
export default EvaluationPage;
