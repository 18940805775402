var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import Editor from "components/templates/Editor";
var ClientDetails = function () {
    var _a = useFormContext(), getValues = _a.getValues, setValue = _a.setValue, errors = _a.formState.errors;
    var missingBassicInfo = errors.company || errors.contactEmail;
    var handleEdit = function (values) {
        setValue("contents", values, {
            shouldDirty: true,
        });
    };
    var editorConfig = {
        header: true,
        image: true,
        embed: true,
        speaker: false,
        quote: false,
    };
    return (_jsxs("div", __assign({ className: "flex flex-col justify-center items-center" }, { children: [_jsx("div", __assign({ className: "m-5 p-5 w-editor bg-white rounded-md shadow-xl border border-black10" }, { children: _jsx(Editor, { initialValues: getValues("contents"), onEdit: handleEdit, config: editorConfig }, void 0) }), void 0), _jsx("h6", __assign({ className: classnames("animate-bounce text-error font-bold text-center transition-opacity duration-700", missingBassicInfo ? "opacity-100" : "opacity-0") }, { children: _jsx(FormattedMessage, { id: "common.form.client.tabs.details.missingBassicInfo.error" }, void 0) }), void 0)] }), void 0));
};
export default ClientDetails;
