import { getRange } from "./arrayHelpers";
var prefectureIdMap = {
    hokkaido: [1],
    tohoku: getRange(2, 7),
    kanto: getRange(8, 14),
    hokushinetsu: getRange(15, 20),
    tokai: getRange(21, 24),
    kansai: getRange(25, 30),
    chugoku: getRange(31, 35),
    shikoku: getRange(36, 39),
    kyushu: getRange(40, 47),
};
export default prefectureIdMap;
