var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { useIntl } from "react-intl";
import { DATETIME_FORMAT_YYYYMMDD, DATE_FORMAT_YYYYMMDD } from "utils/constants";
import Icon from "./Icon";
var DatePicker = React.forwardRef(function (_a, refs) {
    var label = _a.label, error = _a.error, isRequired = _a.isRequired, className = _a.className, name = _a.name, description = _a.description, _b = _a.withTime, withTime = _b === void 0 ? false : _b, rest = __rest(_a, ["label", "error", "isRequired", "className", "name", "description", "withTime"]);
    var intl = useIntl();
    var errorMsg = typeof error === "object" && (error === null || error === void 0 ? void 0 : error.locale_id)
        ? intl.formatMessage({ id: error === null || error === void 0 ? void 0 : error.locale_id }, __assign({}, error === null || error === void 0 ? void 0 : error.locale_value))
        : error;
    var value = rest.value &&
        dayjs(rest.value).format(withTime ? DATETIME_FORMAT_YYYYMMDD : DATE_FORMAT_YYYYMMDD);
    return (_jsx("div", __assign({ className: classNames(className, "w-full flex flex-col justify-center h-9") }, { children: _jsxs("div", __assign({ className: "relative group h-5" }, { children: [_jsxs("div", __assign({ className: classNames("relative flex flex-row border-b text-black90 w-full", {
                        "!border-error": error,
                    }) }, { children: [_jsx("input", __assign({}, rest, { name: name, ref: refs, id: name, value: value, 
                            /*
                              This makes peer-valid selector on the label trigger any time
                              there's a value in the input. Forcing required will not interfere
                              with our usage since input validation is done with a schema
                              (yup) and not natively on the input.
                            */
                            required: true, className: "py-1 bg-transparent h-5  w-full text-small peer outline-none focus:border-primary", type: withTime ? "datetime-local" : "date" }), void 0), _jsx(Icon, { name: "Calendar", className: classNames("flex text-size-h4 float-right", { "text-error": error }) }, void 0)] }), void 0), _jsx("label", __assign({ htmlFor: name, className: "\n              py-1\n              transform\n              transition-all\n              absolute\n              top-0\n              left-0\n              items-center\n              text-small\n              text-caption\n              peer-valid:text-caption\n              h-1/2\n              peer-valid:h-1/2\n              -translate-y-full\n              peer-valid:-translate-y-full\n              py-0\n              peer-valid:py-0\n              cursor-text\n            " }, { children: _jsx("span", __assign({ className: classNames({
                            "after:content-['*'] after:ml-1 after:text-error": isRequired,
                        }) }, { children: label }), void 0) }), void 0), ((errorMsg && typeof errorMsg === "string") || description) && (_jsx("p", __assign({ className: classNames("relative h-3 text-caption leading-caption", {
                        "text-error": errorMsg,
                    }) }, { children: errorMsg || description }), void 0))] }), void 0) }), void 0));
});
DatePicker.displayName = "DatePicker";
export default DatePicker;
