var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import { Spinner } from "components/atoms";
import Header from "components/molecules/Header";
import MultiTab from "components/molecules/MultiTab";
import NotFoundView from "components/templates/NotFoundView";
import { useAuth } from "contexts/AuthContext";
import { RoleName, useCurrentMentorQuery, useEntorPageQuery } from "generated/graphql";
import Basic from "./Basic";
import EvaluationPage from "./Evaluation";
import { Interview } from "./Interview";
import { InterviewHistory } from "./InterviewHistory";
export var ENTOR_PAGE_TABS;
(function (ENTOR_PAGE_TABS) {
    ENTOR_PAGE_TABS[ENTOR_PAGE_TABS["basic"] = 0] = "basic";
    ENTOR_PAGE_TABS[ENTOR_PAGE_TABS["interview"] = 1] = "interview";
    ENTOR_PAGE_TABS[ENTOR_PAGE_TABS["evaluation"] = 2] = "evaluation";
    ENTOR_PAGE_TABS[ENTOR_PAGE_TABS["interviewHistory"] = 3] = "interviewHistory";
})(ENTOR_PAGE_TABS || (ENTOR_PAGE_TABS = {}));
var tabs = [
    {
        label: _jsx(FormattedMessage, { id: "admin.entorPage.tab.basic.label" }, void 0),
        index: ENTOR_PAGE_TABS.basic,
    },
    {
        label: _jsx(FormattedMessage, { id: "admin.entorPage.tab.interview.label" }, void 0),
        index: ENTOR_PAGE_TABS.interview,
    },
    {
        label: _jsx(FormattedMessage, { id: "admin.entorPage.tab.evaluation.label" }, void 0),
        index: ENTOR_PAGE_TABS.evaluation,
    },
    {
        label: _jsx(FormattedMessage, { id: "admin.entorPage.tab.interviewHistory.label" }, void 0),
        index: ENTOR_PAGE_TABS.interviewHistory,
    },
];
var EntorView = function () {
    var _a, _b, _c;
    var id = useParams().id;
    var roleSet = useAuth().roleSet;
    var _d = useEntorPageQuery({
        variables: {
            id: Number(id),
        },
    }), data = _d.data, loading = _d.loading, error = _d.error;
    var notCoreOrMentor = roleSet.has(RoleName.Admin) ||
        roleSet.has(RoleName.HeadQuarter) ||
        roleSet.has(RoleName.BranchManager);
    var dataMentor = useCurrentMentorQuery({
        skip: notCoreOrMentor,
    }).data;
    var _e = __read(useSearchParams(), 2), searchParams = _e[0], setSearchParams = _e[1];
    var _f = __read(useState(ENTOR_PAGE_TABS.basic), 2), selectedTab = _f[0], setSelectedTab = _f[1];
    useEffect(function () {
        var tab = String(searchParams.get("tab"));
        setSelectedTab(ENTOR_PAGE_TABS[tab] || ENTOR_PAGE_TABS.basic);
    }, []);
    // core mentors and mentors can only edit entors assigned to them
    var canEdit = !!(notCoreOrMentor ||
        ((dataMentor === null || dataMentor === void 0 ? void 0 : dataMentor.currentMentor) && ((_a = data === null || data === void 0 ? void 0 : data.entor.assignedUser) === null || _a === void 0 ? void 0 : _a.id) === dataMentor.currentMentor.userId));
    if (loading) {
        return _jsx(Spinner, {}, void 0);
    }
    if (error) {
        return _jsx(NotFoundView, {}, void 0);
    }
    return (_jsxs("div", __assign({ className: "pt-4 pl-3 w-full h-full flex flex-col" }, { children: [_jsx(Header, { title: "".concat(data === null || data === void 0 ? void 0 : data.entor.user.lastName, " ").concat(data === null || data === void 0 ? void 0 : data.entor.user.firstName) }, void 0), _jsxs("div", __assign({ className: "flex flex-col h-full bg-white overflow-auto" }, { children: [_jsx(MultiTab, { tabs: tabs, selectedTabIndex: selectedTab, onTabClick: function (index) {
                            setSelectedTab(index);
                            setSearchParams({
                                tab: ENTOR_PAGE_TABS[index],
                            });
                        }, isSticky: true, topZIndex: true }, void 0), selectedTab === ENTOR_PAGE_TABS.basic && _jsx(Basic, { basic: data === null || data === void 0 ? void 0 : data.entor }, void 0), selectedTab === ENTOR_PAGE_TABS.interview && (_jsx(Interview, { canEdit: canEdit, entorId: Number(id), interviewAnswers: data === null || data === void 0 ? void 0 : data.entor.interviewAnswers, companyRecommendation: data === null || data === void 0 ? void 0 : data.entor.companyRecommendation, interviewCategories: (data === null || data === void 0 ? void 0 : data.interviewCategories) || [] }, void 0)), selectedTab === ENTOR_PAGE_TABS.evaluation && (_jsx(EvaluationPage, { canEdit: canEdit, entorId: Number(id), segments: (data === null || data === void 0 ? void 0 : data.segments) || [], segmentAnswers: (data === null || data === void 0 ? void 0 : data.entor.segment) || [], evaluation: (_c = (_b = data === null || data === void 0 ? void 0 : data.entor) === null || _b === void 0 ? void 0 : _b.evaluation) !== null && _c !== void 0 ? _c : undefined }, void 0)), selectedTab === ENTOR_PAGE_TABS.interviewHistory && (_jsx(InterviewHistory, { interviewRecords: data === null || data === void 0 ? void 0 : data.entor.interviewHistories }, void 0))] }), void 0)] }), void 0));
};
export default EntorView;
