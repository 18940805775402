var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApplicantStatusSelect from "components/molecules/FormSelects/SingleSelect/ApplicantStatusSelect";
import GenderSelect from "components/molecules/FormSelects/SingleSelect/GenderSelect";
import FilterHeader from "components/templates/FilterHeader";
import { useEventEntorsQuery, useFeatureFlagsQuery, } from "generated/graphql";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
import InfoHeader, { InfoHeaderType } from "../InfoHeader";
import DatesTable from "./DatesTable";
var getParamFilters = function (searchParams) { return ({
    name: searchParams.get("name") || undefined,
    phoneNumber: searchParams.get("phoneNumber") || undefined,
    schoolName: searchParams.get("schoolName") || undefined,
    email: searchParams.get("email") || undefined,
    gender: searchParams.get("gender") || undefined,
    status: searchParams.get("status") || undefined,
}); };
var getQueryFilters = function (filters) { return ({
    name: filters.name,
    phoneNumber: filters.phoneNumber,
    schoolName: filters.schoolName,
    email: filters.email,
    gender: filters.gender,
    applicantStatuses: filters.status,
}); };
var inputs = [
    {
        name: "name",
        label: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.tableHeader.filter.name.label" }, void 0),
    },
    {
        name: "phoneNumber",
        label: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.tableHeader.filter.phoneNumber.label" }, void 0),
    },
    {
        name: "schoolName",
        label: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.tableHeader.filter.schoolName.label" }, void 0),
    },
    {
        name: "email",
        label: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.tableHeader.filter.email.label" }, void 0),
    },
    { name: "gender", Component: GenderSelect, singleSelect: true },
    { name: "status", Component: ApplicantStatusSelect, singleSelect: true },
];
var EventDateView = function () {
    var _a, _b;
    var id = useParams().id;
    var navigate = useNavigate();
    var eventId = Number(id);
    if (!eventId) {
        navigate("/404");
    }
    var _c = useEventEntorsQuery({
        variables: {
            id: eventId,
            first: 30,
        },
    }), data = _c.data, loading = _c.loading, refetch = _c.refetch;
    var eventTitle = data ? data.eventById.title : "";
    var participantCount = data ? (_a = data.eventById.scheduledEvent) === null || _a === void 0 ? void 0 : _a.entors.totalCount : 0;
    var dates = (_b = data === null || data === void 0 ? void 0 : data.eventById.scheduledEvent) === null || _b === void 0 ? void 0 : _b.dates;
    var branches = data === null || data === void 0 ? void 0 : data.eventById.branches;
    var _d = __read(useSearchParams(), 2), searchParams = _d[0], setSearchParams = _d[1];
    // Filters are not used in dates
    var initialFilters = useMemo(function () { return getParamFilters(searchParams); }, []);
    var handleFilter = function (filters) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSearchParams(filters);
                    return [4 /*yield*/, refetch(__assign({ id: eventId, first: 30 }, getQueryFilters(filters)))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: "pt-4 pl-3 w-full h-full flex flex-col" }, { children: [_jsx(FilterHeader, { title: _jsx(FormattedMessage, { id: "admin.events.filter.header.label" }, void 0), initialFilters: initialFilters, onFilter: handleFilter, inputs: inputs, disabled: true }, void 0), data && (_jsx(InfoHeader, { type: InfoHeaderType.ScheduledEventDates, id: id, branches: branches || [], title: eventTitle, totalParticipants: participantCount !== null && participantCount !== void 0 ? participantCount : 0, eventHash: data.eventById.hash }, void 0)), _jsx(DatesTable, { dates: dates || [], loading: loading }, void 0)] }), void 0));
};
var CheckForFlags = function () {
    var flags = useFeatureFlagsQuery().data;
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: flags === null || flags === void 0 ? void 0 : flags.baklavaFeatureFlags.baklavaEventManagement }, { children: _jsx(EventDateView, {}, void 0) }), void 0));
};
export default CheckForFlags;
