var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import RadioSelect from "components/molecules/RadioSelect";
import { EventHostType } from "generated/graphql";
var OPTIONS = [
    {
        value: EventHostType.Branch,
        label: _jsx(FormattedMessage, { id: "common.eventHostType.branch" }, void 0),
        id: EventHostType.Branch,
    },
    {
        value: EventHostType.Client,
        label: _jsx(FormattedMessage, { id: "common.eventHostType.client" }, void 0),
        id: EventHostType.Client,
    },
    {
        value: EventHostType.HeadQuarter,
        label: _jsx(FormattedMessage, { id: "common.eventHostType.headQuarter" }, void 0),
        id: EventHostType.HeadQuarter,
    },
];
var EventHostTypeSelect = function (_a) {
    var onSelect = _a.onSelect, selectedValue = _a.selectedValue, rest = __rest(_a, ["onSelect", "selectedValue"]);
    return (_jsx(RadioSelect, __assign({}, rest, { label: _jsx(FormattedMessage, { id: "common.form.field.radioSelect.eventType.label" }, void 0), onSelect: onSelect, selectedValue: selectedValue, options: OPTIONS }), void 0));
};
export default EventHostTypeSelect;
