var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var getRange = function (start, end) {
    return Array(end - start + 1)
        .fill(0)
        .map(function (_, index) { return start + index; });
};
export var arrayAreEqual = function (array1, array2) {
    if (array1 && array2) {
        return array1.length === array2.length && array1.every(function (val, i) { return val === array2[i]; });
    }
    return array1 === array2;
};
export var getListOfYears = function (lengthOf) {
    if (lengthOf === void 0) { lengthOf = 5; }
    var currentMonth = new Date().getMonth();
    var currentYear = new Date().getFullYear() + (currentMonth >= 4 ? 1 : 0);
    // Arbitrarily chose 6 years ahead of current year
    return __spreadArray([], __read(Array(lengthOf > 0 && length < 10 ? lengthOf : 6)
        .fill(0)
        .map(function (_, index) {
        var year = currentYear + index;
        return year;
    })), false);
};
