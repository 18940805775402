var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import Sidebar from "components/templates/Sidebar";
import { routeRoles } from "_AdminApp/routes";
var PrivateLayout = function (_a) {
    var roleSet = _a.roleSet;
    var intl = useIntl();
    var links = [
        // events
        {
            text: intl.formatMessage({
                id: "common.privateLayout.sidebar.events.navLink",
            }),
            to: "/events",
            iconName: "News",
        },
        {
            text: intl.formatMessage({
                id: "common.privateLayout.sidebar.createEvent.navLink",
            }),
            to: "/events/new",
            permittedRoles: routeRoles.newEvent,
        },
        // entors
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.menteeManagement.navLink",
            }),
            to: "/entors",
            iconName: "Group",
        },
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.interviews.navLink",
            }),
            to: "/entors/questions",
            permittedRoles: routeRoles.entorQuestions,
        },
        // users
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.userManagement.navLink",
            }),
            to: "users",
            iconName: "Magnet",
        },
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.userInvite.navLink",
            }),
            to: "/users/new",
            permittedRoles: routeRoles.newUser,
        },
        // companies
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.clientManagement.navLink",
            }),
            to: "/companies",
            iconName: "Building",
            permittedRoles: routeRoles.branchManagement,
        },
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.createClient.navLink",
            }),
            to: "/companies/new",
            permittedRoles: routeRoles.branchManagement,
        },
        // branches
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.branchManagement.navLink",
            }),
            to: "branches",
            iconName: "Sitemap",
            permittedRoles: routeRoles.clientManagement,
        },
        {
            text: intl.formatMessage({
                id: "admin.privateLayout.sidebar.newBranch.navLink",
            }),
            to: "branches/new",
            permittedRoles: routeRoles.clientManagement,
        },
    ];
    return (_jsxs("div", __assign({ className: "flex h-screen w-screen bg-background-grey" }, { children: [_jsx(Sidebar, { links: links, roleSet: roleSet }, void 0), _jsx("div", __assign({ className: "flex-1 flex w-content" }, { children: _jsx(Outlet, {}, void 0) }), void 0)] }), void 0));
};
export default PrivateLayout;
