var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "components/atoms";
var ScheduledEventEntorInfo = function (props) {
    var location = useLocation();
    var navigateTo = useNavigate();
    return (_jsxs("div", __assign({ className: "flex align-center text-center gap-4 text-black50 py-2" }, { children: [_jsx("span", __assign({ className: "inline-flex items-center font-bold truncate" }, { children: props.title }), void 0), _jsx("div", __assign({ className: "border-l border-black10 pl-3" }, { children: props.branches.map(function (branch, index) {
                    return (_jsx("span", __assign({ className: "inline-flex items-center h-full" }, { children: "".concat(branch.name) + (index === props.branches.length - 1 ? "" : "、") }), branch.name));
                }) }), void 0), _jsxs("div", __assign({ className: "flex gap-1" }, { children: [_jsx(Button, __assign({ onClick: function () {
                            navigateTo("/events/".concat(props.id, "/entors"));
                        }, disabled: location.pathname === "/events/".concat(props.id, "/entors"), fullheight: true, inverted: true }, { children: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.infoHeader.allParticipants.label" }, void 0) }), void 0), _jsx(Button, __assign({ onClick: function () {
                            navigateTo("/events/".concat(props.id, "/dates"));
                        }, disabled: location.pathname === "/events/".concat(props.id, "/dates"), fullheight: true, inverted: true }, { children: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.infoHeader.dateDetails.label" }, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default ScheduledEventEntorInfo;
