var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Label, Modal, Spinner } from "components/atoms";
import MentorRoleSelect from "components/molecules/FormSelects/SingleSelect/MentorRoleSelect";
import { useAlert } from "contexts/AlertContext";
import { useModal } from "contexts/ModalContext";
import { useUpdateMentorRoleMutation } from "generated/graphql";
var EditMentorRoleModal = function (_a) {
    var user = _a.user;
    var _b = __read(useState(user.roles[0]), 2), selectedRole = _b[0], setSelectedRole = _b[1];
    var alert = useAlert();
    var modal = useModal();
    var intl = useIntl();
    var _c = __read(useUpdateMentorRoleMutation(), 2), updateMentorRoleMutation = _c[0], _d = _c[1], client = _d.client, loading = _d.loading;
    var handleUpdateRole = function () {
        updateMentorRoleMutation({
            variables: {
                // TODO: Fix this type on the BE
                role: { roleName: selectedRole },
                userId: user === null || user === void 0 ? void 0 : user.mentor.id,
            },
        }).then(function (_a) {
            var data = _a.data;
            if (data === null || data === void 0 ? void 0 : data.updateMentorRole) {
                alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
                client.cache.modify({
                    id: client.cache.identify(__assign({}, user)),
                    fields: {
                        roles: function () { return [selectedRole]; },
                    },
                });
                modal.close();
            }
        });
    };
    var canSubmit = !loading && selectedRole && selectedRole !== user.roles[0];
    return (_jsx(Modal, __assign({ size: "sm" }, { children: _jsxs("div", __assign({ className: "relative" }, { children: [loading && _jsx(Spinner, {}, void 0), _jsxs("div", __assign({ className: classNames("my-4 mx-6", {
                        "opacity-25": loading,
                    }) }, { children: [_jsxs("div", __assign({ className: "text-size-small" }, { children: [_jsx("h6", __assign({ className: "font-bold mb-1" }, { children: _jsx(FormattedMessage, { id: "admin.usersPage.modal.editMentorRole.title" }, void 0) }), void 0), _jsxs("div", __assign({ className: "py-3 " }, { children: [_jsxs("div", __assign({ className: "my-3 flex flex-row items-center justify-between" }, { children: [_jsx(Label, { children: _jsx(FormattedMessage, { id: "admin.usersPage.modal.editMentorRole.mentorName.label" }, void 0) }, void 0), _jsx("div", __assign({ className: "font-bold" }, { children: "".concat(user.lastName, " ").concat(user.firstName) }), void 0)] }), void 0), _jsx("div", { className: "my-3 w-full h-px bg-black10" }, void 0), _jsxs("div", __assign({ className: "my-3 flex flex-row items-center justify-between" }, { children: [_jsx(Label, { children: _jsx(FormattedMessage, { id: "admin.usersPage.modal.editMentorRole.role.label" }, void 0) }, void 0), _jsx("div", __assign({ className: "w-3/5" }, { children: _jsx(MentorRoleSelect, { selectedValue: selectedRole, onSelect: function (role) { return setSelectedRole(role); } }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "my-3 flex flex-row justify-end gap-3" }, { children: [_jsx(Button, __assign({ inverted: true, onClick: modal.close }, { children: _jsx(FormattedMessage, { id: "common.button.cancel.label" }, void 0) }), void 0), _jsx(Button, __assign({ disabled: !canSubmit, onClick: handleUpdateRole }, { children: _jsx(FormattedMessage, { id: "admin.usersPage.modal.editMentorRole.submitBtn.label" }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default EditMentorRoleModal;
