var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Spinner = function (props) {
    var _a = props.color, color = _a === void 0 ? "bg-primary" : _a;
    return (_jsx("div", __assign({ className: "absolute z-50 inset-0 flex justify-center content-center bg-gray-400/10" }, { children: _jsxs("div", __assign({ className: "flex space-x-2 p-5 rounded-full justify-center items-center" }, { children: [_jsx("div", { className: "".concat(color, " p-2 w-3 h-3 rounded-full animate-bounce loader-circle-1") }, void 0), _jsx("div", { className: "".concat(color, " p-2 w-3 h-3 rounded-full animate-bounce loader-circle-2") }, void 0), _jsx("div", { className: "".concat(color, " p-2 w-3 h-3 rounded-full animate-bounce loader-circle-3") }, void 0)] }), void 0) }), void 0));
};
export var MiniSpinner = function (props) {
    var _a = props.color, color = _a === void 0 ? "bg-gray-400" : _a;
    return (_jsx("div", __assign({ className: "absolute z-50 inset-0 flex justify-center content-center" }, { children: _jsxs("div", __assign({ className: "flex space-x-2 p-2 rounded-full justify-center items-center" }, { children: [_jsx("div", { className: "".concat(color, " p-1 w-1 h-1 rounded-full animate-bounce loader-circle-1") }, void 0), _jsx("div", { className: "".concat(color, " p-1 w-1 h-1 rounded-full animate-bounce loader-circle-2") }, void 0), _jsx("div", { className: "".concat(color, " p-1 w-1 h-1 rounded-full animate-bounce loader-circle-3") }, void 0)] }), void 0) }), void 0));
};
export default Spinner;
