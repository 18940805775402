var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
var HeroView = function (_a) {
    var bgImg = _a.bgImg, logoImg = _a.logoImg, children = _a.children, termsLink = _a.termsLink, privacyLink = _a.privacyLink;
    return (_jsxs("div", __assign({ className: "flex h-full" }, { children: [_jsx("div", __assign({ className: "h-full flex-1" }, { children: _jsx("img", { className: "block h-full object-cover w-full", src: bgImg, alt: "bgImg" }, void 0) }), void 0), _jsxs("div", __assign({ className: "flex-shrink-0 flex flex-col justify-between pt-9 pb-6 px-14 min-w-login overflow-y-auto" }, { children: [_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx("img", { src: logoImg, alt: "logoImg" }, void 0) }), void 0), _jsx("div", { children: children }, void 0), _jsxs("div", __assign({ className: "text-center text-black50 text-caption leading-caption" }, { children: [_jsxs("div", __assign({ className: "mb-2 flex justify-center font-bold" }, { children: [termsLink && (_jsx("div", __assign({ className: "mr-4 flex items-center no-underline hover:underline text-black50" }, { children: termsLink }), void 0)), privacyLink && (_jsx("div", __assign({ className: "flex items-center no-underline hover:underline text-black55" }, { children: privacyLink }), void 0))] }), void 0), _jsx(FormattedMessage, { id: "common.copyright.text" }, void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default HeroView;
