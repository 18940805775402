var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import MultiTab from "components/molecules/MultiTab";
import PublishBar from "components/molecules/PublishBar";
import { useAuth } from "contexts/AuthContext";
import { EmailStatus, EmailTiming, EventHostType, EventStatus, EventType, RoleName, } from "generated/graphql";
import { DEFAULT_EMAILS, DEFAULT_ENTRY_EVENT_EMAILS, EVENT_HOST_TYPES } from "utils/constants";
import { schemaToMutation } from "utils/helpers/eventSchemaMapper";
import BasicEventInfo from "./BasicEventInfo";
import Content from "./EventContent";
import PostEventInformation from "./PostEventInformation";
import PreEventInformation from "./PreEventInformation";
import eventFormSchema, { CheckState, noClientEventSchema, onlyBranchesEventSchema, } from "./schema";
var PUBLISH_STATUS_OPTIONS = [
    {
        value: EventStatus.Draft,
        label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.publishBar.option.draft.label" }, void 0),
    },
    {
        value: EventStatus.Active,
        label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.publishBar.option.public.label" }, void 0),
    },
    {
        value: EventStatus.NoIndex,
        label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.publishBar.option.restricted.label" }, void 0),
    },
];
export var EVENT_EDIT_TABS;
(function (EVENT_EDIT_TABS) {
    EVENT_EDIT_TABS[EVENT_EDIT_TABS["basic"] = 0] = "basic";
    EVENT_EDIT_TABS[EVENT_EDIT_TABS["content"] = 1] = "content";
    EVENT_EDIT_TABS[EVENT_EDIT_TABS["questions"] = 2] = "questions";
    EVENT_EDIT_TABS[EVENT_EDIT_TABS["postApp"] = 3] = "postApp";
})(EVENT_EDIT_TABS || (EVENT_EDIT_TABS = {}));
export var getDefaultEmails = function (eventType) {
    return eventType === EventType.Entry
        ? [
            {
                timing_typed: EmailTiming.PostApplication,
                body: DEFAULT_ENTRY_EVENT_EMAILS[EmailTiming.PostApplication],
                defaultStatus_typed: EmailStatus.Pending,
                checkState: CheckState.Default,
            },
            {
                timing_typed: EmailTiming.PostApplicationCancel,
                body: DEFAULT_ENTRY_EVENT_EMAILS[EmailTiming.PostApplicationCancel],
                defaultStatus_typed: EmailStatus.Pending,
                checkState: CheckState.Default,
            },
        ]
        : Object.values(EmailTiming).map(function (timing) { return ({
            timing_typed: timing,
            body: DEFAULT_EMAILS[timing],
            defaultStatus_typed: EmailStatus.Pending,
            checkState: CheckState.Default,
        }); });
};
var EditEventForm = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, eventHash = _a.eventHash, submittable = _a.submittable, isNewEvent = _a.isNewEvent;
    var _b = __read(useSearchParams(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var _c = __read(useState(EVENT_EDIT_TABS.basic), 2), selectedTab = _c[0], setSelectedTab = _c[1];
    var copyEventId = searchParams.get("copyEventId");
    var roleSet = useAuth().roleSet;
    var schema = roleSet.has(RoleName.CoreMentor) || roleSet.has(RoleName.BranchManager)
        ? noClientEventSchema
        : roleSet.has(RoleName.HeadQuarter)
            ? onlyBranchesEventSchema
            : eventFormSchema;
    var methods = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    useEffect(function () {
        var tab = String(searchParams.get("tab"));
        setSelectedTab(EVENT_EDIT_TABS[tab] || EVENT_EDIT_TABS.basic);
    }, []);
    useEffect(function () {
        if (initialValues) {
            methods.reset(initialValues);
            methods.trigger(); // validate the new values
        }
        else {
            methods.reset(eventFormSchema.cast({
                eventType: EventType.Entry,
                status: EventStatus.Draft,
                eventHostType_typed: EventHostType.Branch,
                categoryId: EVENT_HOST_TYPES.branch,
                entryEvent: { limitNum: undefined },
                emails: getDefaultEmails(EventType.Entry),
            }, { stripUnknown: true }));
            setSelectedTab(EVENT_EDIT_TABS.basic);
        }
    }, [initialValues]);
    var previewLink = eventHash ? "".concat(process.env.ENTOR_URL, "/events/").concat(eventHash) : undefined;
    var tabs = [
        {
            label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.steps.eventInformation.label" }, void 0),
            index: EVENT_EDIT_TABS.basic,
        },
        {
            label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.steps.eventArticle.label" }, void 0),
            index: EVENT_EDIT_TABS.content,
        },
        {
            label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.steps.additionalEventInfo.label" }, void 0),
            index: EVENT_EDIT_TABS.questions,
        },
        {
            label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.steps.afterApplicationInfo.label" }, void 0),
            index: EVENT_EDIT_TABS.postApp,
        },
    ];
    var canSubmit = submittable &&
        (!isNewEvent
            ? ((!methods.formState.isSubmitting && methods.formState.isDirty) ||
                (methods.formState.isSubmitted && methods.formState.isDirty)) &&
                methods.formState.isValid
            : methods.formState.isValid);
    var handleSubmit = methods.handleSubmit(function (data) {
        var mutationInput = schemaToMutation(data);
        if (mutationInput) {
            onSubmit(mutationInput, methods.getValues("eventType"));
        }
    });
    return (_jsx(FormProvider, __assign({}, methods, { children: _jsxs("form", __assign({ onSubmit: handleSubmit, className: "flex flex-col h-full bg-white overflow-auto", noValidate: true }, { children: [_jsxs("div", __assign({ className: "flex flex-col h-full overflow-hidden" }, { children: [_jsx(MultiTab, { tabs: tabs, selectedTabIndex: selectedTab, onTabClick: function (index) {
                                setSelectedTab(index);
                                if (copyEventId) {
                                    setSearchParams({
                                        tab: EVENT_EDIT_TABS[index],
                                        copyEventId: copyEventId,
                                    });
                                }
                                else {
                                    setSearchParams({
                                        tab: EVENT_EDIT_TABS[index],
                                    });
                                }
                            }, isSticky: true, topZIndex: true, overflowX: true }, void 0), _jsxs("div", __assign({ className: "overflow-auto" }, { children: [selectedTab === EVENT_EDIT_TABS.basic && (_jsx(BasicEventInfo, { previewLink: previewLink, isNewEvent: isNewEvent }, void 0)), selectedTab === EVENT_EDIT_TABS.content && _jsx(Content, {}, void 0), selectedTab === EVENT_EDIT_TABS.questions && _jsx(PreEventInformation, {}, void 0), selectedTab === EVENT_EDIT_TABS.postApp && _jsx(PostEventInformation, {}, void 0)] }), void 0)] }), void 0), _jsx(PublishBar, { publishStatuses: PUBLISH_STATUS_OPTIONS, disabled: !canSubmit }, void 0)] }), void 0) }), void 0));
};
export default EditEventForm;
