var _a, _b, _c;
import { Degree, RoleName, UserGender } from "generated/graphql";
var roleLabel = (_a = {},
    _a[RoleName.Admin] = "common.role.admin",
    _a[RoleName.BranchManager] = "common.role.branch_manager",
    _a[RoleName.Client] = "common.role.client",
    _a[RoleName.CoreMentor] = "common.role.core_mentor",
    _a[RoleName.Entor] = "common.role.entor",
    _a[RoleName.HeadQuarter] = "common.role.head_quarter",
    _a[RoleName.Mentor] = "common.role.mentor",
    _a);
var userGenderLabel = (_b = {},
    _b[UserGender.Male] = "common.userGender.male",
    _b[UserGender.Female] = "common.userGender.female",
    _b[UserGender.Other] = "common.userGender.other",
    _b[UserGender.Unspecified] = "common.userGender.unspecified",
    _b);
var degreeLabel = (_c = {},
    _c[Degree.Doctor] = "common.degree.doctor",
    _c[Degree.Master] = "common.degree.master",
    _c[Degree.Bachelor] = "common.degree.bachelor",
    _c[Degree.JuniorCollege] = "common.degree.juniorCollege",
    _c[Degree.SpecialistSchool] = "common.degree.specialistSchool",
    _c[Degree.TechCollege] = "common.degree.techCollege",
    _c[Degree.Overseas] = "common.degree.overseas",
    _c);
export { roleLabel, userGenderLabel, degreeLabel };
