var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from "react-hook-form";
import { Icon } from "components/atoms";
import { EvaluationSelect, EvaluationSelectDynamic, } from "components/molecules/FormSelects/SingleSelect/EvaluationSelect";
import { SegmentQuestionType } from "generated/graphql";
import MultiEntry from "./MultiEntry";
var Questions = function (_a) {
    var _b;
    var segmentCategories = _a.segmentCategories, segmentIdx = _a.segmentIdx, disabled = _a.disabled;
    var _c = useFormContext(), setValue = _c.setValue, getValues = _c.getValues, watch = _c.watch, errors = _c.formState.errors;
    var questions = ((_b = segmentCategories.find(function (_, idx) { return idx === segmentIdx; })) === null || _b === void 0 ? void 0 : _b.questions) || [];
    var questionsArrayStr = "segments.".concat(segmentIdx, ".questions");
    return (_jsx("div", __assign({ className: "w-full" }, { children: questions.map(function (question, idx) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "w-full flex flex-row justify-between" }, { children: [_jsxs("div", __assign({ className: "w-4/5" }, { children: [_jsx("div", __assign({ className: "text-black90 text-size-small mb-1" }, { children: question.label }), void 0), question.subLabel && (_jsx("div", __assign({ className: "text-black50 text-size-caption mb-1" }, { children: question.subLabel }), void 0))] }), void 0), _jsx("div", __assign({ className: "w-1/5" }, { children: question.segmentQuestionType === SegmentQuestionType.Evaluation ? (_jsx(EvaluationSelect, { isDisabled: disabled, isClearable: true, onSelect: function (selected) {
                                        setValue("".concat(questionsArrayStr, ".").concat(idx, ".value"), selected);
                                    }, error: (_e = (_d = (_c = (_b = (_a = errors === null || errors === void 0 ? void 0 : errors.segments) === null || _a === void 0 ? void 0 : _a[segmentIdx]) === null || _b === void 0 ? void 0 : _b.questions) === null || _c === void 0 ? void 0 : _c[idx]) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.message, selectedValue: watch("".concat(questionsArrayStr, ".").concat(idx, ".value")) }, void 0)) : question.segmentQuestionType === SegmentQuestionType.Checkbox ? (_jsx(Checkbox, { disabled: disabled, onCheck: function () {
                                        setValue("".concat(questionsArrayStr, ".").concat(idx, ".value"), !getValues("".concat(questionsArrayStr, ".").concat(idx, ".value")));
                                    }, checked: watch("".concat(questionsArrayStr, ".").concat(idx, ".value")) }, void 0)) : question.segmentQuestionType === SegmentQuestionType.Select ? (_jsx(EvaluationSelectDynamic, { isDisabled: disabled, isClearable: true, onSelect: function (selected) {
                                        setValue("".concat(questionsArrayStr, ".").concat(idx, ".value"), selected);
                                    }, error: (_k = (_j = (_h = (_g = (_f = errors === null || errors === void 0 ? void 0 : errors.segments) === null || _f === void 0 ? void 0 : _f[segmentIdx]) === null || _g === void 0 ? void 0 : _g.questions) === null || _h === void 0 ? void 0 : _h[idx]) === null || _j === void 0 ? void 0 : _j.value) === null || _k === void 0 ? void 0 : _k.message, optionVals: ((_l = question.acceptedAnswers) === null || _l === void 0 ? void 0 : _l.values) || [], selectedValue: watch("".concat(questionsArrayStr, ".").concat(idx, ".value")) }, void 0)) : null }), void 0)] }), void 0), question.segmentQuestionType === SegmentQuestionType.MultiEntry && (_jsx(MultiEntry, { disabled: disabled, segmentIdx: segmentIdx, questionIdx: idx, arrayName: "".concat(questionsArrayStr, ".").concat(idx, ".value") }, void 0)), _jsx("div", { className: "mt-2 mb-4 w-full border-b border-black10" }, void 0)] }, question.name + String(idx)));
        }) }), void 0));
};
export default Questions;
var Checkbox = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, checked = _a.checked, onCheck = _a.onCheck;
    return (_jsx(Icon, { onClick: onCheck, className: disabled
            ? "text-black25 hover:cursor-not-allowed text-size-h5"
            : "text-primary hover:cursor-pointer text-size-h5", name: checked ? "CheckboxChecked" : "Checkbox" }, void 0));
};
