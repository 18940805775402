var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Input, TextArea } from "components/atoms";
import MailFormBlock from "./MailFormBlock";
var PostEventInformation = function () {
    var _a = useFormContext(), register = _a.register, control = _a.control;
    var fields = useFieldArray({
        control: control,
        name: "emails",
    }).fields;
    return (_jsxs("div", __assign({ className: "flex flex-col gap-6" }, { children: [_jsxs("fieldset", __assign({ className: "px-6 py-4" }, { children: [_jsx(Input, __assign({}, register("postApplicationLink.url"), { label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.url.label" }, void 0), description: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.url.description" }, void 0) }), void 0), _jsx(TextArea, __assign({}, register("postApplicationLink.description"), { rows: 3, label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.description.label" }, void 0), description: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.description.description" }, void 0) }), void 0)] }), void 0), _jsx("div", { className: "border-b border-black10" }, void 0), _jsxs("div", __assign({ className: "px-6 py-2" }, { children: [_jsxs("div", __assign({ className: "flex flex-row mb-4" }, { children: [_jsx("div", __assign({ className: "text-warning text-size-h5 mr-2" }, { children: _jsx("i", { className: "icon-info-circle flex" }, void 0) }), void 0), _jsx("span", __assign({ className: "text-black50 text-size-caption" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.reminderMail.description" }, void 0) }), void 0)] }), void 0), _jsx("h4", __assign({ className: "text-size-body text-black font-bold mb-4" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.mailSettings.label" }, void 0) }), void 0), _jsx("div", __assign({ className: "grid grid-cols-1 lg:grid-cols-2 gap-6" }, { children: fields.map(function (field, idx) { return (_jsx(MailFormBlock, { idx: idx }, field.id)); }) }), void 0)] }), void 0)] }), void 0));
};
export default PostEventInformation;
