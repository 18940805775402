var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import ImageUploader from "components/atoms/ImageUploader";
var BgUploader = function (_a) {
    var url = _a.url, onChange = _a.onChange, rest = __rest(_a, ["url", "onChange"]);
    return (_jsx(ImageUploader, __assign({ withIcon: true, initialUrl: url, onChange: onChange, label: _jsx(FormattedMessage, { id: "common.form.field.bgImgUpload.label" }, void 0), sublabel: _jsx(FormattedMessage, { id: "common.form.field.bgImgUpload.sublabel" }, void 0) }, rest), void 0));
};
export default BgUploader;
