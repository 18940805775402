var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "components/atoms";
import BaseSelect from "./BaseSelect";
var OptionPill = function (_a) {
    var option = _a.option, onRemove = _a.onRemove;
    return (_jsxs("div", __assign({ className: "flex justify-center text-caption items-center m-1 px-1 rounded-full bg-background-grey border cursor-default", onClick: function (e) {
            e.stopPropagation();
        } }, { children: [_jsx("div", __assign({ className: "text-xs font-normal leading-none w-full whitespace-nowrap flex-initial" }, { children: option.label }), void 0), _jsx("div", __assign({ className: "flex flex-auto flex-row-reverse" }, { children: _jsx(Icon, { name: "X", className: "cursor-pointer hover:text-gray-400 rounded-full ml-1", onClick: onRemove }, void 0) }), void 0)] }), option.id));
};
var SingleSelect = function (_a) {
    var selectedOptions = _a.selectedOptions, onSelect = _a.onSelect, options = _a.options, rest = __rest(_a, ["selectedOptions", "onSelect", "options"]);
    var handleSelect = function (selectedOption) {
        if (selectedOption) {
            if (selectedOptions.some(function (option) { return option.id === selectedOption.id; })) {
                onSelect(selectedOptions.filter(function (option) { return option.id !== selectedOption.id; }));
            }
            else {
                onSelect(__spreadArray(__spreadArray([], __read(selectedOptions), false), [selectedOption], false));
            }
        }
        else {
            onSelect([]);
        }
    };
    return (_jsx(BaseSelect, __assign({ selectedOptions: selectedOptions, onSelect: handleSelect, options: options === null || options === void 0 ? void 0 : options.filter(function (option) { return !selectedOptions.includes(option); }) }, rest, { children: selectedOptions.map(function (option) { return (_jsx(OptionPill, { option: option, onRemove: function () { return handleSelect(option); } }, option.id)); }) }), void 0));
};
export default SingleSelect;
