var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { Button, Spinner } from "components/atoms";
var schema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
});
var LoginForm = function (_a) {
    var onSubmit = _a.onSubmit, onForgotPw = _a.onForgotPw, isLoading = _a.isLoading;
    var location = useLocation();
    var _b = useForm({
        defaultValues: { email: "", password: "" },
        resolver: yupResolver(schema),
    }), reset = _b.reset, register = _b.register, submit = _b.handleSubmit, _c = _b.formState, errors = _c.errors, isSubmitting = _c.isSubmitting, isSubmitted = _c.isSubmitted, dirtyFields = _c.dirtyFields;
    var handleSubmit = submit(function (data) {
        onSubmit(data);
        // reset form so that it is no longer "dirty" after a failed submit
        reset(__assign({}, data), { keepValues: true });
    });
    var oidcLoginUrl = function () {
        var _a, _b;
        var from = ((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname) || "/";
        var returnTo = window.location.origin + from;
        return "".concat(process.env.BAKLAVA_URL, "/oidc/login?return_to=").concat(returnTo);
    };
    var canSubmit = !isSubmitting &&
        ((dirtyFields.email && dirtyFields.password) || // if all fields are dirty
            (isSubmitted && (dirtyFields.email || dirtyFields.password))); // if submitted and now a field is changed
    return (_jsxs("div", __assign({ className: "relative" }, { children: [isLoading && _jsx(Spinner, {}, void 0), _jsxs("div", __assign({ className: "flex items-center flex-col" }, { children: [_jsx("a", __assign({ className: "mb-3", href: oidcLoginUrl() }, { children: _jsx(Button, { children: "Login via Poblano SSO" }, void 0) }), void 0), _jsx("button", __assign({ onClick: onForgotPw, className: "text-primary no-underline text-small leading-small font-bold" }, { children: _jsx(FormattedMessage, { id: "common.form.login.forgotPassword.link" }, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default LoginForm;
