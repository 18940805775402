var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import BranchMultiSelect from "components/molecules/FormSelects/MultiSelect/BranchMultiSelect";
import ClientMultiSelect from "components/molecules/FormSelects/MultiSelect/ClientMultiSelect";
import AreaSelect from "components/molecules/FormSelects/SingleSelect/AreaSelect";
import ClientSelect from "components/molecules/FormSelects/SingleSelect/ClientSelect";
import EventTypeSelect from "components/molecules/FormSelects/SingleSelect/EventTypeSelect";
import HostTypeSelect from "components/molecules/FormSelects/SingleSelect/HostTypeSelect";
import StatusSelect from "components/molecules/FormSelects/SingleSelect/StatusSelect";
import TargetYearSelect from "components/molecules/FormSelects/SingleSelect/TargetYearSelect";
import FilterHeader from "components/templates/FilterHeader";
import NotFoundView from "components/templates/NotFoundView";
import { useAuth } from "contexts/AuthContext";
import { RoleName, useFeatureFlagsQuery, useListEventsQuery, } from "generated/graphql";
import { EventHostType } from "generated/graphql";
import prefectureIdMap from "utils/helpers/prefectureIdMap";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
import EventsTable from "./EventsTable";
var PAGE_SIZE = 30;
var getParamFilters = function (searchParams) { return ({
    title: searchParams.get("title") || undefined,
    hostIds: searchParams.getAll("hostIds").map(Number) || undefined,
    prefecture: searchParams.get("prefecture") || undefined,
    targetYear: searchParams.get("targetYear") || undefined,
    status: searchParams.get("status") || undefined,
    eventHostType: searchParams.get("eventHostType") || EventHostType.Branch,
    eventType: searchParams.get("eventType") || undefined,
}); };
var getQueryFilters = function (filters) { return ({
    title: filters.title,
    prefectureIds: prefectureIdMap[filters.prefecture || ""],
    targetYears: filters.targetYear ? [Number(filters.targetYear)] : undefined,
    status: filters.status,
    eventHostType: filters.eventHostType || EventHostType.Branch,
    eventTypes: filters.eventType ? [filters.eventType] : undefined,
    hostIds: filters.hostIds ? __spreadArray([], __read(filters.hostIds), false) : undefined,
}); };
var Events = function () {
    var _a, _b;
    var _c = __read(useSearchParams(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var initialFilters = getParamFilters(searchParams);
    var roleSet = useAuth().roleSet;
    var hostType = searchParams.get("eventHostType") || EventHostType.Branch;
    var inputs = useMemo(function () {
        var inputs = [
            { name: "title", label: _jsx(FormattedMessage, { id: "common.form.field.eventName.label" }, void 0) },
            { name: "prefecture", width: "w-1/5", Component: AreaSelect, singleSelect: true },
            {
                name: "targetYear",
                width: "w-1/5",
                Component: TargetYearSelect,
                singleSelect: true,
            },
            { name: "status", width: "w-1/5", Component: StatusSelect, singleSelect: true },
            {
                name: "eventHostType",
                width: "w-1/5",
                Component: HostTypeSelect,
                singleSelect: true,
            },
            {
                name: "eventType",
                width: "w-1/5",
                Component: EventTypeSelect,
                singleSelect: true,
            },
            hostType === EventHostType.Client
                ? { name: "hostIds", width: "w-2/5", Component: ClientSelect, singleSelect: true }
                : {
                    name: "hostIds",
                    width: "w-2/5",
                    Component: hostType === EventHostType.Branch ? BranchMultiSelect : ClientMultiSelect,
                    multiSelect: true,
                },
        ];
        // remove eventHostType select for mentors/core_mentors
        inputs =
            roleSet.has(RoleName.CoreMentor) || roleSet.has(RoleName.Mentor)
                ? inputs.filter(function (i) { return i.name !== "eventHostType"; })
                : inputs;
        // remove hostIds select for mentors
        return roleSet.has(RoleName.Mentor) ? inputs.filter(function (i) { return i.name !== "hostIds"; }) : inputs;
    }, [hostType]);
    var _d = useListEventsQuery({
        variables: {
            filter: __assign({ first: PAGE_SIZE }, getQueryFilters(initialFilters)),
        },
        notifyOnNetworkStatusChange: true,
    }), data = _d.data, loading = _d.loading, error = _d.error, refetch = _d.refetch, fetchMore = _d.fetchMore;
    // Unfortunateley this is the only way to make sure the filters are reflected when the use clicks on the page link
    useEffect(function () {
        refetch({
            filter: __assign(__assign({}, getQueryFilters(initialFilters)), { first: PAGE_SIZE }),
        });
    }, [searchParams]);
    var list = (_a = data === null || data === void 0 ? void 0 : data.events) === null || _a === void 0 ? void 0 : _a.edges.node;
    var pageInfo = (_b = data === null || data === void 0 ? void 0 : data.events) === null || _b === void 0 ? void 0 : _b.pageInfo;
    var handleFilter = function (filters) {
        // when switching host types remove Ids to "reset"
        if (filters.eventHostType &&
            !!searchParams.toString() &&
            filters.eventHostType !== searchParams.get("eventHostType")) {
            // TODO: avoid using delete
            if (filters.hostIds) {
                delete filters.hostIds;
            }
        }
        setSearchParams(filters);
        refetch({
            filter: __assign(__assign({}, getQueryFilters(filters)), { first: PAGE_SIZE }),
        });
    };
    var handleScroll = function () {
        if (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) {
            fetchMore({
                variables: {
                    filter: __assign({ first: PAGE_SIZE, after: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor }, getQueryFilters(getParamFilters(searchParams))),
                },
            });
        }
    };
    return error ? (_jsx(NotFoundView, {}, void 0)) : (_jsxs("div", __assign({ className: "pt-4 pl-3 w-full h-full flex flex-col" }, { children: [_jsx(FilterHeader, { title: _jsx(FormattedMessage, { id: "admin.events.filter.header.label" }, void 0), inputs: inputs, initialFilters: initialFilters, onFilter: handleFilter }, void 0), _jsx(EventsTable, { hostType: hostType, events: list || [], hasMore: !!(pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage), loading: loading, onScroll: handleScroll }, void 0), _jsx(Outlet, {}, void 0)] }), void 0));
};
var CheckForFlags = function () {
    var flags = useFeatureFlagsQuery().data;
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: flags === null || flags === void 0 ? void 0 : flags.baklavaFeatureFlags.baklavaEventManagement }, { children: _jsx(Events, {}, void 0) }), void 0));
};
export default CheckForFlags;
