var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactMarkdown from "markdown-to-jsx";
var Paragraph = function (_a) {
    var children = _a.children;
    return (_jsx("p", __assign({ className: "text-caption leading-caption mb-2" }, { children: children }), void 0));
};
var Heading1 = function (_a) {
    var children = _a.children;
    return _jsx("h3", __assign({ className: "mb-4" }, { children: children }), void 0);
};
var Heading2 = function (_a) {
    var children = _a.children;
    return (_jsx("h6", __assign({ className: "font-bold mt-3 mb-1" }, { children: children }), void 0));
};
var Markdown = function (_a) {
    var source = _a.source;
    return (_jsx(ReactMarkdown, __assign({ options: {
            overrides: {
                h1: Heading1,
                h2: Heading2,
                p: Paragraph,
            },
        } }, { children: source }), void 0));
};
export default Markdown;
