var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "components/atoms";
import InviteUserForm from "components/templates/forms/InviteUserForm";
import { useAlert } from "contexts/AlertContext";
import { useModal } from "contexts/ModalContext";
import { useCreateAdminInvitationMutation, useCreateMentorInvitationMutation, } from "generated/graphql";
var InviteUserModal = function () {
    var alert = useAlert();
    var modal = useModal();
    var intl = useIntl();
    var handleSuccessfulUserInvite = function () {
        alert.onSuccessAlert(intl.formatMessage({ id: "common.api.userInvite.succeeded" }));
        modal.close();
    };
    var _a = __read(useCreateAdminInvitationMutation({
        onCompleted: handleSuccessfulUserInvite,
    }), 2), createAdminInviteMutation = _a[0], inviteAdminLoading = _a[1].loading;
    var _b = __read(useCreateMentorInvitationMutation({
        onCompleted: handleSuccessfulUserInvite,
    }), 2), createMentorInviteMutation = _b[0], inviteMentorLoading = _b[1].loading;
    var handleSubmit = function (values) {
        if (values.role) {
            createMentorInviteMutation({ variables: { input: values } });
        }
        else {
            createAdminInviteMutation({ variables: { input: values } });
        }
    };
    return (_jsx(Modal, __assign({ size: "sm" }, { children: _jsx("div", __assign({ className: "my-4 mx-6" }, { children: _jsxs("div", __assign({ className: "text-size-small" }, { children: [_jsx("h6", __assign({ className: "font-bold mb-1" }, { children: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.actionBtn.label" }, void 0) }), void 0), _jsx(InviteUserForm, { onSubmit: handleSubmit, isLoading: inviteAdminLoading || inviteMentorLoading, onCancel: modal.close }, void 0)] }), void 0) }), void 0) }), void 0));
};
export default InviteUserModal;
