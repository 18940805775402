var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useRef, useState } from "react";
import { Icon } from "components/atoms";
import onOutsideClick from "utils/helpers/onOutsideClick";
var Menu = function (_a) {
    var options = _a.options;
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var wrapperRef = useRef(null);
    onOutsideClick(wrapperRef, function () { return setOpen(false); });
    var handleOpenClick = function () {
        setOpen(!open);
    };
    return (_jsxs("div", __assign({ className: "relative", ref: wrapperRef }, { children: [_jsx(Icon, { name: "VerticalDots", className: "cursor-pointer text-size-h5", onClick: handleOpenClick }, void 0), open && (_jsx("ol", __assign({ className: "absolute w-52 bg-white z-10 right-2 mt-1 shadow-lg py-2 rounded select-none" }, { children: options.map(function (option) { return (_jsx("li", __assign({ onClick: function () {
                        option.onClick();
                        setOpen(false);
                    }, className: classNames("text-size-small px-3 h-5 flex items-center", {
                        "text-black25 pointer-events-none cursor-not-allowed": option.disabled,
                    }, {
                        "hover:bg-primary10 cursor-pointer text-black90": !option.disabled,
                    }) }, { children: option.label }), option.id)); }) }), void 0))] }), void 0));
};
export default Menu;
