var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "components/atoms";
import { MiniSpinner } from "components/atoms/Spinner";
var Header = function (_a) {
    var title = _a.title, actionBtn = _a.actionBtn, _b = _a.actionLoading, actionLoading = _b === void 0 ? false : _b, children = _a.children;
    return (_jsxs("div", __assign({ className: "px-6 py-4 bg-gradient flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("h4", { children: title }, void 0), actionBtn && (_jsx(Button, __assign({ onClick: actionBtn.onClick }, { children: _jsxs("div", __assign({ className: "relative" }, { children: [actionLoading && _jsx(MiniSpinner, {}, void 0), _jsx("div", __assign({ className: actionLoading ? "invisible" : "" }, { children: actionBtn.label }), void 0)] }), void 0) }), void 0))] }), void 0), children && _jsx("div", __assign({ className: "flex flex-row items-center flex-wrap" }, { children: children }), void 0)] }), void 0));
};
export default Header;
