var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var sizes = {
    lg: "w-full",
    md: "w-1/2",
    sm: "w-2/3",
};
var Modal = function (_a) {
    var children = _a.children, size = _a.size;
    return (_jsx("div", __assign({ className: "shadow-lg rounded-md p-1 bg-white ".concat(size && sizes[size]) }, { children: children }), void 0));
};
export default Modal;
