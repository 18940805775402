var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useLocale } from "contexts/IntlContext";
var LocaleSelect = function () {
    var _a = useLocale(), locale = _a.locale, setLocale = _a.setLocale;
    return (_jsxs("div", __assign({ className: "px-3 my-4 text-size-caption" }, { children: [_jsx("p", { children: _jsx(FormattedMessage, { id: "common.privateLayout.languageSelect.label" }, void 0) }, void 0), _jsxs("div", __assign({ className: "my-1 flex gap-3" }, { children: [_jsx("p", __assign({ className: classNames("cursor-pointer hover:underline", {
                            "underline text-primary font-bold": locale === "en",
                        }), onClick: function () {
                            setLocale("en");
                            localStorage.setItem("locale", "en");
                        } }, { children: "English" }), void 0), _jsx("p", __assign({ className: classNames("cursor-pointer hover:underline", {
                            "underline text-primary font-bold": locale === "ja",
                        }), onClick: function () {
                            setLocale("ja");
                            localStorage.setItem("locale", "ja");
                        } }, { children: "日本語" }), void 0)] }), void 0)] }), void 0));
};
export default LocaleSelect;
