var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components/atoms";
import Menu from "components/molecules/Menu";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { useAuth } from "contexts/AuthContext";
import { EventHostType, EventStatus, EventType, RoleName, } from "generated/graphql";
import { useDeleteEventByIdMutation } from "generated/graphql";
import { coreMentorAndAboveRoles } from "utils/constants";
var EventsTable = function (_a) {
    var events = _a.events, loading = _a.loading, hasMore = _a.hasMore, onScroll = _a.onScroll, hostType = _a.hostType;
    var initialColumns = [
        {
            Header: _jsx(FormattedMessage, { id: "admin.events.table.header.eventName.label" }, void 0),
            accessor: "name",
            Cell: function (cellInfo) {
                return cellInfo.row.original.status_typed === EventStatus.Deleted ? (_jsx("span", __assign({ className: "text-black10 cursor-not-allowed font-bold" }, { children: cellInfo.row.original.title }), void 0)) : (_jsx(Link, __assign({ to: String(cellInfo.row.original.id), className: "text-primary font-bold" }, { children: cellInfo.row.original.title }), void 0));
            },
        },
        {
            Header: (_jsx(FormattedMessage, { id: hostType === EventHostType.Branch
                    ? "admin.events.table.header.branches.label"
                    : "admin.events.table.header.clients.label" }, void 0)),
            accessor: "organizerNames",
            Cell: function (cellInfo) {
                return (_jsx("span", { children: cellInfo.row.original.branches.length > 0
                        ? cellInfo.row.original.branches.map(function (branch) { return branch.name; }).join(", ")
                        : cellInfo.row.original.clients.map(function (client) { return client.company.name; }).join(", ") }, void 0));
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.events.table.header.eventType.label" }, void 0),
            accessor: "eventType",
            Cell: function (cellInfo) {
                if (cellInfo.row.original.eventType_typed === EventType.Entry) {
                    return (_jsx(Link, __assign({ to: "".concat(String(cellInfo.row.original.id), "/entors") }, { children: _jsx(Button, __assign({ inverted: true }, { children: _jsx(FormattedMessage, { id: "admin.events.table.column.details.entry.label" }, void 0) }), void 0) }), void 0));
                }
                else if (cellInfo.row.original.eventType_typed === EventType.Scheduled) {
                    return (_jsxs("div", __assign({ className: "flex gap-3" }, { children: [_jsx(Link, __assign({ to: "".concat(String(cellInfo.row.original.id), "/entors") }, { children: _jsx(Button, __assign({ inverted: true }, { children: _jsx(FormattedMessage, { id: "admin.events.table.column.details.scheduled.entries.label" }, void 0) }), void 0) }), void 0), _jsx(Link, __assign({ to: "".concat(String(cellInfo.row.original.id), "/dates") }, { children: _jsx(Button, __assign({ inverted: true }, { children: _jsx(FormattedMessage, { id: "admin.events.table.column.details.scheduled.dates.label" }, void 0) }), void 0) }), void 0)] }), void 0));
                }
                return _jsx(_Fragment, {}, void 0);
            },
        },
    ];
    var _b = __read(useState(initialColumns), 2), columns = _b[0], setColumns = _b[1];
    var _c = __read(useDeleteEventByIdMutation(), 1), deleteEventByIdMutation = _c[0];
    var roleSet = useAuth().roleSet;
    var navigate = useNavigate();
    useEffect(function () {
        if (coreMentorAndAboveRoles.some(function (role) { return roleSet.has(role); })) {
            setColumns(function (oldColumns) { return __spreadArray(__spreadArray([], __read(oldColumns), false), [
                {
                    Header: _jsx(FormattedMessage, { id: "admin.events.table.header.operation.label" }, void 0),
                    accessor: "eventOptions",
                    Cell: function (cellInfo) {
                        var eventOptions = [
                            {
                                id: "copy",
                                label: _jsx(FormattedMessage, { id: "admin.events.table.column.options.copy.label" }, void 0),
                                onClick: function () { return navigate("/events/new?copyEventId=".concat(cellInfo.row.original.id)); },
                            },
                            {
                                id: "delete",
                                label: _jsx(FormattedMessage, { id: "admin.events.table.column.options.delete.label" }, void 0),
                                onClick: function () {
                                    deleteEventByIdMutation({
                                        variables: {
                                            id: cellInfo.row.original.id,
                                        },
                                    });
                                },
                                disabled: 
                                // Cannot delete non draft events
                                cellInfo.row.original.status_typed !== EventStatus.Draft ||
                                    // Only Admins and HQ mentors can delete events
                                    (!roleSet.has(RoleName.Admin) && !roleSet.has(RoleName.HeadQuarter)) ||
                                    // Headquarters mentors can only delete branch events
                                    (!roleSet.has(RoleName.Admin) &&
                                        roleSet.has(RoleName.HeadQuarter) &&
                                        hostType !== EventHostType.Branch),
                            },
                        ];
                        return (_jsx("div", __assign({ className: "flex justify-right" }, { children: _jsx(Menu, { options: eventOptions }, void 0) }), void 0));
                    },
                },
            ], false); });
        }
    }, []);
    useEffect(function () {
        setColumns(function (oldColumns) {
            var newColumns = __spreadArray([], __read(oldColumns), false);
            oldColumns[1].Header = (_jsx(FormattedMessage, { id: hostType === EventHostType.Branch
                    ? "admin.events.table.header.branches.label"
                    : "admin.events.table.header.clients.label" }, void 0));
            newColumns[1] = oldColumns[1];
            return newColumns;
        });
    }, [hostType]);
    return events && (events === null || events === void 0 ? void 0 : events.length) === 0 && !loading ? (_jsx(NoResult, {}, void 0)) : (_jsx(Table, { columns: columns, data: events, hasMore: hasMore, onScroll: onScroll, loading: loading }, void 0));
};
export default EventsTable;
