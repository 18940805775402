var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "components/atoms";
var UserAssignmentModal = function (_a) {
    var title = _a.title, description = _a.description, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    return (_jsx(Modal, { children: _jsxs("div", __assign({ className: "m-4" }, { children: [_jsx("h6", __assign({ className: "font-bold my-3" }, { children: title }), void 0), _jsx("div", __assign({ className: "mt-3 mb-5" }, { children: description }), void 0), _jsxs("div", __assign({ className: "m-3 flex flex-row justify-end gap-3" }, { children: [_jsx(Button, __assign({ inverted: true, type: "reset", onClick: onCancel }, { children: _jsx(FormattedMessage, { id: "common.button.cancel.label" }, void 0) }), void 0), _jsx(Button, __assign({ onClick: onConfirm }, { children: _jsx(FormattedMessage, { id: "common.button.confirm.label" }, void 0) }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default UserAssignmentModal;
