var _a;
export var Icons;
(function (Icons) {
    Icons["ArrowLeft"] = "arrow-left";
    Icons["Building"] = "building";
    Icons["CalendarPlus"] = "calendar-plus";
    Icons["Calendar"] = "calendar";
    Icons["CheckCircle"] = "check-circle";
    Icons["CheckboxChecked"] = "checkbox-checked";
    Icons["Checkbox"] = "checkbox";
    Icons["ChevronDown"] = "chevron-down";
    Icons["ChevronLeft"] = "chevron-left";
    Icons["ChevronRight"] = "chevron-right";
    Icons["ChevronUp"] = "chevron-up";
    Icons["Clipboard"] = "clipboard";
    Icons["Copy"] = "copy";
    Icons["Download"] = "download";
    Icons["EditorPen"] = "editor-pen";
    Icons["EditorTextRegular"] = "editor-text-regular";
    Icons["ErrorCircle"] = "error-circle";
    Icons["Error"] = "error";
    Icons["Group"] = "group";
    Icons["Image"] = "image";
    Icons["InfoCircle"] = "info-circle";
    Icons["ListPlus"] = "list-plus";
    Icons["LogOut"] = "log-out";
    Icons["Magnet"] = "magnet";
    Icons["MessageDetail"] = "message-detail";
    Icons["News"] = "news";
    Icons["PlusCircle"] = "plus-circle";
    Icons["PlusSquare"] = "plus-square";
    Icons["QuoteLeft"] = "quote-left";
    Icons["QuoteRight"] = "quote-right";
    Icons["Quote"] = "quote";
    Icons["RadioCircleMarked"] = "radio-circle-marked";
    Icons["RadioCircle"] = "radio-circle";
    Icons["Shield"] = "shield";
    Icons["Sitemap"] = "sitemap";
    Icons["SortAlt"] = "sort-alt";
    Icons["Time"] = "time";
    Icons["ToggleLeft"] = "toggle-left";
    Icons["ToggleRight"] = "toggle-right";
    Icons["Trash"] = "trash";
    Icons["UserCircle"] = "user-circle";
    Icons["UserDetail"] = "user-detail";
    Icons["User"] = "user";
    Icons["VerticalDots"] = "vertical-dots";
    Icons["Videos"] = "videos";
    Icons["Windows"] = "windows";
    Icons["XCircle"] = "x-circle";
    Icons["XSquare"] = "x-square";
    Icons["X"] = "x";
})(Icons || (Icons = {}));
export var ICONS_CODEPOINTS = (_a = {},
    _a[Icons.ArrowLeft] = "61697",
    _a[Icons.Building] = "61698",
    _a[Icons.CalendarPlus] = "61699",
    _a[Icons.Calendar] = "61700",
    _a[Icons.CheckCircle] = "61701",
    _a[Icons.CheckboxChecked] = "61702",
    _a[Icons.Checkbox] = "61703",
    _a[Icons.ChevronDown] = "61704",
    _a[Icons.ChevronLeft] = "61705",
    _a[Icons.ChevronRight] = "61706",
    _a[Icons.ChevronUp] = "61707",
    _a[Icons.Clipboard] = "61708",
    _a[Icons.Copy] = "61709",
    _a[Icons.Download] = "61710",
    _a[Icons.EditorPen] = "61711",
    _a[Icons.EditorTextRegular] = "61712",
    _a[Icons.ErrorCircle] = "61713",
    _a[Icons.Error] = "61714",
    _a[Icons.Group] = "61715",
    _a[Icons.Image] = "61716",
    _a[Icons.InfoCircle] = "61717",
    _a[Icons.ListPlus] = "61718",
    _a[Icons.LogOut] = "61719",
    _a[Icons.Magnet] = "61720",
    _a[Icons.MessageDetail] = "61721",
    _a[Icons.News] = "61722",
    _a[Icons.PlusCircle] = "61723",
    _a[Icons.PlusSquare] = "61724",
    _a[Icons.QuoteLeft] = "61725",
    _a[Icons.QuoteRight] = "61726",
    _a[Icons.Quote] = "61727",
    _a[Icons.RadioCircleMarked] = "61728",
    _a[Icons.RadioCircle] = "61729",
    _a[Icons.Shield] = "61730",
    _a[Icons.Sitemap] = "61731",
    _a[Icons.SortAlt] = "61732",
    _a[Icons.Time] = "61733",
    _a[Icons.ToggleLeft] = "61734",
    _a[Icons.ToggleRight] = "61735",
    _a[Icons.Trash] = "61736",
    _a[Icons.UserCircle] = "61737",
    _a[Icons.UserDetail] = "61738",
    _a[Icons.User] = "61739",
    _a[Icons.VerticalDots] = "61740",
    _a[Icons.Videos] = "61741",
    _a[Icons.Windows] = "61742",
    _a[Icons.XCircle] = "61743",
    _a[Icons.XSquare] = "61744",
    _a[Icons.X] = "61745",
    _a);
