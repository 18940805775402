var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { TextArea } from "components/atoms";
import { EventType } from "generated/graphql";
import { DEFAULT_EMAILS, DEFAULT_ENTRY_EVENT_EMAILS } from "utils/constants";
import { CheckState } from "../schema";
var MailFormBlock = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var idx = _a.idx;
    var _h = useFormContext(), register = _h.register, getValues = _h.getValues, trigger = _h.trigger, control = _h.control, errors = _h.formState.errors;
    var update = useFieldArray({
        control: control,
        name: "emails",
    }).update;
    var emails = getValues("emails") || [];
    var eventType = getValues("eventType") || "";
    var timing = (_b = emails[idx]) === null || _b === void 0 ? void 0 : _b.timing_typed;
    var _j = __read(useState(emails === null || emails === void 0 ? void 0 : emails[idx].checkState), 2), checkedState = _j[0], setCheckedState = _j[1];
    var handleCheck = function (e) {
        var checkState = e.target.value;
        setCheckedState(checkState);
        if (checkState === CheckState.Custom) {
            update(idx, __assign(__assign({}, emails[idx]), { checkState: e.target.value }));
        }
        else {
            update(idx, __assign(__assign({}, emails[idx]), { checkState: e.target.value, body: eventType == EventType.Entry ? DEFAULT_ENTRY_EVENT_EMAILS[timing] : DEFAULT_EMAILS[timing] }));
        }
        trigger("emails.".concat(idx, ".body"));
    };
    return (_jsxs("div", { children: [_jsx("h5", __assign({ className: "text-black text-size-small" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.mailFormBlock.".concat(timing, ".title") }, void 0) }), void 0), _jsx("fieldset", __assign({ className: "flex gap-3" }, { children: Object.values(CheckState).map(function (checkState) { return (_jsxs("label", __assign({ className: "cursor-pointer" }, { children: [_jsx("input", { type: "radio", name: "checkstate_".concat(timing), value: checkState, checked: checkedState === checkState, onChange: handleCheck }, void 0), _jsx("span", __assign({ className: "ml-2" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.mailFormBlock.".concat(checkState, ".label") }, void 0) }), void 0)] }), checkState)); }) }), void 0), checkedState === CheckState.Custom ? (_jsx(TextArea, __assign({ className: "my-2" }, register("emails.".concat([idx], ".body")), { onChange: function (e) {
                    update(idx, __assign(__assign({}, emails[idx]), { body: e.currentTarget.value }));
                    trigger("emails.".concat(idx, ".body"));
                }, error: (_e = (_d = (_c = errors.emails) === null || _c === void 0 ? void 0 : _c[idx]) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.message, rows: 6, label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.mailFormBlock.mailContent.label" }, void 0), name: "mailContents" }), void 0)) : (_jsx(TextArea, { className: "my-2", disabled: true, value: eventType == EventType.Entry ?
                    DEFAULT_ENTRY_EVENT_EMAILS[(_f = emails[idx]) === null || _f === void 0 ? void 0 : _f.timing_typed] :
                    DEFAULT_EMAILS[(_g = emails[idx]) === null || _g === void 0 ? void 0 : _g.timing_typed], rows: 6, label: _jsx(FormattedMessage, { id: "common.eventPage.editForm.postEvent.mailFormBlock.mailContent.label" }, void 0), name: "mailContents" }, void 0))] }, void 0));
};
export default MailFormBlock;
