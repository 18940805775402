var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Icon, Input } from "components/atoms";
import { DATE_FORMAT_YYYYMMDD } from "utils/constants";
var EntryEvent = function () {
    var _a, _b, _c, _d;
    var _e = useFormContext(), register = _e.register, watch = _e.watch, formState = _e.formState;
    var deadlineDate = dayjs(watch("entryEvent.deadlineDate"));
    return (_jsxs("div", __assign({ className: "px-6 py-5" }, { children: [_jsxs("p", __assign({ className: "flex align-center text-size-caption text-black50 -translate-y-2" }, { children: [_jsx(Icon, { name: "ErrorCircle" }, void 0), _jsx(FormattedMessage, { id: "common.eventPage.editForm.entryEvent.description" }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex gap-6 items-center" }, { children: [_jsx("span", __assign({ className: "text-size-small basis-2/12" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.entryEvent.deadline" }, void 0) }), void 0), _jsxs("div", __assign({ className: "w-2/5 flex gap-6" }, { children: [_jsx(Input, __assign({ label: DATE_FORMAT_YYYYMMDD }, register("entryEvent.deadlineDate"), { isRequired: true, error: (_b = (_a = formState.errors.entryEvent) === null || _a === void 0 ? void 0 : _a.deadlineDate) === null || _b === void 0 ? void 0 : _b.message }), void 0), _jsx(Input, __assign({ label: "HH:MM" }, register("entryEvent.deadlineTime"), { isRequired: true, error: (_d = (_c = formState.errors.entryEvent) === null || _c === void 0 ? void 0 : _c.deadlineTime) === null || _d === void 0 ? void 0 : _d.message }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex gap-6 items-center" }, { children: [_jsx("span", __assign({ className: "text-size-small basis-2/12" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.entryEvent.maxParticipants.label" }, void 0) }), void 0), _jsx("div", __assign({ className: "w-1/5 flex gap-6" }, { children: _jsx(Input, __assign({ label: "", type: "number" }, register("entryEvent.limitNum", {
                            setValueAs: function (v) { return (v === "" ? undefined : parseInt(v, 10)); },
                        })), void 0) }), void 0)] }), void 0), deadlineDate < dayjs() && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "my-3 text-size-body text-black font-bold" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.entryEvent.postDeadline" }, void 0) }), void 0), _jsxs("div", __assign({ className: "flex gap-6 items-center" }, { children: [_jsx("span", __assign({ className: "basis-2/12" }, { children: _jsx(FormattedMessage, { id: "common.eventPage.editForm.entryEvent.lastEntryCount.label" }, void 0) }), void 0), _jsx("div", __assign({ className: "w-1/5 flex gap-6" }, { children: _jsx(Input, __assign({ type: "number" }, register("entryEvent.accountsRegistered", {
                                    setValueAs: function (v) { return (v === "" ? undefined : Number(v)); },
                                }), { label: "" }), void 0) }), void 0)] }), void 0)] }, void 0))] }), void 0));
};
export default EntryEvent;
