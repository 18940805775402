var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Modal } from "components/atoms";
import BranchForm from "components/templates/forms/BranchForm";
import { useAlert } from "contexts/AlertContext";
import { useModal } from "contexts/ModalContext";
import { useCreateBranchMutation, useUpdateBranchMutation, } from "generated/graphql";
var BranchModal = function (_a) {
    var onCreated = _a.onCreated, branch = _a.branch;
    var initialValues = useMemo(function () {
        if (branch) {
            return {
                name: branch.name,
                schoolIDs: branch.schools.map(function (school) { return school.id; }),
                status: branch.status_typed,
            };
        }
        return undefined;
    }, []);
    var alert = useAlert();
    var modal = useModal();
    var intl = useIntl();
    var handleSuccessfulBranchChange = function () {
        alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
        if (onCreated) {
            onCreated();
        }
        modal.close();
    };
    var _b = __read(useCreateBranchMutation({
        onCompleted: handleSuccessfulBranchChange,
    }), 2), createBranchMutation = _b[0], createLoading = _b[1].loading;
    var _c = __read(useUpdateBranchMutation({
        onCompleted: handleSuccessfulBranchChange,
    }), 2), updateBranchMutation = _c[0], updateLoading = _c[1].loading;
    var handleSubmit = function (input) {
        if (branch) {
            updateBranchMutation({ variables: { id: branch.id, input: input } });
        }
        else {
            createBranchMutation({ variables: { input: input } });
        }
    };
    return (_jsx(Modal, __assign({ size: "lg" }, { children: _jsx(BranchForm, { initialValues: initialValues, onSubmit: handleSubmit, onClose: modal.close, isLoading: createLoading || updateLoading, mode: "create" }, void 0) }), void 0));
};
export default BranchModal;
