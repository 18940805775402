var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as yup from "yup";
import { EventHostType, EventType, QuestionType } from "generated/graphql";
import { dateRegex, time24hRegex } from "utils/regex";
import { editorSchema } from "../../Editor";
dayjs.extend(isSameOrBefore, isSameOrAfter);
export var CheckState;
(function (CheckState) {
    CheckState["Default"] = "default";
    CheckState["Custom"] = "custom";
    CheckState["Off"] = "off";
})(CheckState || (CheckState = {}));
export var baseQuestionSchema = {
    id: yup.string(),
    title: yup.string().trim().min(4).max(120).required(),
    required: yup.boolean(),
    questionType: yup.string().oneOf(Object.values(QuestionType)).required(),
    label: yup.string(),
    wordCount: yup.number(),
    options: yup
        .array()
        .of(yup.object({
        label: yup.string().trim().min(1).required(),
        id: yup.string(),
    }))
        .when("questionType", {
        is: function (value) { return value === QuestionType.Text; },
        then: function (schema) { return yup.array(); },
        otherwise: function (schema) { return schema; },
    }),
};
export var emailItem = yup
    .object({
    // Prevent custom email body to be an empty string
    body: yup.string().trim().min(1).required(),
    defaultStatus_typed: yup.string(),
    timing_typed: yup.string().required(),
    checkState: yup.string().oneOf(Object.values(CheckState)).required(),
})
    .required();
export var scheduledEventDate = yup.object({
    startAtDate: yup
        .string()
        .matches(dateRegex, function () { return ({ locale_id: "common.form.field.date.error" }); })
        .required(),
    startAtTime: yup
        .string()
        .matches(time24hRegex, function () { return ({ locale_id: "common.form.field.time.error" }); })
        .required(),
    deadlineDate: yup
        .string()
        .matches(dateRegex, function () { return ({ locale_id: "common.form.field.date.error" }); })
        .required()
        .test("deadlineBeforeEnd", function () { return ({ locale_id: "common.eventPage.editForm.scheduledEvent.date.deadlineBeforeEnd" }); }, function (_, context) {
        var _a = context.parent, endAtDate = _a.endAtDate, deadlineDate = _a.deadlineDate;
        return dayjs(deadlineDate).isSameOrBefore(endAtDate);
    }),
    deadlineTime: yup
        .string()
        .matches(time24hRegex, function () { return ({ locale_id: "common.form.field.time.error" }); })
        .required()
        .test("deadlineBeforeEnd", function () { return ({ locale_id: "common.eventPage.editForm.scheduledEvent.date.deadlineBeforeEnd" }); }, function (_, context) {
        var _a = context.parent, endAtTime = _a.endAtTime, endAtDate = _a.endAtDate, deadlineDate = _a.deadlineDate, deadlineTime = _a.deadlineTime;
        var endAt = dayjs("".concat(endAtDate, " ").concat(endAtTime));
        var deadline = dayjs("".concat(deadlineDate, " ").concat(deadlineTime));
        return deadline.isSameOrBefore(endAt);
    }),
    endAtDate: yup
        .string()
        .matches(dateRegex, function () { return ({ locale_id: "common.form.field.date.error" }); })
        .required()
        .test("endAfterStart", function () { return ({ locale_id: "common.eventPage.editForm.scheduledEvent.date.endAfterStart" }); }, function (_, context) {
        var _a = context.parent, startAtDate = _a.startAtDate, startAtTime = _a.startAtTime, endAtTime = _a.endAtTime, endAtDate = _a.endAtDate;
        var start = dayjs("".concat(startAtDate, " ").concat(startAtTime));
        var end = dayjs("".concat(endAtDate, " ").concat(endAtTime));
        return end.isAfter(start);
    }),
    endAtTime: yup
        .string()
        .matches(time24hRegex, function () { return ({ locale_id: "common.form.field.time.error" }); })
        .required(),
    doorsOpenAt: yup.string().required(),
    place: yup.object({
        prefectureId: yup.number().required(),
        address1: yup.string().required(),
        address2: yup.string().required(),
        emergencyContact: yup.string().required(),
        access: yup.string().required(),
    }),
    seatsNum: yup.number().required(),
    reservationNum: yup.number().required(),
    seatsUsed: yup.number(),
    id: yup.number(),
});
export var scheduledEventSchema = yup
    .object({
    dates: yup.array().of(scheduledEventDate),
    contact: yup.string().required(),
    dressCode: yup.string().required(),
    belongings: yup.string().required(),
    fee: yup.string().required(),
})
    .when("eventType", {
    is: function (value) { return value === EventType.Entry; },
    then: function () { return yup.object(); },
});
export var entryEventSchema = yup
    .object({
    deadlineDate: yup
        .string()
        .matches(dateRegex, function () { return ({ locale_id: "common.form.field.date.error" }); })
        .required(),
    deadlineTime: yup
        .string()
        .matches(time24hRegex, function () { return ({ locale_id: "common.form.field.time.error" }); })
        .required(),
    limitNum: yup.number().nullable(),
    accountsRegistered: yup.number().nullable(),
})
    .when("eventType", {
    is: function (value) { return value === EventType.Scheduled; },
    then: function () { return yup.object(); },
});
export var basicEventInfoSchema = yup.object({
    title: yup.string().min(16).max(64).required(),
    eventType: yup.string().oneOf([EventType.Entry, EventType.Scheduled]).required(),
    categoryId: yup.number().required(),
    targetYears: yup.array().of(yup.number().required()).required(),
    imageUrl: yup.string().required(),
    jobhuntInfoRequired: yup.boolean(),
    status: yup.string().required(),
    postApplicationLink: yup.object({
        url: yup.string(),
        description: yup.string(),
    }),
    entryEvent: entryEventSchema,
    scheduledEvent: scheduledEventSchema,
    questions: yup.array().of(yup.object(__assign({}, baseQuestionSchema))),
    eventApplicationScreen: yup.object({
        hasInterviewed: yup.boolean(),
        cancelledPercentMax: yup.number(),
        attendedEventMin: yup.number(),
    }),
    targetSchools: yup.array().of(yup.number().required()),
    emails: yup.array().of(emailItem),
    contents: editorSchema,
});
var adminClientAndBranches = yup.object({
    eventHostType_typed: yup.mixed().oneOf(Object.values(EventHostType)).defined().required(),
    participatingClients: yup
        .array()
        .of(yup.number().required())
        .when("eventHostType_typed", {
        is: EventHostType.HeadQuarter,
        then: function (schema) { return schema.required(); },
    }),
    participatingBranches: yup
        .array()
        .of(yup.number().required())
        .when("eventHostType_typed", {
        is: EventHostType.Branch,
        then: function (schema) { return schema.required(); },
    }),
    participatingSingleClient: yup.number().when("eventHostType_typed", {
        is: EventHostType.Client,
        then: function (schema) { return schema.required(); },
    }),
});
var branchRestrictedRoles = yup.object({
    eventHostType_typed: yup.mixed().oneOf(Object.values(EventHostType)).defined().required(),
});
var branchAllowedRoles = yup.object({
    eventHostType_typed: yup.mixed().oneOf(Object.values(EventHostType)).defined().required(),
    participatingBranches: yup.array().of(yup.number().required()).required(),
});
var defaultEventSchema = basicEventInfoSchema.concat(adminClientAndBranches);
export var noClientEventSchema = basicEventInfoSchema.concat(branchRestrictedRoles);
export var onlyBranchesEventSchema = basicEventInfoSchema.concat(branchAllowedRoles);
export default defaultEventSchema;
