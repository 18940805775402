var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Spinner } from "components/atoms";
import Header from "components/molecules/Header";
import EditEventForm from "components/templates/forms/EventForm";
import { useAlert } from "contexts/AlertContext";
import { useAuth } from "contexts/AuthContext";
import { EventHostType, EventType, RoleName, useCreateEntryEventMutation, useCreateScheduledEventMutation, useEventDetailsLazyQuery, useFeatureFlagsQuery, useUpdateEntryEventMutation, useUpdateScheduledEventMutation, } from "generated/graphql";
import { queryToSchema } from "utils/helpers/eventSchemaMapper";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
var EventsView = function () {
    var alert = useAlert();
    var intl = useIntl();
    var navigate = useNavigate();
    var roleSet = useAuth().roleSet;
    var id = useParams().id;
    var _a = __read(useSearchParams(), 1), searchParams = _a[0];
    var copyEventId = searchParams.get("copyEventId");
    var _b = __read(useEventDetailsLazyQuery({
        notifyOnNetworkStatusChange: true,
    }), 2), getEvent = _b[0], _c = _b[1], data = _c.data, client = _c.client, loading = _c.loading, refetch = _c.refetch;
    var event = id || copyEventId ? data === null || data === void 0 ? void 0 : data.eventById : undefined;
    useEffect(function () {
        if (id || copyEventId) {
            getEvent({
                variables: {
                    id: Number(id || copyEventId),
                },
            });
        }
    }, [id, copyEventId]);
    var handleSuccesfulEventUpdate = function () {
        if (!copyEventId && id) {
            client.cache.evict({ id: "Event:".concat(id) });
        }
        refetch();
        alert.onSuccessAlert(intl.formatMessage({
            id: "common.eventPage.editForm.api.success.mutation.update",
        }));
    };
    var handleSuccesfulEventCreation = function (newId) {
        if (typeof newId === "number") {
            alert.onSuccessAlert(intl.formatMessage({
                id: "common.eventPage.editForm.api.success.mutation.creation",
            }));
            navigate("../".concat(newId));
        }
        else {
            alert.onErrorAlert(intl.formatMessage({
                id: "common.eventPage.editForm.api.fail.mutation.creation",
            }));
        }
    };
    var _d = __read(useCreateScheduledEventMutation({
        onCompleted: function (createData) {
            handleSuccesfulEventCreation(createData === null || createData === void 0 ? void 0 : createData.createScheduledEvent.event.id);
        },
    }), 1), createScheduledEventMutation = _d[0];
    var _e = __read(useUpdateScheduledEventMutation({
        onCompleted: handleSuccesfulEventUpdate,
    }), 1), updateScheduledEventMutation = _e[0];
    var _f = __read(useCreateEntryEventMutation({
        onCompleted: function (createData) {
            handleSuccesfulEventCreation(createData === null || createData === void 0 ? void 0 : createData.createEntryEvent.event.id);
        },
    }), 1), createEntryEventMutation = _f[0];
    var _g = __read(useUpdateEntryEventMutation({
        onCompleted: handleSuccesfulEventUpdate,
    }), 1), updateEntryEventMutation = _g[0];
    var handleSubmit = function (updatedData, submissionType) {
        var _a, _b;
        if (submissionType === EventType.Entry) {
            if (id && event) {
                var inputWithId = __assign(__assign({}, updatedData), { id: event.id });
                updateEntryEventMutation({
                    variables: { input: inputWithId, hash: event.hash },
                });
            }
            else {
                var questions = (_a = updatedData.eventCommonInput.questions) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                    var _b;
                    var _ = _a.id, question = __rest(_a, ["id"]);
                    question.options = (_b = question.options) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                        var _ = _a.id, option = __rest(_a, ["id"]);
                        return option;
                    });
                    return question;
                });
                updatedData.eventCommonInput.questions = questions;
                createEntryEventMutation({
                    variables: { input: updatedData },
                });
            }
        }
        if (submissionType === EventType.Scheduled) {
            if (id && event) {
                var inputWithId = __assign(__assign({}, updatedData), { id: event.id });
                updateScheduledEventMutation({
                    variables: {
                        input: inputWithId,
                        hash: event.hash,
                    },
                });
            }
            else {
                updatedData.scheduled.dates = updatedData.scheduled.dates.map(function (date) {
                    var _a = date, _ = _a.id, dateWithoutId = __rest(_a, ["id"]);
                    return dateWithoutId;
                });
                var questions = (_b = updatedData.eventCommonInput.questions) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                    var _b;
                    var _ = _a.id, question = __rest(_a, ["id"]);
                    question.options = (_b = question.options) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                        var _ = _a.id, option = __rest(_a, ["id"]);
                        return option;
                    });
                    return question;
                });
                updatedData.eventCommonInput.questions = questions;
                createScheduledEventMutation({
                    variables: {
                        input: updatedData,
                    },
                });
            }
        }
    };
    // If there is no ID, then it's a new event
    // Mentors can not make new events
    // CM and BM can make events with only their own branch
    // Headquarter can make events with any branches.
    // Admin can make events with no limitations
    var readOnly = !id
        ? false
        : roleSet.has(RoleName.Mentor)
            ? true
            : (event === null || event === void 0 ? void 0 : event.eventHostType_typed) !== EventHostType.Branch &&
                (roleSet.has(RoleName.HeadQuarter) ||
                    roleSet.has(RoleName.BranchManager) ||
                    roleSet.has(RoleName.CoreMentor));
    var initialValues = useMemo(function () { return (event ? queryToSchema(event) : undefined); }, [event === null || event === void 0 ? void 0 : event.id]);
    if (loading)
        return _jsx(Spinner, {}, void 0);
    return (_jsxs("div", __assign({ className: "pt-4 pl-3 w-full h-full flex flex-col" }, { children: [_jsx(Header, { title: _jsx(FormattedMessage, { id: readOnly
                        ? "common.eventPage.editForm.readOnlyTitle"
                        : data
                            ? "common.eventPage.editForm.editTitle"
                            : "common.eventPage.editForm.createTitle" }, void 0) }, void 0), _jsx(EditEventForm, { initialValues: initialValues, onSubmit: handleSubmit, eventHash: event === null || event === void 0 ? void 0 : event.hash, submittable: !readOnly, isNewEvent: !id }, void 0)] }), void 0));
};
var CheckForFlags = function () {
    var flags = useFeatureFlagsQuery().data;
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: flags === null || flags === void 0 ? void 0 : flags.baklavaFeatureFlags.baklavaEventManagement }, { children: _jsx(EventsView, {}, void 0) }), void 0));
};
export default CheckForFlags;
