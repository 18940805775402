var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useIntl } from "react-intl";
import { useAlert } from "contexts/AlertContext";
var paginatedPolicy = {
    /*
      This prevents caching by different input keys.
      By default apollo will save query results
      into separate caches with the inputs as the
      key. In paginated queries something like different
      "offset" values would cause apollo to save each result
      in its own cache.
    */
    keyArgs: false,
    merge: function (existing, incoming) {
        if ((existing === null || existing === void 0 ? void 0 : existing.pageInfo.endCursor) === (incoming === null || incoming === void 0 ? void 0 : incoming.pageInfo.endCursor)) {
            return incoming;
        }
        var existingNode = (existing === null || existing === void 0 ? void 0 : existing.edges.node) || [];
        var incomingNode = (incoming === null || incoming === void 0 ? void 0 : incoming.edges.node) || [];
        return __assign(__assign({}, incoming), { edges: __assign(__assign({}, incoming.edges), { node: __spreadArray(__spreadArray([], __read(existingNode), false), __read(incomingNode), false) }) });
    },
};
var ApiClient = function (_a) {
    var children = _a.children;
    var intl = useIntl();
    var onErrorAlert = useAlert().onErrorAlert;
    var gqlClient = function () {
        var errorLink = onError(function (_a) {
            var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation;
            if (graphQLErrors) {
                if (process.env.NODE_ENV === "development")
                    console.error(graphQLErrors);
                graphQLErrors.forEach(function (_a) {
                    var _b = _a.extensions, name = _b.name, japaneseMessage = _b.japaneseMessage, message = _b.message;
                    var msg = "";
                    // Session expired error msg
                    if (name === "Unauthorized") {
                        msg = intl.formatMessage({ id: "common.api.errors.unauthorized" });
                        var exludeUnauthorizedErrorsOn = ["CurrentUser"];
                        if (exludeUnauthorizedErrorsOn.includes(operation.operationName))
                            return;
                    }
                    else {
                        msg = intl.formatMessage({ id: "common.api.errors.UnspecifiedError" });
                        if (intl.locale === "ja" && japaneseMessage && typeof japaneseMessage === "string") {
                            msg = japaneseMessage;
                        }
                        else if (message && typeof message === "string") {
                            msg = message;
                        }
                    }
                    onErrorAlert(msg);
                });
            }
            if (networkError) {
                onErrorAlert(intl.formatMessage({ id: "common.api.errors.UnspecifiedError" }));
            }
        });
        return new ApolloClient({
            connectToDevTools: process.env.NODE_ENV === "development",
            cache: new InMemoryCache({
                typePolicies: {
                    Query: {
                        fields: {
                            clients: paginatedPolicy,
                            events: paginatedPolicy,
                            users: paginatedPolicy,
                            entors: paginatedPolicy,
                        },
                    },
                    ScheduledEvent: {
                        fields: {
                            entors: paginatedPolicy,
                        },
                    },
                    EntryEvent: {
                        fields: {
                            entors: paginatedPolicy,
                        },
                    },
                },
            }),
            link: from([
                // authMiddleware,
                errorLink,
                new HttpLink({
                    uri: "".concat(process.env.SERVER_URL, "/graphql"),
                    credentials: "include",
                }),
            ]),
        });
    };
    return _jsx(ApolloProvider, __assign({ client: gqlClient() }, { children: children }), void 0);
};
export default ApiClient;
