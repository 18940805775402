var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { configureScope, setUser as setSentryUser } from "@sentry/react";
import { createContext, useContext, useEffect, useMemo, useState, } from "react";
import { RoleName, useCurrentUserLazyQuery, useLogoutMutation } from "generated/graphql";
import { publicRoutePath } from "_AdminApp/routes";
var authContext = createContext({
    userEmail: undefined,
    isAuthenticated: false,
    isAuthenticating: true,
    roleSet: new Set(),
    highestRole: RoleName.Entor,
    onAuth: function () { },
    clearAuth: function () { },
});
var ProvideAuth = function (_a) {
    var children = _a.children;
    var _b = __read(useCurrentUserLazyQuery(), 2), getUser = _b[0], _c = _b[1], data = _c.data, loadingUser = _c.loading;
    var email = ((data === null || data === void 0 ? void 0 : data.currentUser) || {}).email;
    var roles = (data === null || data === void 0 ? void 0 : data.currentUserRoles) || [];
    var _d = __read(useLogoutMutation(), 1), logoutMutation = _d[0];
    var _e = __read(useState(true), 2), initialloading = _e[0], setInitialLoading = _e[1];
    // get user on mount
    useEffect(function () {
        // dont get user when on public paths
        if (!window.location.href.includes(publicRoutePath.login) &&
            !window.location.href.includes(publicRoutePath.account)) {
            getUser().then(function () { return setInitialLoading(false); });
        }
    }, []);
    // set sentry email for error logging
    useEffect(function () {
        if (email) {
            setSentryUser({ email: email });
        }
        else {
            configureScope(function (scope) { return scope.setUser(null); });
        }
    }, [email]);
    var handleAuthenticated = function (onCompleted) {
        getUser().then(function () {
            setInitialLoading(false);
            if (onCompleted)
                onCompleted();
        });
    };
    var handleClear = function () {
        logoutMutation().then(function () {
            window.location.replace(publicRoutePath.login);
        });
    };
    var roleSet = useMemo(function () { return new Set(roles); }, [roles]);
    return (_jsx(authContext.Provider, __assign({ value: {
            isAuthenticated: !!email,
            isAuthenticating: initialloading || loadingUser,
            userEmail: email,
            roleSet: roleSet,
            highestRole: roles === null || roles === void 0 ? void 0 : roles[0],
            onAuth: handleAuthenticated,
            clearAuth: handleClear,
        } }, { children: children }), void 0));
};
export var useAuth = function () {
    return useContext(authContext);
};
export default ProvideAuth;
