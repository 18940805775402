var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useDebouncedCallback from "hooks/useDebounce";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import ClientStatusSelect from "components/molecules/FormSelects/SingleSelect/ClientStatusSelect";
import FilterHeader from "components/templates/FilterHeader";
import NotFoundView from "components/templates/NotFoundView";
import { useListClientsQuery } from "generated/graphql";
import { clientStatusMap } from "utils/mappers";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
import ClientsTable from "./ClientsTable";
var PAGE_SIZE = 30;
var FILTERS = [
    {
        name: "name",
        label: _jsx(FormattedMessage, { id: "admin.clientsPage.tableHeader.filter.clientName.label" }, void 0),
    },
    {
        name: "status",
        Component: ClientStatusSelect,
        singleSelect: true,
    },
];
var ClientList = function () {
    var _a, _b;
    var _c = __read(useSearchParams(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var initialFilters = useMemo(function () { return ({
        name: searchParams.get("name") || undefined,
        status: searchParams.get("status") || undefined,
    }); }, []);
    var _d = useListClientsQuery({
        variables: {
            filter: {
                first: PAGE_SIZE,
                companyName: initialFilters.name,
                status: initialFilters.status ? clientStatusMap(initialFilters.status) : undefined,
            },
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true, // this will trigger a loading state on refetch
    }), data = _d.data, loading = _d.loading, error = _d.error, refetch = _d.refetch, fetchMore = _d.fetchMore;
    var list = (_a = data === null || data === void 0 ? void 0 : data.clients) === null || _a === void 0 ? void 0 : _a.edges.node;
    var pageInfo = (_b = data === null || data === void 0 ? void 0 : data.clients) === null || _b === void 0 ? void 0 : _b.pageInfo;
    var handleFilter = useDebouncedCallback(function (filters) {
        setSearchParams(filters);
        refetch({
            filter: {
                companyName: filters.name,
                first: PAGE_SIZE,
                status: filters.status ? clientStatusMap(filters.status) : undefined,
            },
        });
    }, 500);
    var handleScroll = function () {
        if (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) {
            fetchMore({
                variables: {
                    filter: {
                        companyName: searchParams.get("name"),
                        status: searchParams.get("status")
                            ? clientStatusMap(searchParams.get("status"))
                            : undefined,
                        first: PAGE_SIZE,
                        after: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor,
                    },
                },
            });
        }
    };
    return error ? (_jsx(NotFoundView, {}, void 0)) : (_jsxs("div", __assign({ className: "w-full overflow-y-scroll flex flex-col mt-4" }, { children: [_jsx(FilterHeader, { title: _jsx(FormattedMessage, { id: "admin.clientsPage.tableHeader.title" }, void 0), initialFilters: initialFilters, inputs: FILTERS, onFilter: handleFilter }, void 0), _jsx(ClientsTable, { clients: list || [], loading: loading, hasMore: !!(pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage), onScroll: handleScroll }, void 0)] }), void 0));
};
var CheckForFlags = function () {
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: true }, { children: _jsx(ClientList, {}, void 0) }), void 0));
};
export default CheckForFlags;
