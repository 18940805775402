var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Button, Input, Modal, Spinner } from "components/atoms";
import { useAlert } from "contexts/AlertContext";
import { useModal } from "contexts/ModalContext";
import { useCreateInterviewQuestionMutation, useInterviewQuestionsQuery, useUpdateInterviewQuestionMutation, } from "generated/graphql";
var formSchema = yup.object({
    questionTitle: yup.string().min(4).required(),
});
var QuestionModal = function (_a) {
    var _b, _c, _d;
    var questionId = _a.questionId, subCategoryId = _a.subCategoryId, categoryId = _a.categoryId, onUpdated = _a.onUpdated;
    var _e = useInterviewQuestionsQuery(), data = _e.data, loading = _e.loading;
    var question = typeof questionId === "number"
        ? (_c = (_b = data === null || data === void 0 ? void 0 : data.interviewCategories.find(function (category) { return category.id === categoryId; })) === null || _b === void 0 ? void 0 : _b.subCategories.find(function (subCat) { return subCat.id === subCategoryId; })) === null || _c === void 0 ? void 0 : _c.questions.find(function (q) { return q.id === questionId; })
        : undefined;
    var alert = useAlert();
    var modal = useModal();
    var intl = useIntl();
    var _f = useForm({
        defaultValues: { questionTitle: question === null || question === void 0 ? void 0 : question.title },
        resolver: yupResolver(formSchema),
    }), register = _f.register, submit = _f.handleSubmit, _g = _f.formState, isDirty = _g.isDirty, isSubmitting = _g.isSubmitting, isSubmitted = _g.isSubmitted, errors = _g.errors;
    var canSubmit = (!isSubmitting && isDirty) || (isSubmitted && isDirty);
    var handleCompletede = function () {
        onUpdated();
        alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
        modal.close();
    };
    var _h = __read(useCreateInterviewQuestionMutation({
        onCompleted: handleCompletede,
    }), 2), createQuestionMutation = _h[0], createLoading = _h[1].loading;
    var _j = __read(useUpdateInterviewQuestionMutation({
        onCompleted: handleCompletede,
    }), 2), updateInterviewQuestionMutation = _j[0], updateLoading = _j[1].loading;
    var handleSubmit = submit(function (data) {
        if (question === null || question === void 0 ? void 0 : question.id) {
            updateInterviewQuestionMutation({
                variables: {
                    questionId: question.id,
                    subCategoryId: subCategoryId,
                    categoryId: categoryId,
                    title: data.questionTitle || "",
                },
            });
        }
        else {
            createQuestionMutation({
                variables: { subCategoryId: subCategoryId, categoryId: categoryId, title: data.questionTitle || "" },
            });
        }
    });
    return (_jsx(Modal, __assign({ size: "lg" }, { children: _jsxs("div", __assign({ className: "relative" }, { children: [(loading || createLoading || updateLoading) && _jsx(Spinner, {}, void 0), _jsxs("form", __assign({ onSubmit: handleSubmit, className: "flex flex-col h-full", noValidate: true }, { children: [_jsxs("div", __assign({ className: "grow flex flex-col gap-y-5 m-5" }, { children: [_jsx("legend", { children: _jsx("h6", { children: (question === null || question === void 0 ? void 0 : question.id) ? (_jsx(FormattedMessage, { id: "admin.entorsPage.questions.questionModal.edit.title" }, void 0)) : (_jsx(FormattedMessage, { id: "admin.entorsPage.questions.questionModal.new.title" }, void 0)) }, void 0) }, void 0), _jsx(Input, __assign({}, register("questionTitle"), { isRequired: true, error: (_d = errors.questionTitle) === null || _d === void 0 ? void 0 : _d.message, type: "text", label: _jsx(FormattedMessage, { id: "admin.entorsPage.questions.questionModal.field.questionTitle.label" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "m-5 gap-4 flex justify-end mt-auto" }, { children: [_jsx(Button, __assign({ type: "button", onClick: modal.close }, { children: _jsx(FormattedMessage, { id: "common.button.cancel.label" }, void 0) }), void 0), _jsx(Button, __assign({ type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.button.save.label" }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default QuestionModal;
