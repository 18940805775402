var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from "dayjs";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "components/molecules/Header";
import { editorBlocksToMutation, queryToEditorBlocks, } from "components/templates/Editor";
import ClientForm from "components/templates/forms/ClientForm";
import { useAlert } from "contexts/AlertContext";
import { useClientLazyQuery, useCreateClientMutation, useUpdateClientMutation, } from "generated/graphql";
import { DATE_FORMAT_YYYYMMDD } from "utils/constants";
import { ReplaceComponentWithRedirect } from "_AdminApp/routes/redirect";
var formatForCreate = function (clientForm) {
    var _a;
    return (__assign(__assign({}, clientForm), { company: __assign(__assign({}, clientForm.company), { logoImageUrl: ((_a = clientForm.company) === null || _a === void 0 ? void 0 : _a.logoImageUrl) || "" }), contents: clientForm.contents && editorBlocksToMutation(clientForm.contents) }));
};
var formatForUpdate = function (_a) {
    var contents = _a.contents, rest = __rest(_a, ["contents"]);
    return __assign(__assign({}, rest), { contents: contents && editorBlocksToMutation(contents) });
};
var formatFromQuery = function (client) { return ({
    company: __assign(__assign({}, client.company), { prefectureId: client.company.prefectureId, backgroundImageUrl: client.company.backgroundImageUrl, logoImageUrl: client.company.logoImageUrl, presidentName: client.company.presidentName, url: client.company.url, industryId: client.company.industryId, employeesNum: client.company.employeesNum, newGraduateNum: client.company.newGraduateNum, foundationDate: client.company.foundationDate &&
            dayjs(client.company.foundationDate).format(DATE_FORMAT_YYYYMMDD) }),
    contactEmail: client.contactEmail,
    status: client.status,
    contents: queryToEditorBlocks(client.contents),
}); };
// eslint-disable-next-line no-empty-pattern
var ClientView = function (_a) {
    var navigate = useNavigate();
    var id = useParams().id;
    var _b = __read(useClientLazyQuery(), 2), getClient = _b[0], _c = _b[1], data = _c.data, loadingClient = _c.loading;
    useEffect(function () {
        if (id) {
            getClient({ variables: { id: Number(id) } });
        }
    }, [id]);
    var alert = useAlert();
    var intl = useIntl();
    var handleSuccessfulClientChange = function () {
        alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
        client.resetStore();
    };
    var _d = __read(useCreateClientMutation({
        onCompleted: function (data) {
            handleSuccessfulClientChange();
            navigate("../".concat(data === null || data === void 0 ? void 0 : data.createClient.id));
        },
    }), 2), createClientMutation = _d[0], createLoading = _d[1].loading;
    var _e = __read(useUpdateClientMutation({
        onCompleted: function () {
            handleSuccessfulClientChange();
            client.cache.evict({ id: "Client:".concat(id) }); // remove this client from cache
        },
    }), 2), updateClientMutation = _e[0], _f = _e[1], client = _f.client, updateLoading = _f.loading;
    var handleSubmit = function (input) {
        if (id && (data === null || data === void 0 ? void 0 : data.client)) {
            updateClientMutation({ variables: { input: formatForUpdate(input), id: Number(id) } });
        }
        else {
            createClientMutation({ variables: { input: formatForCreate(input) } });
        }
    };
    return (_jsxs("div", __assign({ className: "pt-4 w-full h-full flex flex-col" }, { children: [_jsx(Header, { title: id ? (_jsx(FormattedMessage, { id: "common.form.client.update.title" }, void 0)) : (_jsx(FormattedMessage, { id: "common.form.client.new.title" }, void 0)) }, void 0), _jsx(ClientForm, { onSubmit: handleSubmit, isLoading: createLoading || updateLoading || loadingClient, defaultValues: id ? (data === null || data === void 0 ? void 0 : data.client) && formatFromQuery(data.client) : undefined }, void 0)] }), void 0));
};
var CheckForFlags = function () {
    return (_jsx(ReplaceComponentWithRedirect, __assign({ replace: true }, { children: _jsx(ClientView, {}, void 0) }), void 0));
};
export default CheckForFlags;
