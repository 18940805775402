var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useDebouncedCallback from "hooks/useDebounce";
import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useTable } from "react-table";
import { Spinner } from "components/atoms";
/*
  Table implemented with react-table.
  
  See: https://react-table.tanstack.com/docs/overview
  for potential ways to extend features into it (sorting, filtering, etc...)
*/
var Table = function (_a) {
    var columns = _a.columns, data = _a.data, _b = _a.onScroll, onScroll = _b === void 0 ? function () { } : _b, hasMore = _a.hasMore, loading = _a.loading;
    var parentRef = useRef(null);
    // This is needed to allow InfiniteScroll to get the correct ref
    var _c = __read(useState(null), 2), refState = _c[0], setRefState = _c[1];
    useEffect(function () {
        setRefState(parentRef.current);
    }, []);
    var handleLoadMore = useDebouncedCallback(function () {
        if (hasMore && !loading) {
            onScroll();
        }
    }, 100);
    // Use the state and functions returned from useTable to build your UI
    var _d = useTable({
        columns: columns,
        data: data,
    }), getTableProps = _d.getTableProps, getTableBodyProps = _d.getTableBodyProps, rows = _d.rows, prepareRow = _d.prepareRow, headerGroups = _d.headerGroups;
    var RenderRow = useCallback(function (row) {
        prepareRow(row);
        return (_jsx("tr", __assign({}, row.getRowProps(), { className: "bg-white border-b border-black10 text-black90" }, { children: row.cells.map(function (cell) {
                return (
                // {...cell.getCellProps()} will give it a unique key (not caught by ts)
                // eslint-disable-next-line react/jsx-key
                _jsx("td", __assign({}, cell.getCellProps(), { className: "px-4 py-2 text-size-caption text-left" }, { children: _jsx("div", __assign({ className: "text-size-caption text-black90" }, { children: cell.render("Cell") }), void 0) }), void 0));
            }) }), void 0));
    }, [prepareRow, rows]);
    return (_jsx("div", __assign({ className: "overflow-auto w-full h-75p", ref: parentRef }, { children: _jsx("div", __assign({ className: "w-full h-full bg-white" }, { children: _jsxs("table", __assign({}, getTableProps(), { className: "w-full bg-white" }, { children: [_jsx("thead", __assign({ className: "sticky top-0 w-full h-5 bg-background-grey text-size-caption text-left z-[1]" }, { children: headerGroups.map(function (headerGroup) { return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps(), { key: headerGroup.id + "header" }), headerGroup.headers.map(function (column) { return (_createElement("th", __assign({}, column.getHeaderProps(), { className: "".concat(column.render("Header") && !column.minWidth && "min-w-[60px]", " font-regular p-1 pl-4 text-black50"), key: column.id + "col", 
                            // Useing the width value as a percentage for fixed col sized tables
                            style: {
                                width: column.width && column.width < 100 ? "".concat(column.width, "%") : "auto",
                            } }), column.render("Header"))); }))); }) }), void 0), _jsx(InfiniteScroll, __assign({}, getTableBodyProps(), { threshold: 10, pageStart: 0, initialLoad: false, loadMore: handleLoadMore, hasMore: hasMore, loader: _jsx("tr", __assign({ className: "h-9 bg-white " }, { children: _jsx("td", __assign({ colSpan: columns.length, className: "relative" }, { children: _jsx(Spinner, { color: "bg-primary" }, void 0) }), void 0) }), "loader"), element: "tbody", useWindow: false, getScrollParent: function () { return refState; } }, { children: rows.map(RenderRow) }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default Table;
