var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import dayjs from "dayjs";
import { CheckState, } from "components/templates/forms/EventForm/schema";
import { DoorsOpeningTiming, EmailStatus, EmailTiming, EventHostType, EventStatus, EventType, } from "generated/graphql";
import { DATE_FORMAT_YYYYMMDD, DEFAULT_EMAILS } from "utils/constants";
import { editorBlocksToMutation, queryToEditorBlocks, } from "../../components/templates/Editor";
var getSingleParticipatingClient = function (clientList) {
    if (clientList.length > 0) {
        return clientList[0];
    }
    else {
        return undefined;
    }
};
var datesToMutation = function (dates) {
    return __spreadArray([], __read(dates.map(function (date) {
        var _a;
        var deadlineDate = date.deadlineDate, deadlineTime = date.deadlineTime, startAtDate = date.startAtDate, startAtTime = date.startAtTime, endAtDate = date.endAtDate, endAtTime = date.endAtTime, place = date.place, filteredDate = __rest(date, ["deadlineDate", "deadlineTime", "startAtDate", "startAtTime", "endAtDate", "endAtTime", "place"]);
        var deadline = dayjs(deadlineDate.concat(" ", deadlineTime), "YYYY-MM-DD HH:mm").toISOString();
        var startAt = dayjs(startAtDate.concat(" ", startAtTime), "YYYY-MM-DD HH:mm").toISOString();
        var endAt = dayjs(endAtDate.concat(" ", endAtTime), "YYYY-MM-DD HH:mm").toISOString();
        return __assign(__assign({}, filteredDate), { place: place, doorsOpenAt: (_a = date.doorsOpenAt) !== null && _a !== void 0 ? _a : DoorsOpeningTiming.Before_10Minutes, deadline: deadline, startAt: startAt, endAt: endAt });
    })), false);
};
var emailsToSchema = function (emails, eventType) {
    var timings = eventType === EventType.Entry
        ? [EmailTiming.PostApplication, EmailTiming.PostApplicationCancel]
        : Object.values(EmailTiming);
    return timings.map(function (timing) {
        var _a;
        var body = (_a = emails === null || emails === void 0 ? void 0 : emails.find(function (email) { return email.timing_typed === timing; })) === null || _a === void 0 ? void 0 : _a.body;
        return {
            body: body || DEFAULT_EMAILS[timing],
            timing_typed: timing,
            checkState: DEFAULT_EMAILS[timing] === body
                ? CheckState.Default
                : Boolean(body) && DEFAULT_EMAILS[timing] !== body
                    ? CheckState.Custom
                    : CheckState.Off,
            defaultStatus_typed: EmailStatus.Pending,
        };
    });
};
var queryToSchema = function (queryOutput) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var entryEventDeadline = (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.entryEvent)
        ? dayjs(queryOutput.entryEvent.deadline)
        : undefined;
    return __assign(__assign({}, queryOutput), { eventType: (_a = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.eventType_typed) !== null && _a !== void 0 ? _a : EventType.Entry, title: (_b = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.title) !== null && _b !== void 0 ? _b : "", categoryId: (_c = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.categoryId) !== null && _c !== void 0 ? _c : 1, targetYears: (_d = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.targetYears) !== null && _d !== void 0 ? _d : [], eventHostType_typed: (_e = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.eventHostType_typed) !== null && _e !== void 0 ? _e : undefined, imageUrl: (_f = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.imageUrl) !== null && _f !== void 0 ? _f : "", status: (_g = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.status) !== null && _g !== void 0 ? _g : EventStatus.Draft, jobhuntInfoRequired: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.jobhuntInfoRequired) || false, participatingClients: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.clients)
            ? __spreadArray([], __read(queryOutput.clients.map(function (client) { return client.id; })), false) : undefined, participatingBranches: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.branches)
            ? __spreadArray([], __read(queryOutput.branches.map(function (branch) { return branch.id; })), false) : undefined, participatingSingleClient: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.clients) && (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.eventHostType_typed) === EventHostType.Client
            ? getSingleParticipatingClient(__spreadArray([], __read(queryOutput.clients.map(function (client) { return client.id; })), false))
            : undefined, questions: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.questions)
            ? __spreadArray([], __read(queryOutput.questions.map(function (q) {
                var _a;
                return ({
                    id: String(q.id),
                    title: q.title,
                    label: q.label ? q.label : "",
                    required: Boolean(q.required),
                    options: q.options
                        ? __spreadArray([], __read(q.options.map(function (opt) { return ({
                            label: opt.label ? opt.label : "",
                            id: String(opt.id),
                        }); })), false) : [],
                    wordCount: (_a = q.wordCount) !== null && _a !== void 0 ? _a : undefined,
                    questionType: q.questionType,
                });
            })), false) : [], entryEvent: {
            deadlineDate: entryEventDeadline ? entryEventDeadline.format(DATE_FORMAT_YYYYMMDD) : "",
            deadlineTime: entryEventDeadline ? entryEventDeadline.format("HH:mm") : "",
            limitNum: (_j = (_h = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.entryEvent) === null || _h === void 0 ? void 0 : _h.limitNum) !== null && _j !== void 0 ? _j : undefined,
            accountsRegistered: (_l = (_k = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.entryEvent) === null || _k === void 0 ? void 0 : _k.accountsRegistered) !== null && _l !== void 0 ? _l : undefined,
        }, scheduledEvent: {
            contact: (_o = (_m = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.scheduledEvent) === null || _m === void 0 ? void 0 : _m.contact) !== null && _o !== void 0 ? _o : "",
            dressCode: (_q = (_p = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.scheduledEvent) === null || _p === void 0 ? void 0 : _p.dressCode) !== null && _q !== void 0 ? _q : "",
            belongings: (_s = (_r = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.scheduledEvent) === null || _r === void 0 ? void 0 : _r.belongings) !== null && _s !== void 0 ? _s : "",
            fee: (_u = (_t = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.scheduledEvent) === null || _t === void 0 ? void 0 : _t.fee) !== null && _u !== void 0 ? _u : "",
            dates: ((_v = queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.scheduledEvent) === null || _v === void 0 ? void 0 : _v.dates)
                ? __spreadArray([], __read(queryOutput.scheduledEvent.dates.map(function (date) {
                    var _a, _b;
                    return ({
                        startAtDate: date.startAt && dayjs(date.startAt).format(DATE_FORMAT_YYYYMMDD),
                        startAtTime: date.startAt && dayjs(date.startAt).format("HH:mm"),
                        endAtDate: date.endAt && dayjs(date.endAt).format(DATE_FORMAT_YYYYMMDD),
                        endAtTime: date.endAt && dayjs(date.endAt).format("HH:mm"),
                        deadlineDate: date.deadline && dayjs(date.deadline).format(DATE_FORMAT_YYYYMMDD),
                        deadlineTime: date.deadline && dayjs(date.deadline).format("HH:mm"),
                        doorsOpenAt: (_a = date.doorsOpenAt) !== null && _a !== void 0 ? _a : "",
                        place: {
                            access: date.place.access,
                            prefectureId: date.place.prefecture.id,
                            address1: date.place.address1,
                            address2: date.place.address2,
                            emergencyContact: date.place.emergencyContact,
                        },
                        seatsNum: date.seatsNum,
                        seatsUsed: (_b = date.seatsUsed) !== null && _b !== void 0 ? _b : undefined,
                        reservationNum: date.reservationNum,
                        id: date.id,
                    });
                })), false) : [],
        }, eventApplicationScreen: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.eventApplicationScreen)
            ? queryOutput.eventApplicationScreen
            : {
                hasInterviewed: false,
                cancelledPercentMax: 0,
                attendedEventMin: 0,
            }, targetSchools: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.targetSchools)
            ? __spreadArray([], __read(queryOutput.targetSchools.map(function (targetSchool) { return targetSchool.id; })), false) : [], postApplicationLink: {
            url: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.postApplicationLink) && queryOutput.postApplicationLink.url
                ? queryOutput.postApplicationLink.url
                : undefined,
            description: (queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.postApplicationLink) && queryOutput.postApplicationLink.description
                ? queryOutput.postApplicationLink.description
                : undefined,
        }, contents: queryToEditorBlocks(queryOutput.contents || []), emails: (_w = emailsToSchema(queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.emails, queryOutput === null || queryOutput === void 0 ? void 0 : queryOutput.eventType_typed)) !== null && _w !== void 0 ? _w : [] });
};
var schemaToMutation = function (eventForm) {
    var _a, _b, _c, _d, _e;
    var eventCommonInput = {
        branchIds: (_a = eventForm.participatingBranches) !== null && _a !== void 0 ? _a : undefined,
        categoryId: eventForm.categoryId,
        clientIds: eventForm.eventHostType_typed === EventHostType.Client && eventForm.participatingSingleClient
            ? [eventForm.participatingSingleClient]
            : eventForm.participatingClients,
        contents: eventForm.contents ? editorBlocksToMutation(eventForm.contents) : [],
        emails: eventForm.emails
            ? __spreadArray([], __read(eventForm.emails
                .filter(function (e) { return e.checkState !== CheckState.Off; })
                .map(function (email) { return ({
                body: email.body,
                defaultStatus: email.defaultStatus_typed,
                timing: email.timing_typed,
            }); })), false) : [],
        eventHostType: eventForm.eventHostType_typed,
        imageUrl: eventForm.imageUrl,
        status: eventForm.status,
        jobhuntInfoRequired: Boolean(eventForm.jobhuntInfoRequired),
        targetSchools: eventForm.targetSchools ? __spreadArray([], __read(eventForm.targetSchools), false) : [],
        targetYears: eventForm.targetYears,
        title: eventForm.title,
        questions: eventForm.questions
            ? __spreadArray([], __read(eventForm.questions.map(function (question) { return (__assign(__assign({}, question), { id: Number(question.id), questionType: question.questionType, required: Boolean(question.required), label: question.label ? question.label : "", options: question.options
                    ? __spreadArray([], __read(question.options.map(function (_a) {
                        var label = _a.label, id = _a.id;
                        return ({ label: label ? label : "", id: id });
                    })), false) : [] })); })), false) : [],
        postApplicationLink: eventForm.postApplicationLink,
    };
    if (eventForm.eventType === EventType.Scheduled) {
        return {
            eventCommonInput: eventCommonInput,
            scheduled: {
                staffs: [],
                belongings: (_b = eventForm.scheduledEvent.belongings) !== null && _b !== void 0 ? _b : "",
                contact: (_c = eventForm.scheduledEvent.contact) !== null && _c !== void 0 ? _c : "",
                dates: eventForm.scheduledEvent.dates
                    ? datesToMutation(eventForm.scheduledEvent.dates)
                    : [],
                fee: (_d = eventForm.scheduledEvent.fee) !== null && _d !== void 0 ? _d : "",
                dressCode: (_e = eventForm.scheduledEvent.dressCode) !== null && _e !== void 0 ? _e : "",
            },
        };
    }
    if (eventForm.eventType === EventType.Entry) {
        var _f = eventForm.entryEvent, deadlineDate = _f.deadlineDate, deadlineTime = _f.deadlineTime, rest = __rest(_f, ["deadlineDate", "deadlineTime"]);
        return {
            eventCommonInput: eventCommonInput,
            entry: __assign({ deadline: dayjs(deadlineDate.concat(" ", deadlineTime), "YYYY-MM-DD HH:mm").format() }, rest),
        };
    }
};
export { queryToSchema, schemaToMutation };
