var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import CommonEN from "commonLocale/en.json";
import CommonJA from "commonLocale/ja.json";
import yupLocales from "commonLocale/yup";
import { createContext, useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import { setLocale as setYuplocale } from "yup";
setYuplocale(yupLocales);
var initLocale;
var locale = localStorage.getItem("locale");
if (locale) {
    initLocale = locale;
}
else if (navigator.language.includes("ja")) {
    initLocale = "ja";
}
else {
    initLocale = "en";
}
var localeContext = createContext({
    setLocale: function (locale) { },
    locale: initLocale,
});
var commonMessages = {
    en: CommonEN,
    ja: CommonJA,
};
var IntlWrapper = function (_a) {
    var children = _a.children, messages = _a.messages;
    var _b = __read(useState(initLocale), 2), locale = _b[0], setLocale = _b[1];
    var joinedMessages = {
        en: __assign(__assign({}, commonMessages.en), messages.en),
        ja: __assign(__assign({}, commonMessages.ja), messages.ja),
    };
    return (_jsx(localeContext.Provider, __assign({ value: {
            locale: locale,
            setLocale: function (l) {
                setLocale(l);
                localStorage.setItem("locale", l);
            },
        } }, { children: _jsx(IntlProvider, __assign({ messages: joinedMessages[locale], locale: locale }, { children: children }), void 0) }), void 0));
};
export var useLocale = function () { return useContext(localeContext); };
export default IntlWrapper;
