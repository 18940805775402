var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Button, Input, Modal, Spinner } from "components/atoms";
import EntorQuestionCategorySelect from "components/molecules/FormSelects/SingleSelect/EntorQuestionCategorySelect";
import { useAlert } from "contexts/AlertContext";
import { useModal } from "contexts/ModalContext";
import { useCreateInterviewQuestionSubCatMutation, useInterviewQuestionsQuery, useUpdateInterviewQuestionSubCatMutation, } from "generated/graphql";
var formSchema = yup.object({
    title: yup.string().min(4).required(),
    categoryId: yup.number().required(),
});
var SubCategoryModal = function (_a) {
    var _b, _c, _d;
    var subCategoryId = _a.subCategoryId, categoryId = _a.categoryId, onUpdated = _a.onUpdated;
    var _e = useInterviewQuestionsQuery(), data = _e.data, loading = _e.loading;
    var alert = useAlert();
    var modal = useModal();
    var intl = useIntl();
    var subCategory = typeof subCategoryId === "number"
        ? (_b = data === null || data === void 0 ? void 0 : data.interviewCategories.find(function (category) { return category.id === categoryId; })) === null || _b === void 0 ? void 0 : _b.subCategories.find(function (subCat) { return subCat.id === subCategoryId; })
        : undefined;
    var handleCompleted = function () {
        alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
        onUpdated();
        modal.close();
    };
    var _f = __read(useCreateInterviewQuestionSubCatMutation({
        onCompleted: handleCompleted,
    }), 2), createSubCatMutation = _f[0], createLoading = _f[1].loading;
    var _g = __read(useUpdateInterviewQuestionSubCatMutation({
        onCompleted: handleCompleted,
    }), 2), updateSubCatMutation = _g[0], updateLoading = _g[1].loading;
    var _h = useForm({
        defaultValues: { title: subCategory === null || subCategory === void 0 ? void 0 : subCategory.title, categoryId: categoryId },
        resolver: yupResolver(formSchema),
    }), reset = _h.reset, register = _h.register, submit = _h.handleSubmit, watch = _h.watch, _j = _h.formState, isDirty = _j.isDirty, isSubmitting = _j.isSubmitting, isSubmitted = _j.isSubmitted, errors = _j.errors, setValue = _h.setValue;
    var canSubmit = (!isSubmitting && isDirty) || (isSubmitted && isDirty);
    var isUpdating = subCategoryId && categoryId;
    var handleSubmit = submit(function (data) {
        if (isUpdating) {
            updateSubCatMutation({ variables: { categoryId: categoryId, subCategoryId: subCategoryId, title: data.title || "" } });
        }
        else {
            createSubCatMutation({
                variables: { categoryId: data.categoryId || 0, title: data.title || "" },
            });
        }
        reset(__assign({}, data));
    });
    var handleCategorySelect = function (categoryId) {
        setValue("categoryId", categoryId, { shouldDirty: true });
    };
    return (_jsx(Modal, __assign({ size: "lg" }, { children: _jsxs("div", __assign({ className: "relative" }, { children: [(loading || createLoading || updateLoading) && _jsx(Spinner, {}, void 0), _jsxs("form", __assign({ onSubmit: handleSubmit, className: "flex flex-col h-full", noValidate: true }, { children: [_jsxs("div", __assign({ className: "grow flex flex-col gap-y-5 m-5" }, { children: [_jsx("legend", { children: _jsx("h6", { children: isUpdating ? (_jsx(FormattedMessage, { id: "admin.entorsPage.questions.subCatagoryModal.edit.title" }, void 0)) : (_jsx(FormattedMessage, { id: "admin.entorsPage.questions.subCatagoryModal.new.title" }, void 0)) }, void 0) }, void 0), !isUpdating && (_jsx(EntorQuestionCategorySelect, { isRequired: true, error: (_c = errors.categoryId) === null || _c === void 0 ? void 0 : _c.message, onSelect: handleCategorySelect, selectedValue: watch("categoryId") }, void 0)), _jsx(Input, __assign({}, register("title"), { isRequired: true, error: (_d = errors.title) === null || _d === void 0 ? void 0 : _d.message, type: "text", label: _jsx(FormattedMessage, { id: "admin.entorsPage.questions.subCatagoryModal.field.title.label" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "m-5 gap-4 flex justify-end mt-auto" }, { children: [_jsx(Button, __assign({ type: "button", onClick: modal.close }, { children: _jsx(FormattedMessage, { id: "common.button.cancel.label" }, void 0) }), void 0), _jsx(Button, __assign({ type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.button.save.label" }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default SubCategoryModal;
