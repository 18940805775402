var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import ModalContainer from "contexts/ModalContext/ModalContainer";
var modalContext = createContext({
    show: function (modal, options) { },
    close: function () { },
});
var ProvideModal = function (_a) {
    var children = _a.children;
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var _c = __read(useState(false), 2), disableOutsideClick = _c[0], setDisableOutsideClick = _c[1];
    var _d = __read(useState({ onClose: function () { } }), 2), callbacks = _d[0], setCallbacks = _d[1];
    var _e = __read(useState(undefined), 2), modal = _e[0], setModal = _e[1];
    var handleClose = function () {
        setModal(undefined);
        callbacks.onClose();
    };
    var close = function () {
        setDisableOutsideClick(false);
        setOpen(false);
    };
    var handleOpen = function (modal, options) {
        if (options) {
            setCallbacks({ onClose: options.onClose || (function () { }) });
            setDisableOutsideClick(!!options.disableOutsideClick);
        }
        setModal(modal);
        setOpen(true);
    };
    return (_jsxs(modalContext.Provider, __assign({ value: {
            show: handleOpen,
            close: close,
        } }, { children: [_jsx(ModalContainer, __assign({ open: open, closeModal: close, onClose: handleClose, disableOutsideClick: disableOutsideClick }, { children: modal }), void 0), children] }), void 0));
};
export var useModal = function () { return useContext(modalContext); };
export default ProvideModal;
