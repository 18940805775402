var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from "react-hook-form";
import Editor from "components/templates/Editor";
var Index = function () {
    var _a = useFormContext(), getValues = _a.getValues, setValue = _a.setValue;
    var contents = getValues("contents");
    var handleEdit = function (values) {
        setValue("contents", values, {
            shouldDirty: true,
        });
    };
    return (_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx("div", __assign({ className: "m-5 p-5 w-editor bg-white rounded-md shadow-xl border border-black10" }, { children: _jsx(Editor, { initialValues: contents, onEdit: handleEdit }, void 0) }), void 0) }), void 0));
};
export default Index;
