var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RoleSelect from "components/molecules/FormSelects/SingleSelect/RoleSelect";
import TargetYearSelect from "components/molecules/FormSelects/SingleSelect/TargetYearSelect";
import FilterHeader from "components/templates/FilterHeader";
import NotFoundView from "components/templates/NotFoundView";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import { useListUsersQuery } from "generated/graphql";
import { coreMentorAndAboveRoles } from "utils/constants";
import InviteUserModal from "./InviteUserModal";
import UserTable from "./UserTable";
var PAGE_SIZE = 30;
var getFilters = function (searchParams) { return ({
    mentorName: searchParams.get("mentorName") || undefined,
    branchName: searchParams.get("branchName") || undefined,
    schoolName: searchParams.get("schoolName") || undefined,
    departmentName: searchParams.get("departmentName") || undefined,
    graduationYear: Number(searchParams.get("graduationYear")) || undefined,
    role: searchParams.get("role") || undefined,
}); };
var UserList = function () {
    var _a, _b;
    var _c = __read(useState([
        {
            name: "mentorName",
            label: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.filter.mentorName.label" }, void 0),
        },
        {
            name: "branchName",
            label: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.filter.branchName.label" }, void 0),
        },
        {
            name: "schoolName",
            label: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.filter.highestEdu.label" }, void 0),
        },
        {
            name: "departmentName",
            label: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.filter.departmentName.label" }, void 0),
        },
        {
            name: "graduationYear",
            Component: TargetYearSelect,
            singleSelect: true,
        },
    ]), 2), inputs = _c[0], setInputs = _c[1];
    var modal = useModal();
    var location = useLocation();
    var navigate = useNavigate();
    var roleSet = useAuth().roleSet;
    var withModalOpen = location.pathname.split("/")[2] === "new"; // open modal if path is for new user
    useEffect(function () {
        if (withModalOpen) {
            modal.show(_jsx(InviteUserModal, {}, void 0), { onClose: function () { return navigate("/users"); } });
        }
    }, [withModalOpen]);
    useEffect(function () {
        if (coreMentorAndAboveRoles.some(function (role) { return roleSet.has(role); })) {
            // Only if roles of user and inviteAllowedRoles intersect
            setInputs(__spreadArray(__spreadArray([], __read(inputs), false), [
                {
                    name: "role",
                    Component: RoleSelect,
                    singleSelect: true,
                },
            ], false));
        }
    }, []);
    var _d = __read(useSearchParams(), 2), searchParams = _d[0], setSearchParams = _d[1];
    var initialFilters = useMemo(function () { return getFilters(searchParams); }, []);
    var _e = useListUsersQuery({
        variables: __assign(__assign({}, initialFilters), { roles: initialFilters.role ? [initialFilters.role] : undefined, first: PAGE_SIZE }),
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true, // this will trigger a loading state on refetch
    }), data = _e.data, error = _e.error, refetch = _e.refetch, loading = _e.loading, fetchMore = _e.fetchMore;
    var users = (_a = data === null || data === void 0 ? void 0 : data.users) === null || _a === void 0 ? void 0 : _a.edges.node;
    var pageInfo = (_b = data === null || data === void 0 ? void 0 : data.users) === null || _b === void 0 ? void 0 : _b.pageInfo;
    var handleFilter = function (filters) {
        setSearchParams(filters);
        refetch(__assign(__assign({ 
            // TODO: add filter object for this query
            /*
              Apollo uses previous values for omitted variables in refetch({ ...variables }),
              so we need to remove them.
      
              Fix: BE needs to return a filter object type for the query (see client list)
            */
            after: undefined, offset: undefined, userTypes: undefined, branchName: undefined, departmentName: undefined, mentorName: undefined, schoolName: undefined }, filters), { graduationYear: filters.graduationYear ? Number(filters.graduationYear) : undefined, roles: filters.role ? [filters.role] : undefined, first: PAGE_SIZE }));
    };
    var handleScroll = function () {
        if (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) {
            var filters = getFilters(searchParams);
            fetchMore({
                variables: __assign(__assign({}, filters), { roles: filters.role ? [filters.role] : undefined, first: PAGE_SIZE, after: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor }),
            });
        }
    };
    return error ? (_jsx(NotFoundView, {}, void 0)) : (_jsxs("div", __assign({ className: "w-full overflow-y-scroll flex flex-col mt-4" }, { children: [_jsx(FilterHeader, { title: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.title" }, void 0), initialFilters: initialFilters, inputs: inputs, onFilter: handleFilter, actionBtn: 
                // Only if roles of user and inviteAllowedRoles intersect
                coreMentorAndAboveRoles.some(function (role) { return roleSet.has(role); })
                    ? {
                        label: _jsx(FormattedMessage, { id: "admin.usersPage.tableHeader.actionBtn.label" }, void 0),
                        onClick: function () { return modal.show(_jsx(InviteUserModal, {}, void 0)); },
                    }
                    : undefined }, void 0), _jsx(UserTable, { users: users || [], loading: loading, hasMore: !!(pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage), onScroll: handleScroll }, void 0)] }), void 0));
};
export default UserList;
