var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Icon } from "components/atoms";
import { useAuth } from "contexts/AuthContext";
import { RoleName, useGetEventDateEntorsCsvLazyQuery, useGetEventEntorsCsvLazyQuery, } from "generated/graphql";
import DownloadLink from "./DownloadLink";
import EntryEventEntorInfo from "./EntryEventEntorInfo";
import ScheduledEventDateEntorInfo from "./ScheduledEventDateEntorInfo";
import ScheduledEventDatesInfo from "./ScheduledEventDatesInfo";
export var InfoHeaderType;
(function (InfoHeaderType) {
    InfoHeaderType["EntryEventEntors"] = "entryEventEntors";
    InfoHeaderType["ScheduledEventDates"] = "scheduledEventEntors";
    InfoHeaderType["ScheduledEventDateEntors"] = "scheduledEventDateEntors";
})(InfoHeaderType || (InfoHeaderType = {}));
var InfoHeader = function (props) {
    var roleSet = useAuth().roleSet;
    var navigateTo = useNavigate();
    // For download link
    var _a = __read(useGetEventEntorsCsvLazyQuery({
        fetchPolicy: "cache-first",
        onCompleted: function (data) {
            var _a;
            if ((_a = data.getEventEntorsCsv) === null || _a === void 0 ? void 0 : _a.url) {
                window.open(data.getEventEntorsCsv.url);
            }
        },
    }), 2), getEventEntorsCsvLazyQuery = _a[0], isEventEntorsCsvLoading = _a[1].loading;
    // For download link
    var _b = __read(useGetEventDateEntorsCsvLazyQuery({
        fetchPolicy: "cache-first",
        onCompleted: function (data) {
            var _a;
            if ((_a = data.getEventDateEntorsCsv) === null || _a === void 0 ? void 0 : _a.url) {
                window.open(data.getEventDateEntorsCsv.url);
            }
        },
    }), 2), getEventDateEntorsCsvLazyQuery = _b[0], isEventDateEntorsCsvLoading = _b[1].loading;
    var handleDownload = function () {
        if (props.type === InfoHeaderType.ScheduledEventDateEntors) {
            var parsedId = Number(props.dateId);
            if (parsedId) {
                getEventDateEntorsCsvLazyQuery({
                    variables: {
                        id: parsedId,
                    },
                });
            }
        }
        else {
            getEventEntorsCsvLazyQuery({
                variables: {
                    hash: props.eventHash,
                },
            });
        }
    };
    var handleScheduledEventNavigation = function () {
        if (props.type === InfoHeaderType.ScheduledEventDateEntors) {
            navigateTo("/events/".concat(props.id, "/dates"));
        }
        else {
            navigateTo("/events");
        }
    };
    return (_jsxs("div", __assign({ className: "relative px-6 flex align-center gap-6 bg-white text-size-caption justify-between" }, { children: [_jsx("div", __assign({ className: "absolute rounded-full bg-white -left-floating-button" }, { children: _jsx("button", __assign({ onClick: handleScheduledEventNavigation, className: "flex justify-center items-center focus:outline-none outline-none rounded-full w-6 h-6 px-0 font-bold text-primary bg-transparent border border-black10 hover:text-primary55 shadow-a text-size-h5" }, { children: _jsx(Icon, { name: "ArrowLeft", className: "flex justify-center" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "flex align-center h-6" }, { children: props.type === InfoHeaderType.ScheduledEventDateEntors ? (_jsx(ScheduledEventDateEntorInfo, { id: props.id, date: props.date || "", location: props.location || "", count: "".concat(props.numApplied, "/").concat(props.reservationNum) }, void 0)) : props.type === InfoHeaderType.EntryEventEntors ? (_jsx(EntryEventEntorInfo, { title: props.title, branches: props.branches || [], participantCount: props.totalParticipants }, void 0)) : (_jsx(ScheduledEventDatesInfo, { id: props.id, title: props.title, branches: props.branches || [] }, void 0)) }), void 0), (roleSet.has(RoleName.Admin) ||
                roleSet.has(RoleName.HeadQuarter) ||
                roleSet.has(RoleName.BranchManager)) &&
                props.totalParticipants > 0 &&
                (props.type === InfoHeaderType.ScheduledEventDateEntors ? (_jsx(DownloadLink, __assign({ onDownload: handleDownload, isLoading: isEventDateEntorsCsvLoading }, { children: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.infoHeader.dateEntorDownloadLink.label" }, void 0) }), void 0)) : (_jsx(DownloadLink, __assign({ onDownload: handleDownload, isLoading: isEventEntorsCsvLoading }, { children: _jsx(FormattedMessage, { id: "admin.eventEntorsPage.infoHeader.downloadLink.label" }, void 0) }), void 0)))] }), void 0));
};
export default InfoHeader;
