var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { userGenderLabel } from "commonLocale/typeLabels";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { UserGender } from "generated/graphql";
var GenderSelect = function (props) {
    var intl = useIntl();
    var GENDER_OPTIONS = [
        {
            id: UserGender.Male,
            label: intl.formatMessage({ id: userGenderLabel["male"] }),
            value: UserGender.Male,
        },
        {
            id: UserGender.Female,
            label: intl.formatMessage({ id: userGenderLabel["female"] }),
            value: UserGender.Female,
        },
        {
            id: UserGender.Other,
            label: intl.formatMessage({ id: userGenderLabel["other"] }),
            value: UserGender.Other,
        },
        {
            id: UserGender.Unspecified,
            label: intl.formatMessage({ id: userGenderLabel["unspecified"] }),
            value: UserGender.Unspecified,
        },
    ];
    return (_jsx(SingleSelect, __assign({}, props, { options: GENDER_OPTIONS, name: "gender", label: intl.formatMessage({ id: "common.mypage.profile.gender.label" }) }), void 0));
};
export default GenderSelect;
