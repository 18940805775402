import { RoleName } from "generated/graphql";
export var FALLBACK_STRING = "-";
export var SEARCH_LIMIT = 7;
export var DATE_FORMAT_YYYYMMDD = "YYYY-MM-DD";
export var DATETIME_FORMAT_YYYYMMDD = "YYYY-MM-DDTHH:mm";
export var EVENT_HOST_TYPES;
(function (EVENT_HOST_TYPES) {
    EVENT_HOST_TYPES[EVENT_HOST_TYPES["selection"] = 3] = "selection";
    EVENT_HOST_TYPES[EVENT_HOST_TYPES["seminar"] = 4] = "seminar";
    EVENT_HOST_TYPES[EVENT_HOST_TYPES["intern"] = 5] = "intern";
    EVENT_HOST_TYPES[EVENT_HOST_TYPES["dinner"] = 6] = "dinner";
    EVENT_HOST_TYPES[EVENT_HOST_TYPES["branch"] = 2] = "branch";
    EVENT_HOST_TYPES[EVENT_HOST_TYPES["headquarter"] = 1] = "headquarter";
})(EVENT_HOST_TYPES || (EVENT_HOST_TYPES = {}));
export var SEGMENT_QUESTION_TYPES;
(function (SEGMENT_QUESTION_TYPES) {
    SEGMENT_QUESTION_TYPES["INTERN_EVALUATION"] = "intern_evaluation";
    SEGMENT_QUESTION_TYPES["CAREER_DESIGN"] = "career_design";
    SEGMENT_QUESTION_TYPES["OUTSTANDING_EVALUATION"] = "outstanding_evaluation";
    SEGMENT_QUESTION_TYPES["CHARACTER"] = "character";
    SEGMENT_QUESTION_TYPES["SEGMENTATION"] = "segmentation";
})(SEGMENT_QUESTION_TYPES || (SEGMENT_QUESTION_TYPES = {}));
export var EntryEventEmailTiming;
(function (EntryEventEmailTiming) {
    EntryEventEmailTiming["PostApplication"] = "post_application";
    EntryEventEmailTiming["PostApplicationCancel"] = "post_application_cancel";
})(EntryEventEmailTiming || (EntryEventEmailTiming = {}));
export var DATE_FORMAT_YYYYMMDDHM = "YYYY-MM-DD H:mm";
var POST_APP_EMAIL_TEXT = "エンカレッジ運営事務局です。\n" +
    "\n" +
    "{eventName}の申し込みが完了しました。\n" +
    "\n" +
    "以下が、イベントの詳細です。\n" +
    "\n" +
    "{eventName}\n" +
    "開始時間：{eventDate}\n" +
    "受付時刻：{doorsOpenAt}\n" +
    "申込んだ日時：{appliedDate}\n" +
    "開催地：{address}\n" +
    "服装：{dressCode}\n" +
    "持ち物：{belongings}\n" +
    "当日連絡先：{contactPhoneNumber}\n" +
    "\n" +
    "イベントの詳細は、以下から確認することができます。\n" +
    "{eventURL}\n" +
    "\n" +
    "不明点があれば、以下のLINE@から、\n" +
    "お気軽にお問い合わせください。\n" +
    "{lineURL}\n" +
    "\n" +
    "なおキャンセルデータは、データベースに記録されております。\n" +
    "\n" +
    "3日前以前のキャンセル 0pt\n" +
    "3日前のキャンセル -1pt\n" +
    "2日前のキャンセル -2pt\n" +
    "1日前のキャンセル -3pt\n" +
    "当日のキャンセル -4pt\n" +
    "無断欠席 -10pt\n" +
    "\n" +
    "参加申し込み、ありがとうございました！\n" +
    "当日お会いできるのを楽しみにしております。";
var POST_APP_CANCEL_EMAIL_TEXT = "エンカレッジ運営事務局です。 \n" +
    "\n" +
    "{eventName}の申し込みをキャンセルしました。 \n" +
    "\n" +
    "再度エントリーをする場合は、以下からエントリーしてください。\n" +
    "{eventURL} \n" +
    "\n" +
    "不明点があれば、以下のLINE@から、\n" +
    "お気軽にお問い合わせください。\n" +
    "{lineURL} \n" +
    "\n" +
    "引き続き、エンカレッジをよろしくお願いします。";
var POST_APP_ENTRY_EVENT_EMAIL_TEXT = "エンカレッジ運営事務局です。\n" +
    "\n" +
    "{eventName}の申し込みが完了しました。\n" +
    "\n" +
    "イベントの詳細は、以下から確認することができます。\n" +
    "{eventURL}\n" +
    "\n" +
    "不明点があれば、以下のLINE@から、\n" +
    "お気軽にお問い合わせください。\n" +
    "{lineURL}\n" +
    "\n" +
    "なおキャンセルデータは、データベースに記録されております。\n" +
    "\n" +
    "3日前以前のキャンセル 0pt\n" +
    "3日前のキャンセル -1pt\n" +
    "2日前のキャンセル -2pt\n" +
    "1日前のキャンセル -3pt\n" +
    "当日のキャンセル -4pt\n" +
    "無断欠席 -10pt\n" +
    "\n" +
    "参加申し込み、ありがとうございました！\n" +
    "当日お会いできるのを楽しみにしております。";
var getReminderEmail = function (days) {
    return "エンカレッジ運営事務局です。\n" +
        "\n" +
        "{eventName}\u304C".concat(days, "\u65E5\u5F8C\u306B\u958B\u50AC\u3055\u308C\u307E\u3059\u3002\n") +
        "\n" +
        "以下が、イベントの詳細です。\n" +
        "\n" +
        "{eventName}\n" +
        "開始時間：{eventDate}\n" +
        "受付時刻：{doorsOpenAt}\n" +
        "申込んだ日時：{appliedDate}\n" +
        "開催地：{address}\n" +
        "服装：{dressCode}\n" +
        "持ち物：{belongings}\n" +
        "当日連絡先：{contactPhoneNumber}\n" +
        "\n" +
        "イベントの詳細は、以下から確認することができます。\n" +
        "※イベントのキャンセルも以下から可能です。\n" +
        "{eventURL}\n" +
        "\n" +
        "不明点があれば、以下のLINE@から、\n" +
        "お気軽にお問い合わせください。\n" +
        "{lineURL}\n" +
        "\n" +
        "なおキャンセルデータは、データベースに記録されております。\n" +
        "\n" +
        "3日前以前のキャンセル 0pt\n" +
        "3日前のキャンセル -1pt\n" +
        "2日前のキャンセル -2pt\n" +
        "1日前のキャンセル -3pt\n" +
        "当日のキャンセル -4pt\n" +
        "無断欠席 -10pt\n" +
        "\n" +
        "※今後のイベントにおいて優先的に\n" +
        "ご案内ができなくなる可能性がございますのでご留意くださいませ。\n" +
        "\n" +
        "参加申し込み、ありがとうございました！\n" +
        "当日お会いできるのを楽しみにしております。";
};
export var DEFAULT_EMAILS = {
    post_application: POST_APP_EMAIL_TEXT,
    post_application_cancel: POST_APP_CANCEL_EMAIL_TEXT,
    reminder_1: getReminderEmail(1),
    reminder_3: getReminderEmail(3),
    reminder_7: getReminderEmail(7),
    reminder_14: getReminderEmail(14),
};
export var DEFAULT_ENTRY_EVENT_EMAILS = {
    post_application: POST_APP_ENTRY_EVENT_EMAIL_TEXT,
    post_application_cancel: POST_APP_CANCEL_EMAIL_TEXT,
};
// roles in the array below can perform user invite, event delete & copy allowed (more to be added accordingly)
export var coreMentorAndAboveRoles = [
    RoleName.Admin,
    RoleName.HeadQuarter,
    RoleName.CoreMentor,
    RoleName.BranchManager,
];
