var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext, } from "react-router-dom";
// TODO: remove this file once it is added to the latest version of react-router-dom: https://github.com/remix-run/react-router/issues/8139
/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
var useBlocker = function (blocker, when) {
    if (when === void 0) { when = true; }
    var navigator = useContext(NavigationContext).navigator;
    useEffect(function () {
        if (!when) {
            return;
        }
        var unblock = navigator.block(function (tx) {
            var autoUnblockingTx = __assign(__assign({}, tx), { retry: function () {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                } });
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
};
/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
var useNavPrompt = function (message, when) {
    if (when === void 0) { when = true; }
    var blocker = useCallback(function (tx) {
        var response = window.confirm(message);
        if (response) {
            tx.retry();
        }
    }, [message]);
    return useBlocker(blocker, when);
};
export default useNavPrompt;
