var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
var AreaSelect = function (props) {
    var intl = useIntl();
    var AREA_OPTIONS = [
        {
            id: "hokkaido",
            label: intl.formatMessage({ id: "common.area.hokkaido" }),
            value: "hokkaido",
        },
        {
            id: "tohoku",
            label: intl.formatMessage({ id: "common.area.tohoku" }),
            value: "tohoku",
        },
        {
            id: "kanto",
            label: intl.formatMessage({ id: "common.area.kanto" }),
            value: "kanto",
        },
        {
            id: "hokushinetsu",
            label: intl.formatMessage({ id: "common.area.hokushinetsu" }),
            value: "hokushinetsu",
        },
        {
            id: "tokai",
            label: intl.formatMessage({ id: "common.area.tokai" }),
            value: "tokai",
        },
        {
            id: "kansai",
            label: intl.formatMessage({ id: "common.area.kansai" }),
            value: "kansai",
        },
        {
            id: "chugoku",
            label: intl.formatMessage({ id: "common.area.chugoku" }),
            value: "chugoku",
        },
        {
            id: "shikoku",
            label: intl.formatMessage({ id: "common.area.shikoku" }),
            value: "shikoku",
        },
        {
            id: "kyushu",
            label: intl.formatMessage({ id: "common.area.kyushu" }),
            value: "kyushu",
        },
    ];
    return (_jsx(SingleSelect, __assign({}, props, { label: intl.formatMessage({ id: "common.form.field.singleSelect.area.label" }), options: AREA_OPTIONS, name: "area-select", isClearable: true }), void 0));
};
export default AreaSelect;
