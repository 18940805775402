var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { EventHostType } from "generated/graphql";
import { EVENT_HOST_TYPES } from "utils/constants";
var EventClassSelect = function (_a) {
    var eventHostType = _a.eventHostType, rest = __rest(_a, ["eventHostType"]);
    var intl = useIntl();
    var CLIENT_TYPE_OPTIONS = [
        {
            id: "client_selection",
            label: intl.formatMessage({ id: "common.clientEventClassType.selection" }),
            value: EVENT_HOST_TYPES.selection,
        },
        {
            id: "client_seminar",
            label: intl.formatMessage({ id: "common.clientEventClassType.info" }),
            value: EVENT_HOST_TYPES.seminar,
        },
        {
            id: "client_intern",
            label: intl.formatMessage({ id: "common.clientEventClassType.intern" }),
            value: EVENT_HOST_TYPES.intern,
        },
        {
            id: "client_dinner",
            label: intl.formatMessage({ id: "common.clientEventClassType.dinner" }),
            value: EVENT_HOST_TYPES.dinner,
        },
    ];
    var HEAD_QUARTER_TYPE_OPTIONS = [
        {
            id: "hq",
            label: intl.formatMessage({ id: "common.HQEventClassType.headquarter" }),
            value: EVENT_HOST_TYPES.headquarter,
        },
        {
            id: "selection",
            label: intl.formatMessage({ id: "common.HQEventClassType.selection" }),
            value: EVENT_HOST_TYPES.selection,
        },
        {
            id: "seminar",
            label: intl.formatMessage({ id: "common.HQEventClassType.seminar" }),
            value: EVENT_HOST_TYPES.seminar,
        },
        {
            id: "intern",
            label: intl.formatMessage({ id: "common.HQEventClassType.intern" }),
            value: EVENT_HOST_TYPES.intern,
        },
        {
            id: "dinner",
            label: intl.formatMessage({ id: "common.HQEventClassType.dinner" }),
            value: EVENT_HOST_TYPES.dinner,
        },
    ];
    var BRANCH_TYPE_OPTIONS = [
        {
            id: "branch",
            label: intl.formatMessage({ id: "common.branchEventClassType.branch" }),
            value: EVENT_HOST_TYPES.branch,
        },
    ];
    var options = eventHostType === EventHostType.Branch
        ? BRANCH_TYPE_OPTIONS
        : eventHostType === EventHostType.Client
            ? CLIENT_TYPE_OPTIONS
            : HEAD_QUARTER_TYPE_OPTIONS;
    return (_jsx(SingleSelect, __assign({}, rest, { options: options, isClearable: false, name: "event-class", label: intl.formatMessage({ id: "common.form.field.singleSelect.eventClassType.label" }) }), void 0));
};
export default EventClassSelect;
