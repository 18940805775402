var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "components/atoms";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { BranchStatus } from "generated/graphql";
var branchStatusLabel = (_a = {},
    _a[BranchStatus.Hidden] = "common.status.hidden",
    _a[BranchStatus.Suspended] = "common.status.suspended",
    _a[BranchStatus.Visible] = "common.status.visible",
    _a);
var formatSchools = function (schools) {
    return schools.map(function (school) { return "".concat(school.name); }).join("、") || "";
};
var BranchesTable = function (_a) {
    var branches = _a.branches, onEditBranch = _a.onEditBranch;
    var COLUMNS = useMemo(function () { return [
        {
            Header: _jsx(FormattedMessage, { id: "admin.branchesPage.table.column.branchName.label" }, void 0),
            Cell: function (_a) {
                var value = _a.value;
                return _jsx("div", __assign({ className: "font-bold" }, { children: value }), void 0);
            },
            accessor: "name",
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.branchesPage.table.column.schoolNames.label" }, void 0),
            accessor: "schools",
            Cell: function (_a) {
                var value = _a.value;
                return formatSchools(value);
            },
        },
        {
            Header: _jsx(FormattedMessage, { id: "admin.branchesPage.table.column.status.label" }, void 0),
            accessor: "status_typed",
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx(FormattedMessage, { id: branchStatusLabel[value] || "common.status.hidden" }, void 0));
            },
        },
        {
            Header: false,
            accessor: "id",
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx(Icon, { name: "EditorPen", className: "cursor-pointer text-size-h5", onClick: function () { return onEditBranch(value); } }, void 0));
            },
        },
    ]; }, []);
    return branches && (branches === null || branches === void 0 ? void 0 : branches.length) === 0 ? (_jsx(NoResult, {}, void 0)) : (_jsx(Table, { columns: COLUMNS, data: branches }, void 0));
};
export default BranchesTable;
