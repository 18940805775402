var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-no-target-blank */
import { yupResolver } from "@hookform/resolvers/yup";
import useNavPrompt from "hooks/useNavPromt";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { Button, Spinner } from "components/atoms";
import MultiTab from "components/molecules/MultiTab";
import PublishBar from "components/molecules/PublishBar";
import { ClientStatus } from "generated/graphql";
import ClientDetails from "./ClientDetails";
import clientSchema from "./schema";
var CLIENT_FORM_TABS;
(function (CLIENT_FORM_TABS) {
    CLIENT_FORM_TABS[CLIENT_FORM_TABS["BasicInfo"] = 0] = "BasicInfo";
    CLIENT_FORM_TABS[CLIENT_FORM_TABS["Details"] = 1] = "Details";
})(CLIENT_FORM_TABS || (CLIENT_FORM_TABS = {}));
var PUBLISH_STATUS_OPTIONS = [
    {
        value: ClientStatus.Visible,
        label: _jsx(FormattedMessage, { id: "common.form.client.publishBar.status.visible.label" }, void 0),
    },
    {
        value: ClientStatus.Hidden,
        label: _jsx(FormattedMessage, { id: "common.form.client.publishBar.status.hidden.label" }, void 0),
    },
    {
        value: ClientStatus.NoIndex,
        label: _jsx(FormattedMessage, { id: "common.form.client.publishBar.status.noIndex.label" }, void 0),
    },
];
var ClientForm = function (_a) {
    var onSubmit = _a.onSubmit, isLoading = _a.isLoading, restricted = _a.restricted, defaultValues = _a.defaultValues;
    var id = useParams().id;
    var _b = __read(useState(CLIENT_FORM_TABS.BasicInfo), 2), selectedTab = _b[0], setSelectedTab = _b[1];
    useEffect(function () {
        setSelectedTab(CLIENT_FORM_TABS.BasicInfo); // reset tabs when default data changes
    }, [defaultValues]);
    var initValues = useMemo(function () {
        return clientSchema.cast(__assign({ status: ClientStatus.Hidden }, defaultValues), { stripUnknown: true });
    }, [defaultValues]);
    var formMethods = useForm({
        resolver: yupResolver(clientSchema),
        defaultValues: initValues,
    });
    var handleSubmit = formMethods.handleSubmit, _c = formMethods.formState, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting, isSubmitted = _c.isSubmitted;
    useEffect(function () {
        formMethods.reset(initValues);
    }, [initValues]);
    var tabs = [
        {
            index: CLIENT_FORM_TABS.BasicInfo,
            label: _jsx(FormattedMessage, { id: "common.form.client.tabs.basic.label" }, void 0),
        },
        {
            index: CLIENT_FORM_TABS.Details,
            label: _jsx(FormattedMessage, { id: "common.form.client.tabs.details.label" }, void 0),
        },
    ];
    var canSubmit = (!isSubmitting && isDirty) || (isSubmitted && isDirty);
    useNavPrompt("Are you sure you want to leave?", isDirty);
    var isCreating = !id;
    var loading = isLoading;
    var ssoOrgURL = "".concat(process.env.SSO_URL, "organizations");
    return (_jsx(FormProvider, __assign({}, formMethods, { children: _jsxs("form", __assign({ className: "flex flex-col h-full justify-between overflow-hidden bg-white relative", onSubmit: handleSubmit(onSubmit), noValidate: true }, { children: [loading && (_jsx("div", __assign({ className: "absolute h-full w-full" }, { children: _jsx(Spinner, { color: "bg-primary" }, void 0) }), void 0)), _jsxs("div", __assign({ className: "overflow-auto h-full" }, { children: [!isCreating && (_jsx(MultiTab, { tabs: tabs, selectedTabIndex: selectedTab, onTabClick: setSelectedTab }, void 0)), selectedTab === CLIENT_FORM_TABS.BasicInfo && (_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx("a", __assign({ className: "p-10", target: "_blank", href: isCreating ? "".concat(ssoOrgURL, "/new") : ssoOrgURL }, { children: _jsx(Button, __assign({ type: "button" }, { children: isCreating ? (_jsx(FormattedMessage, { id: "admin.clientPage.basicInfo.button.create.label" }, void 0)) : (_jsx(FormattedMessage, { id: "admin.clientPage.basicInfo.button.edit.label" }, void 0)) }), void 0) }), void 0) }), void 0)), selectedTab === CLIENT_FORM_TABS.Details && _jsx(ClientDetails, {}, void 0)] }), void 0), _jsx(PublishBar, { publishStatuses: !restricted ? PUBLISH_STATUS_OPTIONS : [], disabled: !canSubmit }, void 0)] }), void 0) }), void 0));
};
export default ClientForm;
