import { useEffect } from "react";
/**
 * Alerts clicks outside of the passed ref
 */
var onOutsideClick = function (ref, callBack) {
    useEffect(function () {
        // if clicked on outside of element
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callBack();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};
export default onOutsideClick;
