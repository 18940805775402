var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import errorImg from "assets/images/404.png";
import { Button } from "components/atoms";
var NotFoundView = function (_a) {
    var withGoToLogin = _a.withGoToLogin, unauthorized = _a.unauthorized;
    var navigate = useNavigate();
    return (_jsx("div", __assign({ className: "flex h-screen w-screen bg-background-grey" }, { children: _jsx("div", __assign({ className: "flex-1 flex w-content" }, { children: _jsx("div", __assign({ className: "h-full w-full flex flex-col justify-center items-center" }, { children: _jsx("div", __assign({ className: "flex flex-col items-center w-single-asset" }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center justify-center text-center" }, { children: [_jsx("div", __assign({ className: "mb-4 items-center justify-center flex" }, { children: _jsx("img", { className: "w-3/5", src: errorImg, alt: "404" }, void 0) }), void 0), _jsx("div", __assign({ className: "mb-2 text-black50 font-bold text-h6" }, { children: unauthorized ? (_jsx(FormattedMessage, { id: "common.page.unauthorized.title" }, void 0)) : (_jsx(FormattedMessage, { id: "common.page.notfound.title" }, void 0)) }), void 0), _jsxs("div", __assign({ className: "mb-5 text-black50 text-small font-regular" }, { children: [_jsx("p", { children: unauthorized ? (_jsx(FormattedMessage, { id: "common.page.unauthorized.subtitle" }, void 0)) : (_jsx(FormattedMessage, { id: "common.page.notfound.subtitle-1" }, void 0)) }, void 0), !unauthorized && (_jsx("p", { children: _jsx(FormattedMessage, { id: "common.page.notfound.subtitle-2" }, void 0) }, void 0))] }), void 0), withGoToLogin && (_jsx(Button, __assign({ onClick: function () { return navigate("login"); } }, { children: _jsx(FormattedMessage, { id: "common.page.notfound.button.gotoLogin.label" }, void 0) }), void 0))] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0));
};
export default NotFoundView;
