var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
var Button = function (_a) {
    var children = _a.children, fullwidth = _a.fullwidth, fullheight = _a.fullheight, inverted = _a.inverted, className = _a.className, rest = __rest(_a, ["children", "fullwidth", "fullheight", "inverted", "className"]);
    return (_jsx("button", __assign({ className: classNames("flex justify-center items-center focus:outline-none outline-none " +
            "rounded-md h-6 px-4 font-bold text-white bg-primary shadow-b " +
            "hover:bg-primary55 hover:shadow-c text-small leading-small text-font ".concat(className), {
            "w-full": fullwidth,
        }, {
            "h-full": fullheight,
        }, {
            "bg-white border border-black10 text-primary hover:bg-white hover:text-primary55": inverted && !rest.disabled,
        }, {
            "bg-black10 cursor-not-allowed hover:bg-black10": rest.disabled,
        }) }, rest, { children: children }), void 0));
};
export default Button;
