var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "components/atoms";
import Table from "components/templates/Table";
import { useModal } from "contexts/ModalContext";
var QuestionsTable = function (_a) {
    var questions = _a.questions, onEditQuestion = _a.onEditQuestion;
    var modal = useModal();
    var COLUMNS = useMemo(function () { return [
        {
            Header: _jsx(FormattedMessage, { id: "admin.entorsPage.questions.table.title" }, void 0),
            accessor: "title",
            width: 90,
        },
        {
            Header: false,
            accessor: "id",
            Cell: function (_a) {
                var id = _a.value;
                return (_jsx(Icon, { name: "EditorPen", className: "cursor-pointer text-size-h5", onClick: function () { return onEditQuestion(id); } }, void 0));
            },
        },
    ]; }, []);
    return (_jsxs("div", { children: [_jsx(Table, { data: questions, columns: COLUMNS }, void 0), _jsx("div", __assign({ className: "hover:bg-primary10 cursor-pointer", onClick: function () { return onEditQuestion(); } }, { children: _jsx(Icon, { name: "PlusCircle", className: "flex text-size-h5 p-2 justify-center" }, void 0) }), void 0)] }, void 0));
};
export default QuestionsTable;
