var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { EventType } from "generated/graphql";
var EventTypeSelect = function (props) {
    var intl = useIntl();
    var EVENT_TYPE_OPTIONS = [
        {
            id: EventType.Scheduled,
            label: intl.formatMessage({ id: "common.eventType.scheduled" }),
            value: EventType.Scheduled,
        },
        {
            id: EventType.Entry,
            label: intl.formatMessage({ id: "common.eventType.entry" }),
            value: EventType.Entry,
        },
    ];
    return (_jsx(SingleSelect, __assign({}, props, { options: EVENT_TYPE_OPTIONS, name: "target-year", label: intl.formatMessage({ id: "common.form.field.singleSelect.eventType.label" }) }), void 0));
};
export default EventTypeSelect;
