var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import React from "react";
import { useIntl } from "react-intl";
import Label from "./Label";
var TextArea = React.forwardRef(function (_a, refs) {
    var label = _a.label, error = _a.error, isRequired = _a.isRequired, autocomplete = _a.autocomplete, disabled = _a.disabled, name = _a.name, description = _a.description, className = _a.className, value = _a.value, _b = _a.rows, rows = _b === void 0 ? 0 : _b, rest = __rest(_a, ["label", "error", "isRequired", "autocomplete", "disabled", "name", "description", "className", "value", "rows"]);
    var intl = useIntl();
    var errorMsg = typeof error === "object" && (error === null || error === void 0 ? void 0 : error.locale_id)
        ? intl.formatMessage({ id: error === null || error === void 0 ? void 0 : error.locale_id }, __assign({}, error === null || error === void 0 ? void 0 : error.locale_value))
        : error;
    var textRowCount = value ? value.split("\n").length + 1 : rows;
    return (_jsx("div", __assign({ className: classNames(className, "w-full flex flex-col justify-center") }, { children: _jsxs("div", __assign({ className: "" }, { children: [_jsx(Label, __assign({ htmlFor: name, className: classNames("py-1items-center text-small cursor-text", {
                        "text-black25 cursor-not-allowed": disabled,
                    }), isRequired: !!isRequired }, { children: label }), void 0), _jsx("textarea", __assign({}, rest, { rows: textRowCount, value: value, name: name, ref: refs, id: name, disabled: disabled, className: classNames("py-1 bg-transparent h-auto border-b text-black90 w-full text-small outline-none focus:border-primary h-6", { "!border-error": error }, { "text-black25 cursor-not-allowed overflow-hidden": disabled }), autoComplete: autocomplete }), void 0), ((errorMsg && typeof errorMsg === "string") || description) && (_jsx("p", __assign({ className: classNames("relative h-3 text-caption leading-caption", {
                        "text-error": errorMsg,
                    }) }, { children: errorMsg || description }), void 0))] }), void 0) }), void 0));
});
TextArea.displayName = "TextArea";
export default TextArea;
