var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { EventStatus } from "generated/graphql";
var StatusSelect = function (props) {
    var intl = useIntl();
    var STATUS_OPTIONS = [
        {
            id: "active",
            label: intl.formatMessage({ id: "common.eventStatus.active" }),
            value: EventStatus.Active,
        },
        {
            id: "not-active",
            label: intl.formatMessage({ id: "common.eventStatus.inactive" }),
            value: EventStatus.NoIndex,
        },
        {
            id: "draft",
            label: intl.formatMessage({ id: "common.eventStatus.draft" }),
            value: EventStatus.Draft,
        },
    ];
    return (_jsx(SingleSelect, __assign({}, props, { options: STATUS_OPTIONS, name: "status", label: intl.formatMessage({ id: "common.form.field.singleSelect.eventStatus.label" }) }), void 0));
};
export default StatusSelect;
