import { mixed, number, object, string } from "yup";
import { editorSchema } from "components/templates/Editor";
import { ClientStatus } from "generated/graphql";
import { dateRegex, phoneJARegex, postCodeJARegex, urlRegex } from "utils/regex";
var MAX_STRING_LEN = 50;
var companySchema = object({
    // Address
    postalCode: string()
        .max(MAX_STRING_LEN)
        .matches(postCodeJARegex, function () { return ({ locale_id: "common.form.field.postCodeJA.error" }); })
        .required(),
    prefectureId: number().required(),
    address1: string().max(MAX_STRING_LEN).required(),
    address2: string().max(MAX_STRING_LEN).required(),
    // Basic info
    backgroundImageUrl: string().required(),
    logoImageUrl: string().nullable(),
    name: string().max(MAX_STRING_LEN).required(),
    phoneNumber: string()
        .matches(phoneJARegex, function () { return ({ locale_id: "common.form.field.phoneJA.error" }); })
        .required(),
    presidentName: string().max(MAX_STRING_LEN).nullable(),
    url: string()
        .matches(urlRegex, function () { return ({ locale_id: "common.form.field.url.error" }); })
        .required(),
    foundationDate: string()
        .matches(dateRegex, function () { return ({ locale_id: "common.form.field.date.error" }); })
        .nullable()
        .transform(function (value, originalValue) { return (String(originalValue).trim() === "" ? null : value); }),
    industryId: number().required(),
    employeesNum: number()
        .min(0)
        .nullable()
        .transform(function (value, originalValue) { return (String(originalValue).trim() === "" ? null : value); }),
    newGraduateNum: number()
        .min(0)
        .nullable()
        .transform(function (value, originalValue) { return (String(originalValue).trim() === "" ? null : value); }),
});
var clientSchema = object({
    company: companySchema,
    contactEmail: string().max(MAX_STRING_LEN).email().required(),
    contents: editorSchema,
    status: mixed().oneOf(Object.values(ClientStatus)).required(),
}).required();
export default clientSchema;
