var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import Speaker from "@scoville/speaker";
import useDebouncedCallback from "hooks/useDebounce";
import { useCallback, useMemo, useRef } from "react";
import { createReactEditorJS } from "react-editor-js";
import { useIntl } from "react-intl";
import { useAlert } from "contexts/AlertContext";
import { useUploadImageMutation } from "generated/graphql";
import CustomParagraph from "./customParagraph";
import CustomQuote from "./customQuote";
import { HEADER_SIZE, editorBlocksToMutation, editorSchema, queryToEditorBlocks, } from "./helpers";
export { HEADER_SIZE, queryToEditorBlocks, editorBlocksToMutation, editorSchema };
var defaultConfig = {
    header: true,
    image: true,
    embed: true,
    speaker: true,
    quote: true,
};
var sanitizeBlock = function (block) {
    if (block.type === "paragraph") {
        var sanitizedText = block.data.text.replaceAll("<p>", "");
        sanitizedText = sanitizedText.replaceAll("</p>", "<br>");
        return __assign(__assign({}, block), { data: __assign(__assign({}, block.data), { text: sanitizedText }) });
    }
    return block;
};
var Editor = function (_a) {
    var initialValues = _a.initialValues, onEdit = _a.onEdit, _b = _a.config, config = _b === void 0 ? defaultConfig : _b;
    var intl = useIntl();
    var onErrorAlert = useAlert().onErrorAlert;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var editorCore = useRef(null);
    var ReactEditorJS = createReactEditorJS();
    var _c = __read(useUploadImageMutation(), 1), uploadImageMutation = _c[0];
    var handleImgUpload = function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onerror = reject;
            reader.onload = function () {
                uploadImageMutation({
                    variables: {
                        base64: reader.result,
                    },
                })
                    .then(function (_a) {
                    var data = _a.data;
                    if (data) {
                        resolve({
                            success: 1,
                            file: {
                                url: data.createImageFromBase64.imageUrl,
                            },
                            stretched: true,
                        });
                    }
                    else
                        reject();
                })
                    .catch(function () {
                    onErrorAlert(intl.formatMessage({ id: "common.api.fail.ImgUpload" }));
                    reject();
                });
            };
        });
    };
    var tools = __assign(__assign(__assign(__assign(__assign({ paragraph: {
            class: CustomParagraph,
            inlineToolbar: true,
        } }, (config["header"] && {
        header: {
            class: Header,
            config: {
                placeholder: intl.formatMessage({ id: "common.editor.header.placeholder" }),
                level: HEADER_SIZE,
                levels: [HEADER_SIZE],
                defaultLevel: HEADER_SIZE,
            },
        },
    })), (config["image"] && {
        image: {
            class: Image,
            config: {
                uploader: {
                    uploadByFile: handleImgUpload,
                },
                actions: [],
            },
        },
    })), (config["embed"] && {
        embed: {
            class: Embed,
            config: {
                services: {
                    youtube: true,
                },
            },
        },
    })), (config["speaker"] && {
        speaker: {
            class: Speaker,
            config: {
                uploader: {
                    uploadByFile: handleImgUpload,
                },
                companyPlaceholder: intl.formatMessage({
                    id: "common.editor.tool.speaker.companyName.label",
                }),
                namePlaceholder: intl.formatMessage({
                    id: "common.editor.tool.speaker.speakerName.label",
                }),
                contentPlaceholder: intl.formatMessage({
                    id: "common.editor.tool.speaker.content.label",
                }),
            },
            actions: [],
        },
    })), (config["quote"] && {
        quote: {
            class: CustomQuote,
            config: {
                quotePlaceholder: intl.formatMessage({ id: "common.editor.quote.placeholder" }),
                captionPlaceholder: intl.formatMessage({ id: "common.editor.quote.caption.placeholder" }),
            },
        },
    }));
    var handleInitialize = useCallback(function (instance) {
        editorCore.current = instance;
    }, []);
    var handleSave = useDebouncedCallback(function () {
        var _a;
        (_a = editorCore === null || editorCore === void 0 ? void 0 : editorCore.current) === null || _a === void 0 ? void 0 : _a.save().then(function (outputData) {
            onEdit(outputData);
        });
    }, 100);
    var initialData = useMemo(function () {
        var _a;
        return ((_a = initialValues === null || initialValues === void 0 ? void 0 : initialValues.blocks) === null || _a === void 0 ? void 0 : _a.length)
            ? __assign(__assign({}, initialValues), { blocks: initialValues.blocks.map(function (block) { return sanitizeBlock(block); }) }) : {
            blocks: [
                {
                    type: "header",
                    data: {
                        text: "",
                        level: HEADER_SIZE,
                    },
                },
            ],
        };
    }, []);
    var locales = {
        toolNames: {
            Heading: intl.formatMessage({ id: "common.editor.toolOptions.heading" }),
            Text: intl.formatMessage({ id: "common.editor.toolOptions.text" }),
            Image: intl.formatMessage({ id: "common.editor.toolOptions.image" }),
        },
        tools: {
            image: {
                "Select an Image": intl.formatMessage({ id: "common.editor.tools.image.selectPrompt" }),
            },
            quote: {
                "Align Left": intl.formatMessage({ id: "common.editor.tools.paragraph.alignLeft" }),
                "Align Center": intl.formatMessage({ id: "common.editor.tools.paragraph.alignCenter" }),
                "Align Right": intl.formatMessage({ id: "common.editor.tools.paragraph.alignRight" }),
            },
        },
        ui: {
            blockTunes: {
                toggler: {
                    "Click to tune": intl.formatMessage({ id: "common.editor.tuner.tooltip" }),
                },
            },
            inlineToolbar: {
                converter: {
                    "Convert to": intl.formatMessage({ id: "common.editor.inlineToolbar.converter.tooltip" }),
                },
            },
            toolbar: {
                toolbox: {
                    Add: intl.formatMessage({ id: "common.editor.add.tooltip" }),
                },
            },
        },
        blockTunes: {
            delete: {
                Delete: intl.formatMessage({ id: "common.editor.tune.delete" }),
            },
            moveUp: {
                "Move up": intl.formatMessage({ id: "common.editor.tune.moveUp" }),
            },
            moveDown: {
                "Move down": intl.formatMessage({ id: "common.editor.tune.moveDown" }),
            },
        },
    };
    return (_jsx(ReactEditorJS, { defaultValue: initialData, onChange: handleSave, onInitialize: handleInitialize, tools: tools, i18n: { messages: locales } }, void 0));
};
export default Editor;
