var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { Evaluation } from "generated/graphql";
var EvaluationSelect = function (props) {
    var intl = useIntl();
    var EVALUATION_OPTIONS = [
        {
            id: Evaluation.GePlus,
            label: intl.formatMessage({ id: "common.generalEvaluation.geplus" }),
            value: Evaluation.GePlus,
        },
        {
            id: Evaluation.Ge,
            label: intl.formatMessage({ id: "common.generalEvaluation.ge" }),
            value: Evaluation.Ge,
        },
        {
            id: Evaluation.GeMinus,
            label: intl.formatMessage({ id: "common.generalEvaluation.geminus" }),
            value: Evaluation.GeMinus,
        },
        {
            id: Evaluation.NePlus,
            label: intl.formatMessage({ id: "common.generalEvaluation.neplus" }),
            value: Evaluation.NePlus,
        },
        {
            id: Evaluation.Ne,
            label: intl.formatMessage({ id: "common.generalEvaluation.ne" }),
            value: Evaluation.Ne,
        },
        {
            id: Evaluation.NeMinus,
            label: intl.formatMessage({ id: "common.generalEvaluation.neminus" }),
            value: Evaluation.NeMinus,
        },
        {
            id: Evaluation.PePlus,
            label: intl.formatMessage({ id: "common.generalEvaluation.peplus" }),
            value: Evaluation.PePlus,
        },
        {
            id: Evaluation.Pe,
            label: intl.formatMessage({ id: "common.generalEvaluation.pe" }),
            value: Evaluation.Pe,
        },
        {
            id: Evaluation.PeMinus,
            label: intl.formatMessage({ id: "common.generalEvaluation.peminus" }),
            value: Evaluation.PeMinus,
        },
    ];
    return (_jsx(SingleSelect, __assign({}, props, { options: EVALUATION_OPTIONS, name: "general-evaluation", label: intl.formatMessage({ id: "common.form.field.singleSelect.evaluation.label" }) }), void 0));
};
var EvaluationSelectDynamic = function (props) {
    var intl = useIntl();
    var options = props.optionVals.map(function (value) { return ({
        id: value,
        label: String(value),
        value: value,
    }); });
    return (_jsx(SingleSelect, __assign({ options: options }, props, { name: "segment-select", label: intl.formatMessage({ id: "common.form.field.singleSelect.evaluation.label" }) }), void 0));
};
export { EvaluationSelect, EvaluationSelectDynamic };
