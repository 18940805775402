var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Spinner } from "components/atoms";
import NoResult from "components/templates/NoResult";
import Table from "components/templates/Table";
import { DATE_FORMAT_YYYYMMDDHM } from "utils/constants";
var columns = [
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventDatesPage.table.column.header.id.label" }, void 0),
        accessor: "id",
        Cell: function (cellInfo) { return (_jsx("span", { children: cellInfo.row.original.id }, void 0)); },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventDatesPage.table.column.header.startAt.label" }, void 0),
        accessor: "startAt",
        Cell: function (cellInfo) { return (_jsx("span", { children: dayjs(cellInfo.row.original.startAt).format(DATE_FORMAT_YYYYMMDDHM) }, void 0)); },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventDatesPage.table.column.header.location.label" }, void 0),
        accessor: "location",
        Cell: function (cellInfo) { return (_jsx("span", { children: cellInfo.row.original.place.prefecture.name }, void 0)); },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventDatesPage.table.column.header.participants.label" }, void 0),
        accessor: "participants",
        Cell: function (cellInfo) { return (_jsxs("span", { children: [cellInfo.row.original.numApplied, "/", cellInfo.row.original.reservationNum] }, void 0)); },
    },
    {
        Header: _jsx(FormattedMessage, { id: "admin.eventDatesPage.table.column.header.details.label" }, void 0),
        accessor: "details",
        Cell: function (cellInfo) { return (_jsx(Link, __assign({ to: "".concat(cellInfo.row.original.id, "/entors") }, { children: _jsx(Button, __assign({ color: "primary", inverted: true }, { children: _jsx(FormattedMessage, { id: "admin.eventDatesPage.table.column.cell.details.label" }, void 0) }), void 0) }), void 0)); },
    },
];
var DatesTable = function (_a) {
    var dates = _a.dates, loading = _a.loading;
    return (_jsxs(_Fragment, { children: [loading && _jsx(Spinner, {}, void 0), dates && (dates === null || dates === void 0 ? void 0 : dates.length) === 0 ? _jsx(NoResult, {}, void 0) : _jsx(Table, { columns: columns, data: dates }, void 0), ";"] }, void 0));
};
export default DatesTable;
