var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { Button, Input, Spinner } from "components/atoms";
import SchoolMultiSelect from "components/molecules/FormSelects/MultiSelect/SchoolMultiSelect";
import BranchStatusRadioSelect from "components/molecules/FormSelects/RadioSelect/BranchStatusRadioSelect";
import { BranchStatus } from "generated/graphql";
var formSchema = yup.object({
    name: yup.string().min(4).required(),
    schoolIDs: yup.array().of(yup.number()),
    status: yup.mixed().oneOf(Object.values(BranchStatus)).defined().required(),
});
var DEFAULT_VALUES = {
    name: "",
    schoolIDs: [],
    status: BranchStatus.Visible,
};
var BranchForm = function (_a) {
    var _b, _c;
    var mode = _a.mode, onSubmit = _a.onSubmit, _d = _a.initialValues, initialValues = _d === void 0 ? DEFAULT_VALUES : _d, isLoading = _a.isLoading, onClose = _a.onClose;
    var _e = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(formSchema),
    }), reset = _e.reset, register = _e.register, submit = _e.handleSubmit, watch = _e.watch, _f = _e.formState, isDirty = _f.isDirty, isSubmitting = _f.isSubmitting, isSubmitted = _f.isSubmitted, errors = _f.errors, setValue = _e.setValue;
    var canSubmit = (!isSubmitting && isDirty) || (isSubmitted && isDirty);
    var handleSubmit = submit(function (data) {
        onSubmit(data);
        reset(__assign({}, data));
    });
    var handleSchoolSelect = function (schoolIds) {
        setValue("schoolIDs", schoolIds || [], { shouldDirty: true });
    };
    return (_jsxs("div", __assign({ className: "relative w-full bg-white" }, { children: [isLoading && _jsx(Spinner, {}, void 0), _jsxs("form", __assign({ onSubmit: handleSubmit, className: "flex flex-col h-full", noValidate: true }, { children: [_jsxs("div", __assign({ className: "grow flex flex-col gap-y-5 m-5" }, { children: [_jsx("legend", { children: _jsx("h6", { children: _jsx(FormattedMessage, { id: "common.form.branch.".concat(mode, ".title") }, void 0) }, void 0) }, void 0), _jsx(Input, __assign({}, register("name"), { isRequired: true, error: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message, type: "text", label: _jsx(FormattedMessage, { id: "common.form.branch.field.name.label" }, void 0) }), void 0), _jsx(SchoolMultiSelect, { onSelect: handleSchoolSelect, selectedValues: initialValues.schoolIDs }, void 0), _jsx(BranchStatusRadioSelect, { onSelect: function (status) {
                                    return setValue("status", status, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                }, selectedValue: watch("status"), error: (_c = errors.status) === null || _c === void 0 ? void 0 : _c.message, isRequired: true }, void 0)] }), void 0), _jsxs("div", __assign({ className: "m-5 gap-4 flex justify-end mt-auto" }, { children: [_jsx(Button, __assign({ type: "button", onClick: onClose }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.cancelButton.label" }, void 0) }), void 0), _jsx(Button, __assign({ type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.saveButton.label" }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default BranchForm;
