var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import { useAlert } from "contexts/AlertContext";
import { useModal } from "contexts/ModalContext";
import { useUnassignUserFromEntorMutation } from "generated/graphql";
import UserAssignmentModal from "./UserAssignmentModal";
var UnassignUserModal = function (_a) {
    var entor = _a.entor;
    var alert = useAlert();
    var modal = useModal();
    var intl = useIntl();
    var _b = __read(useUnassignUserFromEntorMutation({
        onCompleted: function (res) {
            if (res.removeAssigneeOfEntor) {
                alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
                client.cache.modify({
                    id: client.cache.identify(__assign({}, entor)),
                    fields: {
                        assignedUser: function () { return null; },
                    },
                });
                modal.close();
            }
        },
    }), 2), unassignUserFromEntorMutation = _b[0], client = _b[1].client;
    return (_jsx(UserAssignmentModal, { title: intl.formatMessage({ id: "admin.entorsPage.modal.unassignUser.title" }), description: intl.formatMessage({ id: "admin.entorsPage.modal.unassignUser.description" }), onCancel: modal.close, onConfirm: function () {
            unassignUserFromEntorMutation({ variables: { entorID: entor.id } });
        } }, void 0));
};
export default UnassignUserModal;
