var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, DateInput, Label, Spinner } from "components/atoms";
import { useSyncCompaniesLazyQuery, useSyncUsersLazyQuery } from "generated/graphql";
var Sync = function () {
    var _a = __read(useSyncCompaniesLazyQuery(), 2), getSyncedCompanyCount = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    var _c = __read(useSyncUsersLazyQuery(), 2), getSyncedUserCount = _c[0], _d = _c[1], userData = _d.data, userLoading = _d.loading;
    var _e = __read(useState(null), 2), syncDate = _e[0], setSyncDate = _e[1];
    var _f = __read(useState(), 2), userIds = _f[0], setUserIds = _f[1];
    var handleSubmit = function () {
        getSyncedCompanyCount({ variables: { syncDate: syncDate } });
    };
    var handleUserSubmit = function () {
        getSyncedUserCount({ variables: { syncDate: syncDate, userIds: userIds } });
    };
    var handleDateSelect = function (event) {
        setSyncDate(dayjs(event.target.value).toISOString());
    };
    var handleUserIds = function (event) {
        var value = event.target.value;
        var ids = value
            .split("\n")
            .map(function (val) { return Number(val.trim()); })
            .filter(function (val) { return val > 0; });
        if (ids.length === 0)
            return setUserIds(undefined);
        setUserIds(ids);
    };
    return (_jsx("div", __assign({ className: "w-full" }, { children: _jsx("div", __assign({ className: "bg-white w-full px-6 py-4 overflow-y-scroll h-full shadow-a" }, { children: _jsxs("div", __assign({ className: "text-black" }, { children: [_jsx(DateInput, { className: "w-40", name: "date", label: "Sync date", withTime: true, onChange: handleDateSelect }, void 0), _jsx(Button, __assign({ onClick: handleSubmit, disabled: !syncDate }, { children: _jsx(FormattedMessage, { id: "admin.syncPage.syncButton" }, void 0) }), void 0), loading && (_jsx("div", __assign({ className: "absolute h-full w-full" }, { children: _jsx(Spinner, { color: "bg-primary" }, void 0) }), void 0)), data &&
                        (data.syncCompanies && data.syncCompanies > 0 ? (_jsx("div", { children: _jsx(FormattedMessage, { id: "admin.syncPage.totalClientsSynced", values: { clients: data.syncCompanies } }, void 0) }, void 0)) : (_jsx("div", { children: _jsx(FormattedMessage, { id: "admin.syncPage.allClientSynced" }, void 0) }, void 0))), _jsx(Button, __assign({ onClick: handleUserSubmit, disabled: !syncDate && !(userIds === null || userIds === void 0 ? void 0 : userIds.length) }, { children: "Sync users" }), void 0), _jsx(Label, __assign({ className: classNames("py-1items-center cursor-text"), htmlFor: "user-ids" }, { children: "Sync users by user ids: ".concat(userIds ? userIds.join(", ") : "") }), void 0), _jsx("textarea", { id: "user-ids", rows: 4, className: classNames("py-1 border text-black90 w-full text-small focus:border-primary"), onChange: handleUserIds }, void 0), _jsx(Label, __assign({ className: classNames("py-1items-center text-small cursor-text"), htmlFor: "user-ids" }, { children: "*enter one user id per line" }), void 0), userLoading && (_jsx("div", __assign({ className: "absolute h-full w-full" }, { children: _jsx(Spinner, { color: "bg-primary" }, void 0) }), void 0)), userData &&
                        (userData.syncUsers && userData.syncUsers > 0 ? (_jsxs("div", { children: ["Synced ", userData.syncUsers, " user(s)"] }, void 0)) : (_jsx("div", { children: "All users synced" }, void 0)))] }), void 0) }), void 0) }), void 0));
};
export default Sync;
