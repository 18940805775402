var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var ScheduledEventDateEntorInfo = function (props) {
    return (_jsxs("div", __assign({ className: "flex align-center text-center gap-4 text-black50 py-2" }, { children: [_jsx("span", __assign({ className: "inline-flex items-center font-bold" }, { children: props.id }), void 0), _jsx("span", __assign({ className: "inline-flex items-center font-bold border-l border-black10 pl-3" }, { children: props.date }), void 0), _jsx("span", __assign({ className: "inline-flex items-center font-bold border-l border-black10 pl-3" }, { children: props.location }), void 0), _jsx("span", __assign({ className: "inline-flex items-center font-bold border-l border-black10 pl-3" }, { children: props.count }), void 0)] }), void 0));
};
export default ScheduledEventDateEntorInfo;
