var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Spinner } from "components/atoms";
import Header from "components/molecules/Header";
import MultiTab from "components/molecules/MultiTab";
import NotFoundView from "components/templates/NotFoundView";
import { useModal } from "contexts/ModalContext";
import { useInterviewQuestionsQuery } from "generated/graphql";
import QuestionModal from "./QuestionModal";
import QuestionTable from "./QuestionsTable";
import SubCategoryModal from "./SubCategoryModal";
var EntorQuerstions = function () {
    var modal = useModal();
    var _a = __read(useState(0), 2), selectedTab = _a[0], setSelectedTab = _a[1];
    var _b = useInterviewQuestionsQuery(), data = _b.data, error = _b.error, refetch = _b.refetch, loading = _b.loading;
    var tabs = (data === null || data === void 0 ? void 0 : data.interviewCategories.map(function (category, index) { var _a; return ({ label: (_a = category.title) !== null && _a !== void 0 ? _a : "", index: index }); })) ||
        [];
    var currentCategory = data === null || data === void 0 ? void 0 : data.interviewCategories[selectedTab];
    return error ? (_jsx(NotFoundView, {}, void 0)) : (_jsxs("div", __assign({ className: "pt-4 pl-3 w-full h-full flex flex-col" }, { children: [_jsx(Header, { title: _jsx(FormattedMessage, { id: "admin.entorsPage.questions.header.title" }, void 0), actionBtn: {
                    label: _jsx(FormattedMessage, { id: "admin.entorsPage.questions.header.action.label" }, void 0),
                    onClick: function () { return modal.show(_jsx(SubCategoryModal, { onUpdated: refetch }, void 0)); },
                } }, void 0), _jsx(MultiTab, { tabs: tabs, selectedTabIndex: selectedTab, onTabClick: function (index) { return setSelectedTab(index); }, isSticky: true, topZIndex: true }, void 0), _jsx("div", __assign({ className: "overflow-auto" }, { children: loading ? (_jsx(Spinner, {}, void 0)) : (currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.subCategories.map(function (subCategory) { return (_jsxs("div", __assign({ className: "bg-white shadow-md rounded-md m-3 border border-gray" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center py-3 px-4" }, { children: [_jsx("h6", __assign({ className: "font-bold" }, { children: subCategory.title }), void 0), _jsx(Button, __assign({ inverted: true, onClick: function () {
                                        return modal.show(_jsx(SubCategoryModal, { onUpdated: refetch, subCategoryId: subCategory.id, categoryId: currentCategory.id }, void 0));
                                    } }, { children: _jsx(FormattedMessage, { id: "admin.entorsPage.questions.subCatagory.action.edit.label" }, void 0) }), void 0)] }), void 0), _jsx(QuestionTable, { questions: subCategory.questions, onEditQuestion: function (id) {
                                return modal.show(_jsx(QuestionModal, { questionId: id, subCategoryId: subCategory.id, categoryId: currentCategory.id, onUpdated: refetch }, void 0));
                            } }, void 0)] }), subCategory.id)); })) }), void 0)] }), void 0));
};
export default EntorQuerstions;
