// e.g. 2020-04-01
var dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
// e.g. 15:15
var time24hRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
// e.g.
// postal codes of Japan are represented by 7 digits numbers using the format 〒NNN-NNNN
var postCodeJARegex = /^\d{3}-\d{4}$/;
var urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
var phoneJARegex = /(\d{2,3})-?(\d{3,4})-?(\d{4})/;
// Mixed half-width alphanumerical characters
var pwRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].*$/;
// Katakana characters
var kanaRegex = /^[ァ-ヾ]*$/;
export { kanaRegex, pwRegex, time24hRegex, postCodeJARegex, urlRegex, dateRegex, phoneJARegex };
